import { createReducer, on } from '@ngrx/store';
import { ContentsActions } from 'src/app/core/state/app.actions';
import { ContentState } from 'src/app/pages/contents/state/content.state';
import { STATE_STATUS } from 'src/app/shared/models';
import { BaseFiltersType } from 'src/app/shared/models/base-list-filters';


export const TAG = 'contents';

export interface FiltersType extends BaseFiltersType {
	structuresUUID?: string[];
	featureValue?: string[];
	communityUUID?: string;
	active?: boolean;
	updateDate?: string;
	communityEntityUUID?: string;
	[key: string]: unknown;
}

const initialState: ContentState = {
	filters: {
		featureValue: [],
		active: true,
	},
	sort: undefined,
	pages: [0],
	data: { totalContentsCount: 0, contents: [] },
	features: [],
	structures: [],
	status: STATE_STATUS.UNKNOWN,
	gridSearch: null,
	deleteStatus: 'unknown',
  cachedPages: []
};

export const reducers = createReducer(
	initialState,
	/**
	 * Parte standard di un reducer
	 */
	on(ContentsActions.setSort, (state: ContentState, { sort }) => ({
		...state,
		sort,
	})),
	on(ContentsActions.setPages, (state: ContentState, { pages }) => ({
		...state,
		pages,
	})),
	on(ContentsActions.setContentsSuccess, (state: ContentState, { data }) => {
		return {
			...state,
			status: STATE_STATUS.READY,
			data: {
				contents: data.contents,
				totalContentsCount: data.totalContentsCount,
			},
			pages: data.pages,
		};
	}),
	on(ContentsActions.setContentsError, (state: ContentState) => ({
		...state,
		status: STATE_STATUS.ERROR,
	})),
	on(ContentsActions.setStatus, (state: ContentState, { status }) => {
		return {
			...state,
			status,
		};
	}),
	on(ContentsActions.setGridSearch, (state: ContentState, { search }) => {
		return {
			...state,
			gridSearch: search,
		};
	}),
	on(ContentsActions.setDeleteContents, (state: ContentState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.LOADING,
		};
	}),
	on(ContentsActions.setDeleteContentsSuccess, (state: ContentState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.READY,
		};
	}),
	on(ContentsActions.setDeleteContentsError, (state: ContentState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.ERROR,
		};
	}),
	/**
	 * PARTE SPECIFICA DEL REDUCER
	 */
	on(ContentsActions.setFilters, (state: ContentState, { filters }) => ({
		...state,
		filters: {
			...filters,
			structureUUID: filters.structureUUID !== 'allStructures' ? filters.structureUUID : undefined,
			communityUUID: filters.communityEntityUUID,
		},
	})),
	on(ContentsActions.setStructures, (state: ContentState, { structures }) => ({
		...state,
		structures,
	})),
	on(ContentsActions.setFeatures, (state: ContentState, { features }) => ({
		...state,
		features,
	})),
	on(ContentsActions.updateFilters, (state: ContentState, { updatedFilters }) => ({
		...state,
		filters: {
			...state.filters,
			...updatedFilters,
		},
	})),
	on(ContentsActions.addFeatureValueToFilters, (state: ContentState, { featureValueUUID }) => ({
		...state,
		filters: {
			...state.filters,
			featureValue: state.filters.featureValue?.concat(featureValueUUID),
		},
	})),
	on(ContentsActions.removeFeatureValueFromFilters, (state: ContentState, { featureValueUUID }) => ({
		...state,
		filters: {
			...state.filters,
			featureValue: state.filters.featureValue?.filter((uuid) => uuid !== featureValueUUID),
		},
	})),
  on(ContentsActions.setCachedPages, (state: ContentState, { pages }) => ({
		...state,
		cachedPages: [...new Set([...state.cachedPages, ...pages])],
	})),
	on(ContentsActions.resetCache, (state: ContentState) => ({
		...state,
    status: STATE_STATUS.READY,
		cachedPages: [],
		pages: [0],
		data: { totalContentsCount: 0, contents: [] },
	}))
);
