import { ActionReducerMap } from '@ngrx/store';
import { ContextState } from 'context-store';
import {
	ChallengeReducers,
	CommunityReducers,
	ContestReducers,
	IntegrationReducers,
	OBActionReducers,
	OBObjectiveGroupReducers,
	OBOjectiveReducers,
	OBTailReducers,
	PictogramReducers,
	PictogramStructureReducers,
	ProductVersioningReducer,
	RecipientsReducers,
	StructuresReducers,
} from 'src/app/core/state/app.reducers';
import { CollectionsEffects } from 'src/app/pages/collections/store/collections.effects';
import { CollectionReducer, CollectionState } from 'src/app/pages/collections/store/collections.reducer';
import { CommunicationState } from 'src/app/pages/communications/state/communication.state';
import { ApplicationUserEffect } from 'src/app/pages/idm/app-users/state/application-users.effects';
import { DataleanUsersEffects } from 'src/app/pages/idm/datalean-users/state/datalean-users.effects';
import { GroupsEffects } from 'src/app/pages/idm/groups/state/groups.effects';
import { SmartGroupsEffects } from 'src/app/pages/idm/smart-groups/state/smart-groups.effects';
import { CoursesEffects } from 'src/app/pages/lms/courses/state/courses.effects';
import { LessonsEffects } from 'src/app/pages/lms/lessons/state/lessons.effects';
import { MediaLibraryEffects } from 'src/app/pages/media-library/state/media-library.effects';
import { MediaLibraryState } from 'src/app/pages/media-library/state/media-library.state';
import { ProductEffect } from 'src/app/pages/products/state/product.effect';
import { ProductState } from 'src/app/pages/products/state/product.state';
import { UsefulLinksState } from 'src/app/pages/useful-links/state/useful-links.state';
import { CommunitySelectState } from 'src/app/shared/state/community-select/community-select.state';
import { ConfigEffect } from 'src/app/shared/state/config/config.effect';
import { ConfigState } from 'src/app/shared/state/config/config.state';
import { FeaturesState } from 'src/app/shared/state/features/features.state';
import { NavigationState } from 'src/app/shared/state/navigation/navigation.state';
import { StructuresEffects } from 'src/app/shared/state/structures/structures.effects';
import { StructuresState } from 'src/app/shared/state/structures/structures.state';
import { TagsState } from 'src/app/shared/state/tags/tags.state';
import { UserEffect } from 'src/app/shared/state/user/user.effect';
import { UserState } from 'src/app/shared/state/user/user.state';
import { CommunicationEffect } from '../../pages/communications/state/communication.effect';
import { PictogramStructureEffect } from '../../pages/pictogram/pictogram-structures/state/pictogram-structure.effects';
import { CommunityEffect } from '../../pages/settings/communities/state/community.effect';
import { FeaturesEffects } from '../../shared/state/features/features.effects';
import TagsEffects from '../../shared/state/tags/tags.effects';
import { HeaderEffect } from '../components/header/state/header.effect';
import { HeaderState } from '../components/header/state/header.state';
import { ChallengeEffect } from './../../pages/contest/challenges/challenge-list/state/challenge.effect';
import { OBTailEffect } from './../../pages/on-boarding/ob-city-builder/state/ob-builder-tail.effects';
import { OBObjectiveGroupState, OBObjectiveState } from './../../pages/on-boarding/ob-configurator/state/ob-configurator.state';
import { ProductVariantsState } from './../../pages/products-variants/state/product-variants.state';

import { ChallengeState } from 'src/app/pages/contest/challenges/challenge-list/state/challenge.state';
import { ContestEffect } from 'src/app/pages/contest/contests/contest-list/state/contest.effect';
import { ContestState } from 'src/app/pages/contest/contests/contest-list/state/contest.state';
import { QuizEffects } from 'src/app/pages/lms/quiz/state/quiz.effects';
import { OBTailState } from 'src/app/pages/on-boarding/ob-city-builder/state/ob-builder.state';
import { OBActionEffect } from 'src/app/pages/on-boarding/ob-configurator/state/ob-configurator-action.effects';
import { OBObjectiveGroupEffect } from 'src/app/pages/on-boarding/ob-configurator/state/ob-configurator-objective-group.effects';
import { OBObjectiveEffect } from 'src/app/pages/on-boarding/ob-configurator/state/ob-configurator-objective.effects';
import { OBActionState } from 'src/app/pages/on-boarding/ob-configurator/state/ob-configurator.state';
import { PictogramStructureState } from 'src/app/pages/pictogram/pictogram-structures/state/pictogram-structure.state';
import { PictogramEffect } from 'src/app/pages/pictogram/pictograms/state/pictogram.effects';
import { PictogramState } from 'src/app/pages/pictogram/pictograms/state/pictogram.state';
import { ProductVariantsEffect } from 'src/app/pages/products-variants/state/product-variants.effect';
import { CalendarEffect } from 'src/app/pages/reservations/calendar/state/calendar.effect';
import { CalendarState } from 'src/app/pages/reservations/calendar/state/calendar.state';
import { BookableProductEffect } from 'src/app/pages/reservations/products/state/bookable-product.effect';
import { BookableProductState } from 'src/app/pages/reservations/products/state/bookable-product.state';
import { ResourceEffect } from 'src/app/pages/reservations/resources/state/resource.effect';
import { ResourceState } from 'src/app/pages/reservations/resources/state/resource.state';
import { RolesEffect } from 'src/app/pages/roles/store/roles.effect';
import { RolesState } from 'src/app/pages/roles/store/roles.state';
import { CommunityState } from 'src/app/pages/settings/communities/state/community.state';
import { SurveyCommunicationsEffects } from 'src/app/pages/surveys/state/survey-communication.effects';
import { SurveysEffects } from 'src/app/pages/surveys/state/surveys.effects';
import { CommunitySelectEffect } from 'src/app/shared/state/community-select/community-select.effect';
import { RecipientState } from 'src/app/shared/state/recipients/recipients.state';
import { ContentEffect } from '../../pages/contents/state/content.effect';
import { ContentState } from '../../pages/contents/state/content.state';
import { ProductVersioningEffect } from '../../pages/products/state/product-versioning/product-versioning.effect';
import { ProductVersioningState } from '../../pages/products/state/product-versioning/product-versioning.state';
import { IntegrationEffect } from '../../pages/settings/integrations/state/integration.effect';
import { IntegrationState } from '../../pages/settings/integrations/state/integration.state';
import { UsefulLinksEffects } from '../../pages/useful-links/state/useful-links.effect';
import { WorkFlowEffect } from '../../shared/state/workflow/workflow.effects';
import { WorkFlowState } from '../../shared/state/workflow/workflow.reducer';
import {
	ApplicationReducers,
	ApplicationUsersReducers,
	BookableProductReducers,
	CalendarReducers,
	CommunicationReducers,
	CommunitySelectReducers,
	ConfigReducers,
	ContentsReducers,
	CoursesReducers,
	DataleanUsersReducers,
	FeaturesReducers,
	GroupsReducers,
	HeaderReducer,
	LessonsReducers,
	MediaLibraryReducers,
	NavigationReducer,
	ProductReducers,
	ProductVariantsReducers,
	QuizReducers,
	ResourceReducers,
	RolesReducers,
	SmartGroupsReducers,
	SurveyCommunicationsReducers,
	SurveysReducers,
	TagsReducers,
	UsefulLinksReducers,
	UserReducers,
	WorkFlowReducers,
} from './app.reducers';
import { ApplicationEffect } from './application/application.effect';
import { ApplicationState } from './application/application.state';
import { PracticeSectionsReducer, PracticeSectionState } from '../../pages/informed-consent/state/section/practice-section.reducer';
import { PracticeSectionEffects } from '../../pages/informed-consent/state/section/practice-section.effects';
import { PracticeSectionsContainerReducer, PracticeSectionContainerState } from '../../pages/informed-consent/state/section-container/practice-section-container.reducer';
import { PracticeSectionContainerEffects } from '../../pages/informed-consent/state/section-container/practice-section-container.effects';

export interface AppState {
	navigation: NavigationState;
	header: HeaderState;
	user: UserState;
	config: ConfigState;
	application: ApplicationState;
	product: ProductState;
	productVersioning: ProductVersioningState;
	productVariants: ProductVariantsState;
	communication: CommunicationState;
	communitySelect: CommunitySelectState;
	mediaLibrary: ContextState<MediaLibraryState>;
	features: FeaturesState;
	tags: TagsState;
	collections: CollectionState;
	dataleanUsers: DataleanUsersReducers.DataleanUsersState;
	applicationUsers: ApplicationUsersReducers.ApplicationUsersState;
	groups: GroupsReducers.GroupsState;
	smartGroups: SmartGroupsReducers.SmartGroupsState;
	lessons: LessonsReducers.LessonsState;
	courses: CoursesReducers.CoursesState;
	contents: ContentState;
	quiz: QuizReducers.QuizState;
	surveys: SurveysReducers.SurveysState;
	usefulLinks: UsefulLinksState;
	surveyCommunications: SurveyCommunicationsReducers.SurveyCommunicationsState;
	resources: ResourceState;
	bookableProducts: BookableProductState;
	calendar: CalendarState;
	communities: CommunityState;
	structures: StructuresState;
	pictogramStructures: PictogramStructureState;
	pictograms: PictogramState;
	recipients: RecipientState;
	contests: ContestState;
	challenges: ChallengeState;
	workflow: WorkFlowState;
	roles: RolesState;
	integrations: IntegrationState;
	obObjective: OBObjectiveState;
	obObjectiveGroup: OBObjectiveGroupState;
	obAction: OBActionState;
	obTail: OBTailState;
  practiceSections: PracticeSectionState,
  practiceSectionsContainer: PracticeSectionContainerState,
}

export const AppReducers: ActionReducerMap<AppState> = {
	productVariants: ProductVariantsReducers.counterReducer,
	navigation: NavigationReducer.counterReducer,
	header: HeaderReducer.counterReducer,
	user: UserReducers.counterReducer,
	config: ConfigReducers.counterReducer,
	application: ApplicationReducers.counterReducer,
	product: ProductReducers.counterReducer,
	productVersioning: ProductVersioningReducer.reducer,
	communication: CommunicationReducers.counterReducer,
	communitySelect: CommunitySelectReducers.counterReducer,
	mediaLibrary: MediaLibraryReducers.reducers,
	features: FeaturesReducers.reducer,
	tags: TagsReducers.reducer,
	dataleanUsers: DataleanUsersReducers.reducers,
	applicationUsers: ApplicationUsersReducers.reducers,
	groups: GroupsReducers.reducers,
	smartGroups: SmartGroupsReducers.reducers,
	collections: CollectionReducer,
	lessons: LessonsReducers.reducers,
	courses: CoursesReducers.reducers,
	contents: ContentsReducers.reducers,
	quiz: QuizReducers.reducers,
	surveys: SurveysReducers.reducers,
	usefulLinks: UsefulLinksReducers.reducer,
	surveyCommunications: SurveyCommunicationsReducers.reducers,
	communities: CommunityReducers.counterReducer,
	resources: ResourceReducers.counterReducer,
	bookableProducts: BookableProductReducers.counterReducer,
	structures: StructuresReducers.reducers,
	calendar: CalendarReducers.counterReducer,
	pictogramStructures: PictogramStructureReducers.reducer,
	pictograms: PictogramReducers.reducer,
	recipients: RecipientsReducers.reducer,
	contests: ContestReducers.counterReducer,
	challenges: ChallengeReducers.counterReducer,
	workflow: WorkFlowReducers.reducer,
	roles: RolesReducers.reducers,
	integrations: IntegrationReducers.counterReducer,
	obObjective: OBOjectiveReducers.reducer,
	obObjectiveGroup: OBObjectiveGroupReducers.reducer,
	obAction: OBActionReducers.reducer,
	obTail: OBTailReducers.reducer,
  practiceSections: PracticeSectionsReducer,
  practiceSectionsContainer: PracticeSectionsContainerReducer
};

export const AppEffects = [
	ProductVariantsEffect,
	ProductVariantsEffect,
	UserEffect,
	ConfigEffect,
	ApplicationEffect,
	ProductEffect,
	ProductVersioningEffect,
	CommunicationEffect,
	MediaLibraryEffects,
	HeaderEffect,
	FeaturesEffects,
	TagsEffects,
	DataleanUsersEffects,
	ApplicationUserEffect,
	GroupsEffects,
	SmartGroupsEffects,
	CollectionsEffects,
	LessonsEffects,
	CoursesEffects,
	ContentEffect,
	QuizEffects,
	UsefulLinksEffects,
	SurveysEffects,
	SurveyCommunicationsEffects,
	CommunityEffect,
	BookableProductEffect,
	ResourceEffect,
	StructuresEffects,
	CalendarEffect,
	PictogramStructureEffect,
	PictogramEffect,
	CommunitySelectEffect,
	ContestEffect,
	ChallengeEffect,
	WorkFlowEffect,
	IntegrationEffect,
  RolesEffect,
	OBObjectiveEffect,
	OBObjectiveGroupEffect,
	OBActionEffect,
	OBTailEffect,
  PracticeSectionEffects,
  PracticeSectionContainerEffects,
];
