import { Injectable } from '@angular/core';

/**
 * Utility che permette di gestire multiple checkbox
 * utilizza la modalità di 'filtri' per capire quali checkbox sono state
 * selezionate, questo perchè in pagine con infiniteScroll se si vuole selezionare
 * tutto non è possibile indicare gli elementi selezionati in un altro modo (poichè non conosciamo
 * tutti gli elementi che sono stati selezionati)
 *
 * il filtro può essere inclusivo o esclusivo es.
 * checboxMap = {ID} & modalità inclusiva ==> ho selezionato solo ID
 * checboxMap = {ID} & modalità esclusiva ==> ho selezionato tutte le checkbox ad esclusione della checkbox ID
 *
 *
 * NB. occorre settare il numero totale di items per far funzionare correttamente il servizio
 * ossia settare la variabile 'totalItems'
 */

@Injectable({ providedIn: 'root' })
export class CheckboxMultipleService {
	checkboxMap: Record<string, boolean> = {}; //ci salvo gli uuid
	checkboxMapWithValue: Record<string, string> = {}; //ci salvo gli uuid come chiave e come valore una stringa
	currentPageItems = new Array<string>(); //ci salvo gli uuid della pagina corrente
	selectAllChecked: boolean = false; //modalità: se true => 'exclusive', se false => 'inclusive'
	selectAllIndeterminate: boolean = false;
	//necessario settare questo valore per far funzionare correttamente il servizio
	totalItems: number = 0;

	get totalSelected() {
		return this.selectAllChecked ? this.totalItems - this.getUuidArray().length : this.getUuidArray().length;
	}

	updateSelectAllStatus() {
		// console.log('updateSelectAllStatus', this.totalItems);
		if (this.totalItems === 0) {
			throw new Error('totalItems must be set correctly');
		}

		if (this.selectAllChecked) {
			//caso esclusivo
			const falseValues = Object.entries(this.checkboxMap)
				.filter(([uuid, v]) => this.currentPageItems.includes(uuid) && !v)
				.map(([uuid]) => uuid);

			this.selectAllIndeterminate = !!falseValues.length;

			if (falseValues.length === 0) {
				this.setAll(true);
			} else if (falseValues.length === this.totalItems) {
				this.setAll(false);
			}
		} else {
			//Caso inclusivo
			const trueValues = Object.entries(this.checkboxMap)
				.filter(([uuid, v]) => this.currentPageItems.includes(uuid) && v)
				.map(([uuid]) => uuid);

			this.selectAllIndeterminate = !!trueValues.length;
			// console.log('trueValues', trueValues.length === this.totalItems);

			if (trueValues.length === this.totalItems) {
				this.setAll(true);
			} else {
				// console.log('trueValues', trueValues);
				this.selectAllIndeterminate = !!trueValues.length;
				if (trueValues.length === 0) {
					this.setAll(false);
				}
			}
		}
	}

	setAll(value: boolean, dataset?: string[]) {
		this.selectAllChecked = value;
		this.selectAllIndeterminate = false;
		if (dataset) {
			for (const uuid of dataset) {
				this.checkboxMap[uuid] = value;
			}
		} else {
			for (const uuid of this.currentPageItems) {
				this.checkboxMap[uuid] = value;
			}
		}
	}

	reset() {
		this.setAll(false);
		this.checkboxMap = {};
	}

	isInclusiveMode() {
		return !this.selectAllChecked;
	}

  /**
   * questo metodo restituisce l'array di uuid TENENDO CONTO della modalità di selezione
   * - se la modalità è inclusiva, nell'array avrai tutti gli uuid delle entità che non hai selezionato
   *  (ESEMPIO) se hai selezionato tutto, si intende MODALITA' esclusiva, alcune api gestiscono questa modalità
   *  quindi restituirà un array vuoto (poichè chiamerai le api con array vuoro e modalità esclusiva, tale
   *  filtro verrà poi gestito a BE)
   * - se la modalità non è esclusiva avrai l'array di uuid delle entity selezionate
   * @returns array di uuid (con una semantica che varia in base alla modalità di selezione che si sta utilizzando)
   */
	getUuidArray() {
		return Object.entries(this.checkboxMap).reduce((acc, [uuid, value]) => {
			//se la modalità è inclusiva, prendo tutti quelli dentro checkboxMap con valore true
			//se la modalità è esclusiva, prendo tutti quelli con valore false
			if (value !== this.selectAllChecked) {
				acc.push(uuid);
			}
			return acc;
		}, [] as string[]);
	}

  /**
   * a differenza della funzione "getUuidArray", questo metodo restituisce sempre
   * gli uuid delle entità selezionate (non tiene conto della modalità di selezione del servizio)
   */
  getUuidArraySelected(){
    return Object.entries(this.checkboxMap).reduce((acc, [uuid, value]) => {
			if (value) {
				acc.push(uuid);
			}
			return acc;
		}, [] as string[]);
  }
}
