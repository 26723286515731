import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, filter, of } from 'rxjs';
import { catchError, combineLatestWith, debounce, first, map, switchMap, tap, distinctUntilChanged, concatMap } from 'rxjs/operators';
import { CommunitySelectActions, WorkFlowActions } from '../../../core/state/app.actions';
import { STATE_STATUS } from '../../models';
import { WorkflowService } from '../../services/workflow.service';
import { selectLastCommunitySelectedForApiRest } from '../community-select/community-select.selectors';
import {
	selectCachedPages,
	selectGridSearch,
	selectPagedData,
	selectPages,
	selectSort,
	selectStatus,
	selectWorkFlowType,
	selectWorkFlowsFilters,
} from './workflow.selectors';
import { setWorkFlowSuccess } from './workflow.actions';
import { selectUserData } from '../user/user.selectors';

@Injectable()
export class WorkFlowEffect {
	private store = inject(Store);
	private actions$ = inject(Actions);
	private workflowService = inject(WorkflowService);
	private translateService = inject(TranslateService);

	fetchWorkFlow$ = createEffect(() =>
		this.actions$.pipe(
			//quando selezioni una categoria o fai una ricerca, viene triggherato il DamFilter, quindi ci registriamo ad
			//un suo eventuale successo per richiamare gli assets
			ofType(WorkFlowActions.setPages, WorkFlowActions.setForceRefreshWorkFlow),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectWorkFlowsFilters),
				this.store.select(selectWorkFlowType),
				this.store.select(selectLastCommunitySelectedForApiRest),
				this.store.select(selectCachedPages),
				this.store.select(selectUserData),
			]),
			debounce(() => this.store.select(selectStatus).pipe(filter((stat) => !stat || stat !== 'loading'))),
			//non voglio caricare se non ho le pagine pronte
			filter(([, pages]) => !!pages?.length),
			tap(() => {
				this.store.dispatch(WorkFlowActions.setStatus({ status: STATE_STATUS.LOADING }));
			}),
			switchMap(([, pages, sort, gridSearch, filters, workFlowType, communityUUID, cachedPages, userData]) => {
				const actualPages = pages.filter((p) => !cachedPages.includes(p));
				if (!actualPages.length) return EMPTY;
				const filterWithCommunity = structuredClone(filters);
				if (communityUUID) filterWithCommunity.communityUUID = communityUUID;
				if (userData?.roles)
					filterWithCommunity['rolesUUID'] = userData.roles.map((x) => {
						return x.uuid;
					});
				return this.workflowService
					.fetchWorkFlow(
						workFlowType,
						actualPages,
						sort,
						this.translateService.currentLang ?? this.translateService.defaultLang,
						gridSearch,
						filterWithCommunity
					)
					.pipe(
						combineLatestWith(this.store.select(selectPagedData)),
						first(),
						map(([data, startingResult]) => {
							// console.log(startingResult);
							startingResult.pages = cachedPages;
							const result = data.reduce((acc, item) => {
								if (item.paginationInfo) {
									acc.pages.push(item.paginationInfo.pageNumber);
									acc.totalWorkFlowCount = item.paginationInfo.totalElements ?? 0;
									if (item.result) {
										if (acc.workFlows)
											acc.workFlows[item.paginationInfo.pageNumber] = item.result.map((product) => ({
												...product,
											}));
									}
								}
								return acc;
							}, structuredClone(startingResult));

							// console.log(result);

							return WorkFlowActions.setWorkFlowSuccess({ data: result });
						}),
						catchError((error: HttpErrorResponse) => {
							console.error(error);
							return of(WorkFlowActions.setWorkFlowError({ error }));
						})
					);
			})
		)
	);

	resetCache$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				WorkFlowActions.setGridSearch,
				WorkFlowActions.setSort,
				WorkFlowActions.updateFilters,
				CommunitySelectActions.setLastCommunitySelected
				//WorkFlowActions.setWorkFlowList
			),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectWorkFlowsFilters),
				this.store.select(selectWorkFlowType),
				this.store.select(selectLastCommunitySelectedForApiRest),
				this.store.select(selectCachedPages),
			]),
			// //non voglio caricare se i dati delle azioni sono gli stessi
			distinctUntilChanged(
				(
					[, pagesPrev, sortPrev, gridSearchPrev, filterPrev, , communityUUIDPrev],
					[, pagesCurr, sortCurr, gridSearchCurr, filterCurr, , communityUUIDCurr, cachedPages]
				) => {
					return (
						pagesPrev.length === pagesCurr.length &&
						pagesPrev.every((f) => pagesCurr.includes(f)) &&
						sortCurr?.active === sortPrev?.active &&
						sortCurr?.direction === sortPrev?.direction &&
						filterPrev === filterCurr &&
						gridSearchCurr === gridSearchPrev &&
						communityUUIDCurr === communityUUIDPrev &&
						pagesPrev.filter((f) => !cachedPages.includes(f)).length === 0
					);
				}
			),
			map(() => WorkFlowActions.resetCache())
		)
	);

	workFlowTypeChanged$ = createEffect(() =>
		this.actions$.pipe(
			ofType(WorkFlowActions.workflowTypeChanged),
			switchMap((action) =>
				this.workflowService
					.getStructure(action.workflowType)
					.pipe(
						concatMap((structures) => [
							WorkFlowActions.setStructures({ structures }),
							WorkFlowActions.setWorkFlowList({ workflowType: action.workflowType }),
						])
					)
			)
		)
	);

	workFlowSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setWorkFlowSuccess),
			map(({ data: { pages } }) => WorkFlowActions.setCachedPages({ pages }))
		)
	);

	resetPage$ = createEffect(() =>
		this.actions$.pipe(
			ofType(WorkFlowActions.setGridSearch),
			map(() => WorkFlowActions.resetCache())
		)
	);

	forceRefreshTable$ = createEffect(() =>
		this.actions$.pipe(
			ofType(WorkFlowActions.resetCache),
			map(() => WorkFlowActions.setForceRefreshWorkFlow())
		)
	);

	constructor() {}
}
