import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { DataleanPagedResult, PaginationInfo, Parts, SimpleObject, SortInfo } from 'addiction-components';
import { Observable, forkJoin } from 'rxjs';
import { Integration } from 'src/app/shared/models';
import { BaseListService } from 'src/app/shared/services/base-list.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class IntegrationService extends BaseListService {

	constructor() {
		super();
		this.endpoint = environment.integrationUrl;
	}

	getIntegrations(options: {
		parts?: Parts[];
		locale?: string;
		pagination?: PaginationInfo;
		sort?: SortInfo;
		filters?: { uuids?: string[]; query?: string };
	}) {
		const { filters, parts, pagination, sort } = options;

		const params: SimpleObject = {};

		// TODO: generalizzare questa logica in una funzione reutilizzabile
		if (filters?.query) {
			params['q'] = filters.query;
			params['searchFields'] = ['name']; // parametrizzare i campi di ricerca??
		}

		if (filters?.uuids) params['uuid'] = filters.uuids.join(',');

		return this.baseApi.getManyPaged<Integration>(environment.integrationUrl, parts ?? [Parts.ALL], {
			pagination,
			sort,
			additionalParams: params,
		});
	}

	fetchIntegrations(pages: number[], sort?: Sort, locale?: string, gridSearch?: string | null): Observable<DataleanPagedResult<Integration>[]> {
		const obs: Observable<DataleanPagedResult<Integration>>[] = [];

		const params: {
			sortBy?: string;
			q?: string;
			searchFields?: string;
		} = {};

		//SORT
		if (sort) params.sortBy = `${sort.active}#${sort.direction}`;
		if (gridSearch) {
			params.q = gridSearch;
			params.searchFields = 'name,description';
		}

		for (const page of pages) {
			const pagination: PaginationInfo = new PaginationInfo(environment.pageSize, page);

			obs.push(
				this.baseApi.getManyPaged<Integration>(environment.integrationUrl, [Parts.UPDATE_DATE], {
					pagination,
					additionalParams: params,
				})
			);
		}
		return forkJoin(obs);
	}

	deleteIntegration(uuid: string) {
		return this.baseApi.deleteEntity(environment.integrationUrl, { uuid });
	}

	consolidateCatalean(cataleanUUID: string) {
		return this.baseApi.updateEntity(environment.integrationUrl + 'publish', { uuid: cataleanUUID }, [Parts.EMPTY]);
	}
}
