import { Component, Input } from '@angular/core';
import { PaginationInfo, TableRow } from 'addiction-components';
import { environment } from 'src/environments/environment';
import { ApplicationUser } from '../../models';
import { GenericSelectorDialogComponent } from '../generic-selector-dialog/generic-selector-dialog.component';

@Component({
	selector: 'datalean-app-user-selector-dialog',
	templateUrl: './app-user-selector-dialog.component.html',
	styleUrls: ['./app-user-selector-dialog.component.scss'],
})
export class AppUserSelectorDialogComponent extends GenericSelectorDialogComponent<Partial<ApplicationUser>> {
	@Input()
	override selectedUUIDs: string[] = [];
	@Input() override searchFields: string = 'firstName,lastName,email';
	@Input() override searchParamName: string = 'q';

	override endpoint: string = environment.applicationUsersUrl;

	selectedRows?: TableRow[];

	constructor() {
		super();
		this.pageSize = 200;
		this.currentPage$.next([new PaginationInfo(this.pageSize, 0)]);
	}

	override mapData(data: ApplicationUser[]): TableRow[] {
		const result: TableRow[] = [];
		for (const item of data) {
			result.push({
				uuid: item.uuid,
				name: item.firstName + ' ' + item.lastName,
				email: item.email,
			});
		}
		return result;
	}

	override selectionChanged(selectedRows: TableRow[]) {
		super.selectionChanged(selectedRows);
		this.selectedRows = selectedRows;
	}

	override close() {
		this.closeDialog({ selected: this.selectedRows?.map((row) => row['uuid'] as string) ?? [] });
	}

	override onSelectionChange(entityUUID?: string, checked?: boolean): void {
		if (entityUUID) {
			if (entityUUID === 'all') {
				for (const uuid of this._currentPageUUIDs) {
					this.gcs.checkboxMap[uuid] = !!checked;
				}
			} else {
				this.gcs.checkboxMap[entityUUID] = !!checked;
				this.gcs.updateSelectAllStatus();
			}
		}
		this.selectionChanged(
			Object.entries(this.gcs.checkboxMap).reduce((acc, [uuid, value]) => {
				if (value) {
					acc.push({ uuid });
				}
				return acc;
			}, new Array<TableRow>())
		);
	}
}
