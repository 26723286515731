import * as CommunicationActions from '../../pages/communications/state/communication.actions';
import * as ContentsActions from '../../pages/contents/state/content.actions';
import * as ChallengeActions from '../../pages/contest/challenges/challenge-list/state/challenge.actions';
import * as ContestActions from '../../pages/contest/contests/contest-list/state/contest.actions';
import * as ApplicationUsersActions from '../../pages/idm/app-users/state/application-users.actions';
import * as DataleanUsersActions from '../../pages/idm/datalean-users/state/datalean-users.actions';
import * as GroupsActions from '../../pages/idm/groups/state/groups.actions';
import * as SmartGroupsActions from '../../pages/idm/smart-groups/state/smart-groups.actions';
import * as CoursesActions from '../../pages/lms/courses/state/courses.actions';
import * as LessonsActions from '../../pages/lms/lessons/state/lessons.actions';
import * as QuizActions from '../../pages/lms/quiz/state/quiz.actions';
import * as MediaLibraryActions from '../../pages/media-library/state/media-library.actions';
import * as ObBuilderActions from '../../pages/on-boarding/ob-city-builder/state/ob-builder.actions';
import * as ObConfiguratorActions from '../../pages/on-boarding/ob-configurator/state/ob-configurator.actions';
import * as PictogramStructureActions from '../../pages/pictogram/pictogram-structures/state/pictogram-structure.actions';
import * as PictogramActions from '../../pages/pictogram/pictograms/state/pictogram.actions';
import * as ProductVariantsActions from '../../pages/products-variants/state/product-variants.actions';
import * as ProductVersioningActions from '../../pages/products/state/product-versioning/product-versioning.actions';
import * as ProductActions from '../../pages/products/state/product.actions';
import * as CalendarActions from '../../pages/reservations/calendar/state/calendar.actions';
import * as BookableProductActions from '../../pages/reservations/products/state/bookable-product.actions';
import * as ResourceActions from '../../pages/reservations/resources/state/resource.actions';
import * as RolesActions from '../../pages/roles/store/roles.actions';
import * as CommunityActions from '../../pages/settings/communities/state/community.actions';
import * as IntegrationActions from '../../pages/settings/integrations/state/integration.actions';
import * as SurveyCommunicationsActions from '../../pages/surveys/state/survey-communication.actions';
import * as SurveysActions from '../../pages/surveys/state/surveys.actions';
import * as UsefulLinksActions from '../../pages/useful-links/state/useful-links.actions';
import * as CommunitySelectActions from '../../shared/state/community-select/community-select.actions';
import * as ConfigActions from '../../shared/state/config/config.actions';
import * as FeaturesActions from '../../shared/state/features/features.actions';
import * as NavigationActions from '../../shared/state/navigation/navigation.actions';
import * as RecipientsActions from '../../shared/state/recipients/recipients.actions';
import * as StructuresActions from '../../shared/state/structures/structure.actions';
import * as TagsAction from '../../shared/state/tags/tags.actions';
import * as UserActions from '../../shared/state/user/user.actions';
import * as WorkFlowActions from '../../shared/state/workflow/workflow.actions';
import * as HeaderActions from '../components/header/state/header.actions';
import * as ApplicationActions from './application/application.actions';
import * as PracticeSectionsActions from '../../pages/informed-consent/state/section/practice-section.actions'
import * as PracticeSectionsContainerActions from '../../pages/informed-consent/state/section-container/practice-section-container.actions'

export {
	ApplicationActions,
	ApplicationUsersActions,
	BookableProductActions,
	CalendarActions,
	ChallengeActions,
	CommunicationActions,
	CommunityActions,
	CommunitySelectActions,
	ConfigActions,
	ContentsActions,
	ContestActions,
	CoursesActions,
	DataleanUsersActions,
	FeaturesActions,
	GroupsActions,
	HeaderActions,
	IntegrationActions,
	LessonsActions,
	MediaLibraryActions,
	NavigationActions,
	ObBuilderActions,
	ObConfiguratorActions,
	PictogramActions,
	PictogramStructureActions,
	ProductActions,
	ProductVariantsActions,
	ProductVersioningActions,
	QuizActions,
	RecipientsActions,
	ResourceActions,
	RolesActions,
	SmartGroupsActions,
	StructuresActions,
	SurveyCommunicationsActions,
	SurveysActions,
	TagsAction,
	UsefulLinksActions,
	UserActions,
	WorkFlowActions,
  PracticeSectionsActions,
  PracticeSectionsContainerActions,
};
