<mat-accordion *ngrxLet="activePath$ as activePath">
	<mat-expansion-panel
		[routerLink]="navLink.path"
		*ngFor="let navLink of links$ | async"
		[disabled]="!navLink.children"
		[expanded]="checkIfNavLinkSelected(navLink, activePath)">
		<mat-expansion-panel-header [ngClass]="{ parent: navLink.children }" (click)="!navLink.children && this.accordion.closeAll()">
			<mat-panel-title>
				{{ navLink.label | translate }}
			</mat-panel-title>
		</mat-expansion-panel-header>
		<ng-container>
			<button *ngFor="let button of navLink.children; let i = index" [routerLink]="button.path" [ngClass]="{ active: button.path === activePath }">
				{{ button.label | translate }}
			</button>
		</ng-container>
	</mat-expansion-panel>
</mat-accordion>
