import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Collection } from 'addiction-components';
import { catchError, debounce, distinctUntilChanged, filter, map, of, switchMap, tap } from 'rxjs';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';
import { CollectionsService } from '../collections.service';
import {
	createCollection,
	createCollectionError,
	createCollectionSuccess,
	setCollectionsError,
	setCollectionsPages,
	setCollectionsSearch,
	setCollectionsSuccess,
	setFetchCollectionStatus,
	setReloadCollections,
	setReloadCollectionsKey,
} from './collections.actions';
import { selectCollectionsPages, selectCollectionsSearch, selectFetchCollectionStatus } from './collections.selectors';

@Injectable()
export class CollectionsEffects {
	private actions$ = inject(Actions);
	private store = inject(Store);
	private collectionService = inject(CollectionsService);

	constructor() {}

	fetchCollections$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setCollectionsPages, setReloadCollections, setCollectionsSearch),
			concatLatestFrom(() => [this.store.select(selectCollectionsPages), this.store.select(selectCollectionsSearch)]),
			debounce(() => this.store.select(selectFetchCollectionStatus).pipe(filter((stat) => !stat || stat !== STATE_STATUS.LOADING))),
			distinctUntilChanged(([, pages_prev, search_prev], [_action_new, pages_new, search_new]) => {
				//se ritorna true non effettua la chiamata
				if (_action_new.type === setReloadCollectionsKey)
					//se ho forzato la chiamata non voglio effettuare il controllo sotto
					return false;

				return pages_prev.length === pages_new.length && pages_prev.every((f) => pages_new.includes(f)) && search_prev === search_new;
			}),
			tap(() => this.store.dispatch(setFetchCollectionStatus({ status: STATE_STATUS.LOADING }))),
			switchMap(([, pages, search]) => this.collectionService.fetchCollections(pages, search)),
			map((data) => {
				// console.log('data', data);
				const startingResult: { pages: number[]; collections: Collection[][]; totalCollectionsCount: number } = {
					pages: [],
					collections: [],
					totalCollectionsCount: 0,
				};
				const result = data.reduce((acc, item) => {
					if (item.paginationInfo) {
						acc.pages.push(item.paginationInfo.numberOfPage);
						acc.totalCollectionsCount = item.paginationInfo.totalNumberOfElements;
						if (item.result) {
							acc.collections[item.paginationInfo.numberOfPage] = item.result;
						}
					}
					return acc;
				}, startingResult);
				return setCollectionsSuccess(result);
			}),

			catchError((collectionsError: HttpErrorResponse) => of(setCollectionsError({ collectionsError })))
		)
	);

	createCollection$ = createEffect(() =>
		this.actions$.pipe(
			ofType(createCollection),
			switchMap(({ collectionName }) => {
				return this.collectionService.createCollection(collectionName);
			}),
			map(() => {
				return createCollectionSuccess();
			}),
			catchError(() => of(createCollectionError()))
		)
	);

	createCollectionSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(createCollectionSuccess),
			concatLatestFrom(() => this.store.select(selectCollectionsPages)),
			map(() => setReloadCollections())
		)
	);
}
