import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, catchError, combineLatestWith, debounce, distinctUntilChanged, filter, first, map, of, switchMap, tap } from 'rxjs';
import { CommunitySelectActions, UsefulLinksActions } from '../../../core/state/app.actions';
import { CommunitySelectSelectors } from '../../../core/state/app.selectors';
import { STATE_STATUS } from '../../../shared/models';
import { selectLastCommunitySelectedForApiRest } from '../../../shared/state/community-select/community-select.selectors';
import { CommunicationService } from '../../communications/services/communication.service';
import { setUsefulLinksSuccess } from './useful-links.actions';
import {
	selectCachedPages,
	selectGridSearch,
	selectPagedData,
	selectPages,
	selectSort,
	selectStatus,
	selectUsefulLinksFilters,
} from './useful-links.selectors';

@Injectable()
export class UsefulLinksEffects {
	private store = inject(Store);
	private actions$ = inject(Actions);
	private communicationService = inject(CommunicationService);
	private translateService = inject(TranslateService);

	fetchUsefulLinks$ = createEffect(() =>
		this.actions$.pipe(
			//quando selezioni una categoria o fai una ricerca, viene triggherato il DamFilter, quindi ci registriamo ad
			//un suo eventuale successo per richiamare gli assets
			ofType(UsefulLinksActions.setPages, UsefulLinksActions.setForceRefreshUsefulLinks),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectUsefulLinksFilters),
				this.store.select(CommunitySelectSelectors.selectLastCommunitySelectedForApiRest),
				this.store.select(selectCachedPages),
			]),
			debounce(() => this.store.select(selectStatus).pipe(filter((stat) => !stat || stat !== 'loading'))),
			//non voglio caricare se non ho le pagine pronte
			filter(([, pages]) => !!pages?.length),
			tap(() => {
				this.store.dispatch(UsefulLinksActions.setStatus({ status: STATE_STATUS.LOADING }));
			}),
			switchMap(([, pages, sort, gridSearch, filters, communityUUID, cachedPages]) => {
				const actualPages = pages.filter((p) => !cachedPages.includes(p));
				if (!actualPages.length) {
					//se non faccio la chiamata non devo scordarmi di mettere a ready lo status
					this.store.dispatch(UsefulLinksActions.setStatus({ status: STATE_STATUS.READY }));
					return EMPTY;
				}

				const filterWithCommunity = structuredClone(filters);
				if (communityUUID) filterWithCommunity.communityUUID = communityUUID;

				return this.communicationService
					.fetchCommunications(
						actualPages,
						sort,
						this.translateService.currentLang ?? this.translateService.defaultLang,
						gridSearch,
						filterWithCommunity
					)
					.pipe(
						combineLatestWith(this.store.select(selectPagedData)),
						first(),
						map(([data, startingResult]) => {
							// console.log(startingResult);
							startingResult.pages = cachedPages;
							const result = data.reduce((acc, item) => {
								if (item.paginationInfo) {
									acc.pages.push(item.paginationInfo.pageNumber);
									acc.totalUsefulLinksCount = item.paginationInfo.totalElements ?? 0;
									if (item.result) {
										acc.usefulLinks[item.paginationInfo.pageNumber] = item.result.map((usefulLink) => ({
											...usefulLink,
											features: usefulLink.featureValueList ? usefulLink.featureValueList.map((f) => f.name || f.label) : [],
										}));
									}
								}
								return acc;
							}, structuredClone(startingResult));

							// console.log(result);
							return UsefulLinksActions.setUsefulLinksSuccess({ data: result });
						}),
						catchError((error: HttpErrorResponse) => {
							console.error(error);
							return of(UsefulLinksActions.setUsefulLinksError({ error }));
						})
					);
			})
		)
	);

	deleteCommunications$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UsefulLinksActions.deleteUsefulLinks),
			// invoke API
			switchMap(({ uuids }) => {
				return this.communicationService
					.multipleUpdate({
						uuidList: uuids,
						delete: true,
					})
					.pipe(
						map(() => {
							return UsefulLinksActions.setDeleteUsefulLinksSuccess();
						}),
						catchError((error: HttpErrorResponse) => of(UsefulLinksActions.error({ error: { errorType: 'generic_http', data: error } })))
					);
			})
		)
	);

	resetCache$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				UsefulLinksActions.setGridSearch,
				UsefulLinksActions.setSort,
				UsefulLinksActions.updateFilters,
				CommunitySelectActions.setLastCommunitySelected
			),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectUsefulLinksFilters),
				this.store.select(selectLastCommunitySelectedForApiRest),
				this.store.select(selectCachedPages),
			]),
			// //non voglio caricare se i dati delle azioni sono gli stessi
			distinctUntilChanged(
				([, pagesPrev, sortPrev, gridSearchPrev, filterPrev], [, pagesCurr, sortCurr, gridSearchCurr, filterCurr, , cachedPages]) => {
					// console.log(action);
					return (
						pagesPrev.length === pagesCurr.length &&
						pagesPrev.every((f) => pagesCurr.includes(f)) &&
						sortCurr?.active === sortPrev?.active &&
						sortCurr?.direction === sortPrev?.direction &&
						filterPrev === filterCurr &&
						gridSearchCurr === gridSearchPrev &&
						pagesPrev.filter((f) => !cachedPages.includes(f)).length === 0
					);
				}
			),
			map(() => UsefulLinksActions.resetCache())
		)
	);

	disableCommunications$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UsefulLinksActions.disableUsefulLinks),
			// invoke API
			switchMap(({ uuids }) =>
				this.communicationService.multipleUpdate({
					uuidList: uuids,
					active: false,
				})
			),
			// must re-fetch to repaginate
			map(() => UsefulLinksActions.setDisableUsefulLinksSuccess()),
			// error handling
			catchError((error: unknown) => of(UsefulLinksActions.error({ error: { errorType: 'generic_http', data: error } })))
		)
	);

	usefulLinksSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setUsefulLinksSuccess),
			map(({ data: { pages } }) => UsefulLinksActions.setCachedPages({ pages }))
		)
	);

	forceRefreshTable$ = createEffect(() =>
		this.actions$.pipe(
			ofType(UsefulLinksActions.resetCache),
			map(() => UsefulLinksActions.setForceRefreshUsefulLinks())
		)
	);

	deleteAndDisableUsefulLinks$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				UsefulLinksActions.setDeleteUsefulLinksSuccess,
				UsefulLinksActions.setDeleteUsefulLinksError,
				UsefulLinksActions.setDisableUsefulLinksSuccess,
				UsefulLinksActions.setDisableeUsefulLinksError
			),
			map(() => UsefulLinksActions.resetCache())
		)
	);

	deleteAndDisableCommunication$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				UsefulLinksActions.setDeleteUsefulLinksSuccess,
				UsefulLinksActions.setDeleteUsefulLinksError,
				UsefulLinksActions.setDisableUsefulLinksSuccess,
				UsefulLinksActions.setDisableeUsefulLinksError
			),
			map(() => UsefulLinksActions.resetCache())
		)
	);

	constructor() {}
}
