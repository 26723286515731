import { CdkDrag, CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Component, DestroyRef, EventEmitter, Input, OnChanges, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { concatLatestFrom } from '@ngrx/effects';
import { TranslatePipe } from '@ngx-translate/core';
import { ToLocalizedValuePipe } from 'addiction-components';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject, combineLatest, debounceTime, filter, first, map, merge, shareReplay, startWith, switchMap, tap } from 'rxjs';
import { DeepPartial } from '../../lib/deep-partial';
import { ObservableInputs } from '../../lib/observable-input';
import { createEmitter } from '../../lib/utils/create-emitter';
import { LocalizedString } from '../../models/localized-fields.model';
import { NodeMovement, TreeKeyOptions, TreeNode } from '../../models/tree.interface';
import { ModalService } from 'addiction-components';
import { SharedDragDropService } from '../../services/shared-drag-drop.service';
import { TreeSelectorDialogComponent } from '../tree-selector-dialog/tree-selector-dialog.component';
import {
	TreeBrowserButton,
	TreeBrowserConfig,
	TreeBrowserNode,
	TreeExternalDropEvent,
	TreeRemoveEvent,
	TreeRenameEvent,
} from './tree-browser.models';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare type Obj = { [key: string]: any }; // in questo caso è davvero ANY

@Component({
	selector: 'datalean-tree-browser',
	templateUrl: './tree-browser.component.html',
	styleUrls: ['./tree-browser.component.scss'],
})
export class TreeBrowserComponent<T = Record<string, unknown>> implements OnChanges {
	private translate = inject(TranslatePipe);
	private localization = inject(ToLocalizedValuePipe);
	private toast = inject(ToastrService);
	private modal = inject(ModalService);
	private sharedDragDrop = inject(SharedDragDropService, { optional: true });

	private readonly _inputs = new ObservableInputs(this);
	readonly defaultConfig: TreeBrowserConfig = {
		keys: {
			children: 'children',
			key: 'uuid',
			title: 'name',
			parentKey: 'parentUUID',
		},
		rootLabelKey: 'TREE.ROOT_LABEL',
	};
	protected readonly forbidChars = ['/', '\\', '.', '#'];
	protected inputConfig$ = this._inputs.observe(() => this.config);
	protected inputSelectedKey$ = this._inputs.observe(() => this.selectedKey, { startWithDefault: false });
	protected inputCurrentKey$ = this._inputs.observe(() => this.currentKey, { startWithDefault: false });
	protected inputNodes$ = this._inputs.observe(() => this.nodes);
	protected config$ = this.inputConfig$.pipe(
		map((cfg) => Object.assign(structuredClone(this.defaultConfig), structuredClone(cfg ?? {})) as TreeBrowserConfig)
	);

	/** Rappresenta il nodo attualmente mostrato (di cui vengono visuaizzati i figli). NON è il nodo "selezionato" */
	private userCurrentUUID$ = new BehaviorSubject<string | undefined>(undefined);
	protected currentUUID$ = merge(this.userCurrentUUID$, this.inputCurrentKey$);

	/** Rappresenta il nodo selezionato, che può essere diverso dal nodo mostrato */
	private userSelectedUUID$ = new BehaviorSubject<string | undefined>(undefined);
	protected selectedUUID$ = combineLatest([this.inputSelectedKey$, this.userSelectedUUID$]).pipe(
		filter(([a, b]) => a === b),
		map(([a]) => a)
	);
	protected selectedUUID: string | undefined;

	/** Gestione check the nodi */
	protected inputCheckedKeys$ = this._inputs.observe(() => this.checkedKeys, { startWithDefault: false });
	protected userCheckedKeys$ = new BehaviorSubject<string[]>([]);

	protected checkedKeys$ = merge(this.inputCheckedKeys$, this.userCheckedKeys$).pipe(shareReplay({ bufferSize: 1, refCount: false }));
	protected checkedNodes: TreeBrowserNode<T>[] = [];
	protected checkedNodes$ = this.checkedKeys$.pipe(
		concatLatestFrom(() => this.completeTree$),
		map(([keys, tree]) => this.findNodes(tree, (node) => keys.includes(node.id))),
		tap((nodes) => {
			this.checkedNodes = nodes;
			if (this.userCheckedKeys$.value.length == 0 && nodes.length > 0) {
				this.userCheckedKeys$.next(nodes.map((n) => n.id));
			}
		})
	);

	/** Gestione ricerca */
	protected userSearch$ = new BehaviorSubject<string | null>(null);
	protected search$ = this.userSearch$.pipe(
		debounceTime(300),
		tap(() => {
			return this.userCurrentUUID$.next(undefined);
		}),
		shareReplay({ refCount: true, bufferSize: 1 }),
		startWith('')
	);

	private treeConfig$ = combineLatest([this.config$, this.inputNodes$, this.checkedKeys$, this.search$]).pipe(
		shareReplay({ refCount: true, bufferSize: 1 })
	);
	private completeTree$ = this.treeConfig$.pipe(
		map(([cfg, nodes, checked]) => this.mapNestedTree(nodes, cfg, checked)),
		shareReplay({ refCount: true, bufferSize: 1 })
	);

	/* Main Tree Root */
	protected tree$ = this.treeConfig$.pipe(
		map(([cfg, nodes, checked, search]) => {
			const tree = this.mapNestedTree(nodes, cfg, checked);
			if (!search) return tree;

			return this.searchTree(tree, search);
		})
	);

	protected currentNode$ = combineLatest([this.tree$, this.currentUUID$]).pipe(
		map(([root, currentId]) => (currentId ? this.findNode(currentId, root) : root)),
		map((node) => {
			if (!node) return undefined;
			node.children = node.children.sort((a, b) => a.index - b.index);
			return node;
		})
	);

	private destroyRef = inject(DestroyRef);

	/** Nodi di input. Si raccomanda di usare degli immutabili (tipo store) per ragioni di performance */
	@Input() nodes: T[] = [];
	@Input() config?: DeepPartial<TreeBrowserConfig>;
	@Input() sortable: boolean = false;
	@Input() clonable: boolean = false;
	@Input() removable: boolean = false;
	@Input() renameble: boolean = false;
	@Input() checkable: boolean = false;
	@Input() selectable: boolean = true;
	@Input() addable: boolean = false;
	@Input() selectedKey?: string;
	@Input() currentKey?: string;
	@Input() checkedKeys: string[] = [];
	@Input() allowSearch: boolean = false;
	@Input() customButtons: TreeBrowserButton[] = [];
	@Input() buttonDisable: boolean | null = false;
	@Input() editing: boolean = false;

	//utilizzato per nascondere il pulsante 'Permessi' del dottedMenu, (in combo al fatto se è )
	@Input() editablePermissions: boolean = false;

	@Output() selected = new EventEmitter<T>();
	@Output() sorted = new EventEmitter<NodeMovement<T> & { currentNode: TreeBrowserNode<T> }>();
	@Output() cloned = new EventEmitter<{ node: TreeBrowserNode; feature: T }>();
	@Output() editedPermissions = new EventEmitter<{ feature: T }>();
	@Output() removed = new EventEmitter<TreeRemoveEvent<T>>();
	@Output() renamed = new EventEmitter<TreeRenameEvent<T>>();
	@Output() moved = new EventEmitter<NodeMovement<T>>();
	@Output() checked = createEmitter(
		this.destroyRef,
		this.userCheckedKeys$.pipe(
			concatLatestFrom(() => this.completeTree$),
			map(([keys, tree]) => this.findNodes(tree, (node) => keys.includes(node.id))),
			map((nodes) => nodes.map((node) => node.data))
		)
	);
	@Output() added = new EventEmitter<{ parent: string; node?: TreeBrowserNode }>();
	@Output() externalItemDropped = new EventEmitter<TreeExternalDropEvent<T>>();

	get editable() {
		return this.sortable || this.clonable || this.removable || this.renameble;
	}

	constructor() {
		this.selectedUUID$.pipe(takeUntilDestroyed()).subscribe((uuid) => {
			this.selectedUUID = uuid;
		});
	}

	ngOnChanges() {
		this._inputs.onChanges();
	}

	toggleEditing() {
		this.editing = !this.editing;
	}

	add(parent: string, node: TreeBrowserNode) {
		let parentNode: TreeBrowserNode | undefined = node;
		const draftFound: boolean = this.findNode('new', node) ? true : false;
		if ((!parent && this.selectedUUID) || (parent !== this.selectedUUID && this.selectedUUID)) {
			parent = this.selectedUUID;
			parentNode = this.findNode(parent, node);
		}

		if (parent && parent != 'new' && !draftFound) {
			this.added.emit({ parent, node: parentNode! });
		} else {
			this.added.emit({ parent: 'new', node: parentNode });
		}
	}

	findNode(id: string, root: TreeBrowserNode<T>): TreeBrowserNode<T> | undefined {
		const results = this.findNodes(root, (node) => node.id === id);
		return results.length > 0 ? results[0] : undefined;
	}

	findNodes(root: TreeBrowserNode<T>, predicate: (note: TreeBrowserNode) => boolean): TreeBrowserNode<T>[] {
		const found: TreeBrowserNode<T>[] = [];
		if (predicate(root)) found.push(root);
		for (const child of root.children) {
			found.push(...this.findNodes(child, predicate));
		}
		return found;
	}
	findNodesById(id: string, root: TreeBrowserNode<T>): TreeBrowserNode<T>[] {
		return this.findNodes(root, (node) => node.id === id);
	}

	customBtnClick(btn: TreeBrowserButton) {
		if (btn.onClick) btn.onClick();
	}

	protected clone(node: TreeBrowserNode<T>) {
		this.cloned.emit({ node, feature: node.data });
	}

	protected openPermissions(node: TreeBrowserNode<T>) {
		this.editedPermissions.emit({ feature: node.data });
	}

	protected remove(node: TreeBrowserNode<T>) {
		const { data, parent, index } = node;
		this.removed.emit({
			node: data,
			parent: parent?.data,
			index,
		});
	}
	protected nodeRenamed(node: TreeBrowserNode<T>, target: EventTarget, siblings: TreeBrowserNode<T>[]) {
		const input = target as HTMLInputElement;
		const newName = input.value;
		const validity = this.checkNameValidity(newName, siblings);
		if (validity == 'ok') {
			// name is ok
			node.title = newName;
			this.renamed.emit({
				name: newName,
				node: node.data,
			});
			return;
		}
		// reset the title
		input.value = node.title;

		if (validity === 'name_taken') this.toast.error(this.translate.transform('TREE.ERRORS.NAME_TAKEN'));
	}

	searchTree(root: TreeBrowserNode<T>, search: string): TreeBrowserNode<T> {
		const results = this.findNodes(root, (node) => node.title.toLowerCase().includes(search.toLowerCase()));
		const newRoot = { ...root, children: results };
		return newRoot;
	}

	mapNestedTree(sourceNodes: T[], cfg: TreeBrowserConfig, checkedKeys?: string[]) {
		return this.mapTree(this.buildFakeRoot(sourceNodes, cfg), 0, undefined, cfg.keys, checkedKeys);
	}

	/** Esplora e rimappa l'albero, staticizzando i campi dinamici   */
	mapTree(
		sourceNode: T,
		index: number,
		prevNode: TreeBrowserNode<T> | undefined,
		keyMap: TreeKeyOptions,
		checkedKeys?: string[]
	): TreeBrowserNode<T> {
		const id = this.getID(sourceNode, keyMap);
		const node: TreeBrowserNode<T> = {
			id,
			title: this.getTitle(sourceNode, keyMap),
			children: [],
			parent: prevNode,
			isRoot: !prevNode,
			data: sourceNode,
			error: false,
			index,
			type: 'tree-browser-node',
			checked: checkedKeys?.includes(id) ?? false,
		};
		node.children = this.getChildren(sourceNode, keyMap).map((child, i) =>
			this.mapTree(child, 'order' in (child as Obj) ? +`${(child as Obj)['order']}` : i, node, keyMap, checkedKeys)
		);

		return node;
	}

	checkNameValidity(name: string, siblings: TreeBrowserNode<T>[]): 'name_taken' | 'invalid' | 'ok' {
		if (!name || name.trim().length < 1) return 'invalid';

		for (const char of name) if (this.forbidChars.includes(char)) return 'invalid';

		if (siblings.find((node) => node.title === name)) return 'name_taken';

		return 'ok';
	}
	/** Costruisce un nodo finto che contiene i nodi di primo livello (comodo per l'esplorazione dell'albero e non dover gestire casi particolari
	 * se si è al primo livello) */
	buildFakeRoot(nodes: T[], cfg: TreeBrowserConfig): T {
		return {
			[cfg.keys.children]: structuredClone(nodes),
			[cfg.keys.title]: this.translate.transform(cfg.rootLabelKey),
		} as T;
	}

	onDrop(event: CdkDragDrop<TreeBrowserNode<T>, TreeBrowserNode<T>>, currentNode: TreeBrowserNode<T>) {
		const { item, currentIndex, previousIndex } = event;
		if (item.data && item.data.type === 'tree-browser-node') {
			// Sorted a node
			if (currentIndex === previousIndex) return;

			const node = item.data as TreeBrowserNode<T>;
			moveItemInArray(currentNode.children, previousIndex, currentIndex);

			this.sorted.emit({
				newIndex: currentIndex,
				node: node.data as TreeNode<T>,
				newParent: node.parent?.data as TreeNode<T>,
				currentNode: currentNode,
			});
		} else {
			// dropped a node from outside
			if (!this.sharedDragDrop?.lastDropTarget) return;

			// get drop target node (è un po' hackish, ma non ho trovato altre soluzioni, le API di Drag&Drop fanno schifo)
			const id = this.sharedDragDrop.lastDropTarget.getAttribute('data-id');

			const targetNode = id ? this.findNode(id, currentNode) : currentNode;
			if (!targetNode) return;

			this.externalItemDropped.emit({
				droppedItem: item.data,
				target: targetNode.data as TreeNode<T>,
			});
		}
	}

	onMoveTo(evt: CdkDragDrop<TreeNode<T>>, node: TreeBrowserNode<T>) {
		const draggedNode = evt.item.data as TreeBrowserNode<T>;
		this.moved.emit({
			newIndex: 0, // non ha senso
			node: draggedNode.data as TreeNode<T>,
			newParent: node.data as TreeNode<T>,
		});
	}

	/** Naviga in un'altra cartella
	 * @param select Se TRUE, oltre a navigare emette anche l'evento di selezione completata
	 */
	goTo(node: TreeBrowserNode<T>, select: boolean) {
		this.userCurrentUUID$.next(node.id);
		if (select) this.select(node);
	}

	select(node: TreeBrowserNode) {
		if (this.selectedUUID != node.id || node.isRoot) this.selected.emit(node.data);
		this.userSelectedUUID$.next(node.id);
	}

	// Getters using dynamic object keys
	getChildren(node: T, keys: TreeKeyOptions) {
		const { children } = keys;
		const n = node as Obj;

		if (!Array.isArray(n[children])) return [];

		return n[children] as T[];
	}
	getParent(node: T, keys: TreeKeyOptions) {
		const { parentKey } = keys;
		if (!parentKey) return undefined;

		return (node as Obj)[parentKey] as string;
	}
	getID(node: T, keys: TreeKeyOptions) {
		const { key } = keys;
		return (node as Obj)[key] as string;
	}

	getTitle(node: T, keys: TreeKeyOptions) {
		const { title } = keys;
		const value = (node as Obj)[title] as string | LocalizedString;

		if (typeof value === 'string') return value;

		return this.localization.transform(value);
	}

	openMoveDialog(node: TreeBrowserNode<T>) {
		const { title } = node;
		this.tree$
			.pipe(
				first(),
				switchMap((root) => {
					const inputs = {
						dataSelector: root.children.map((child) => child.data as Record<string, unknown>),
						multiple: false,
						key: 'uuid',
						children: 'children',
						title: 'name',
					};
					return this.modal.openDialog(TreeSelectorDialogComponent, { title: 'Sposta Cartella "' + title + '"' }, inputs).result$.pipe(
						// aggiunge i due risultati, forwardando root
						map((result) => ({ root, result }))
					);
				})
			)
			.subscribe(({ root, result }) => {
				if (result.reason === 'COMPLETE' && result.data?.selectedUUID)
					this.moved.emit({
						newIndex: 0,
						node: node.data as TreeNode<T>,
						newParent: this.findNode(result.data.selectedUUID, root)?.data as TreeNode<T>,
					});
			});
	}

	sortPredicate(index: number, item: CdkDrag) {
		return item.data && item.data.type === 'tree-browser-node';
	}

	toggleChecked(node: TreeBrowserNode<T>, override?: boolean) {
		// if override is undefined, toggle the value. Otherwise just set it
		node.checked = override != undefined ? override : !node.checked;

		// console.log('node.checked', node.checked);

		const currentKeys = this.checkedNodes.map((n) => n.id);
		if (node.checked) {
			// adds to selection nodes from root to this node
			const parents = this.getPathToRoot(node).map((n) => n.id);
			const selection = [...parents, node.id];
			this.userCheckedKeys$.next([...currentKeys, ...selection].distinct());
		} else this.userCheckedKeys$.next(currentKeys.filter((key) => key !== node.id));
	}

	getPathToRoot(node: TreeBrowserNode<T>) {
		const path: TreeBrowserNode<T>[] = [];
		let current = node.parent;
		while (current && !current.isRoot) {
			path.push(current);
			current = current.parent;
		}
		return path.reverse();
	}
}
