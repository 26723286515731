import { createAction, props } from '@ngrx/store';
import { WorkFlowConfig, WorkFlowEntity, WorkFlowList } from '../../models/workFlow';
import { Structure, WorkFlowType } from 'addiction-components';
import { STATE_STATUS } from '../../models';
import { Sort } from '@angular/material/sort';
import { HttpErrorResponse } from '@angular/common/http';
import { FiltersType } from './workflow.reducer';

export const setWorkFlowConfig = createAction('[WorkFlow] Set WorkFlowConfig', props<{ workFlowConfig: WorkFlowConfig[] | undefined }>());
export const setWorkFlowInstance = createAction('[WorkFlow] Set WorkFlowInstance', props<{ workFlowInstance: WorkFlowEntity[] | undefined }>());
export const setStructures = createAction('[WorkFlow] Set WorkFlow Structures', props<{ structures: Array<Structure> }>());
export const resetWorkFlow = createAction('[WorkFlow] Reset WorkFlow');
export const workflowTypeChanged = createAction('[WorkFlow] WorkFlow Type Changed', props<{ workflowType: WorkFlowType }>());
export const setWorkFlowList = createAction('[WorkFlow] Set Force Refresh', props<{ workflowType: WorkFlowType }>());
export const setStatus = createAction('[WorkFlow] Set status', props<{ status: STATE_STATUS }>());
export const setPages = createAction('[WorkFlow] Set pages', props<{ pages: number[] }>());
export const setForceRefreshWorkFlow = createAction('[WorkFlow] Set Force Refresh');
export const setSort = createAction('[WorkFlow] Set sort', props<{ sort: Sort }>());
export const setWorkFlowSuccess = createAction(
	'[WorkFlow] Set WorkFlow Success',
	props<{ data: { totalWorkFlowCount: number; pages: number[]; workFlows: WorkFlowList[][] } }>()
);
export const setWorkFlowError = createAction('[WorkFlow] Set WorkFlow Error', props<{ error: HttpErrorResponse }>());
export const setGridSearch = createAction('[WorkFlow] Set search', props<{ search: string | null }>());
export const setFilters = createAction('[WorkFlow] Set Filters', props<{ filters: FiltersType }>());
export const updateFilters = createAction('[WorkFlow] Update Filters', props<{ updatedFilters: FiltersType }>());
export const setCachedPages = createAction('[WorkFlow] Set cached pages', props<{ pages: number[] }>());
export const resetCache = createAction('[WorkFlow] reset cache');
