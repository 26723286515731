import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, catchError, combineLatestWith, debounce, distinctUntilChanged, filter, first, map, mergeMap, of, switchMap, tap } from 'rxjs';
import { CommunitySelectSelectors } from 'src/app/core/state/app.selectors';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';
import { ApplicationUsersActions, CommunitySelectActions } from '../../../../core/state/app.actions';
import { ApplicationUsersService } from '../services/application-users.service';
import {
	setApplicationUsersError,
	setApplicationUsersSuccess,
	setCountPerStructureError,
	setCountPerStructures,
	setCountPerStructuresSuccess,
	setDeleteApplicationUser,
	setDeleteApplicationUserError,
	setDeleteApplicationUserSuccess,
	setForceRefreshApplicationUsers,
	setPages,
	setStructures,
	setStructuresSuccess,
} from './application-users.actions';
import {
	selectCachedPages,
	selectGridSearch,
	selectPagedData,
	selectPages,
	selectSort,
	selectStatus,
	selectStructureUUID,
	selectUserStructures,
} from './application-users.selectors';

@Injectable()
export class ApplicationUsersEffects {
	private actions$ = inject(Actions);
	private store = inject(Store);
	private userService = inject(ApplicationUsersService);

	constructor() {}

	fetchUsers$ = createEffect(() =>
		this.actions$.pipe(
			//quando selezioni una categoria o fai una ricerca, viene triggherato il DamFilter, quindi ci registriamo ad
			//un suo eventuale successo per richiamare gli assets
			ofType(setPages, setForceRefreshApplicationUsers),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectStructureUUID),
				this.store.select(selectCachedPages),
				this.store.select(CommunitySelectSelectors.selectLastCommunitySelectedForApiRest),
			]),
			debounce(() =>
				this.store.select(selectStatus).pipe(
					filter((stat) => {
						return !stat || stat !== 'loading';
					})
				)
			),
			//non voglio caricare se non ho le pagine pronte
			filter(([, pages, , , structureUUID]) => !!pages?.length && !!structureUUID),
			tap(() => {
				this.store.dispatch(ApplicationUsersActions.setStatus({ status: STATE_STATUS.LOADING }));
			}),
			switchMap(([, pages, sort, gridSearch, structureUUID, cachedPages, communityUUID]) => {
				// il filtro sopra si occupa di controllare che structureUUID sia valorizzato
				const actualPages = pages.filter((p) => !cachedPages.includes(p));
				if (!actualPages.length) return EMPTY;

				return this.userService.fetchUsers(actualPages, structureUUID!, sort, gridSearch, communityUUID).pipe(
					combineLatestWith(this.store.select(selectPagedData)),
					first(),
					map(([data, startingResult]) => {
						startingResult.pages = cachedPages;

						const result = data.reduce((acc, item) => {
							if (item.paginationInfo) {
								acc.pages.push(item.paginationInfo.numberOfPage);
								acc.totalUsersCount = item.paginationInfo.totalNumberOfElements;
								if (item.result) {
									acc.users[item.paginationInfo.numberOfPage] = item.result;
								}
							}
							return acc;
						}, structuredClone(startingResult));

						return setApplicationUsersSuccess({ data: result });
					}),
					catchError((error: HttpErrorResponse) => {
						return of(setApplicationUsersError({ error }));
					})
				);
			})
		)
	);

	resetCache$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				ApplicationUsersActions.setStructureUUID,
				ApplicationUsersActions.setGridSearch,
				ApplicationUsersActions.setSort,
				CommunitySelectActions.setLastCommunitySelected
			),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectStructureUUID),
				this.store.select(selectCachedPages),
				this.store.select(CommunitySelectSelectors.selectLastCommunitySelectedForApiRest),
			]),
			//non voglio caricare se i dati delle azioni sono gli stessi
			distinctUntilChanged(
				(
					[, pagesPrev, sortPrev, gridSearchPrev, structureUUIDPrev, , communityUUIDPrev],
					[, pagesCurr, sortCurr, gridSearchCurr, structureUUIDCurr, cachedPaged, communityUUIDCurr]
				) => {
					return (
						structureUUIDPrev === structureUUIDCurr &&
						pagesPrev.length === pagesCurr.length &&
						pagesPrev.every((f) => pagesCurr.includes(f)) &&
						sortCurr?.active === sortPrev?.active &&
						communityUUIDPrev === communityUUIDCurr &&
						sortCurr?.direction === sortPrev?.direction &&
						gridSearchCurr === gridSearchPrev &&
						pagesPrev.filter((f) => !cachedPaged.includes(f)).length === 0
					);
				}
			),
			map(() => ApplicationUsersActions.resetCache())
		)
	);

	deleteUser$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setDeleteApplicationUser),
			switchMap(({ user }) => {
				return this.userService.deleteUser(user).pipe(
					map(() => {
						return setDeleteApplicationUserSuccess();
					}),
					catchError((error: HttpErrorResponse) => {
						return of(setDeleteApplicationUserError({ error }));
					})
				);
			})
		)
	);

	afterFetchApplicationUser$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setApplicationUsersSuccess),
			mergeMap(({ data: { pages } }) => {
				const setStructuresAction = setStructures();
				const setCachedPagesAction = ApplicationUsersActions.setCachedPages({ pages });
				return [setStructuresAction, setCachedPagesAction];
			})
		)
	);

	afterDeleteApplicationUser$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ApplicationUsersActions.setDeleteApplicationUserSuccess, ApplicationUsersActions.setDeleteApplicationUserError),
			map(() => ApplicationUsersActions.resetCache())
		)
	);

	forceRefreshTable$ = createEffect(() =>
		this.actions$.pipe(
			ofType(ApplicationUsersActions.resetCache),
			map(() => ApplicationUsersActions.setForceRefreshApplicationUsers())
		)
	);

	fetchUserStructures$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setStructures),
			switchMap(() =>
				this.userService.fetchUserStructures().pipe(
					map((userStructures) => {
						return setStructuresSuccess({ userStructures });
					}),
					catchError((error: HttpErrorResponse) => {
						return of(setApplicationUsersError({ error }));
					})
				)
			)
		)
	);

	// setFirstStructureUUID$ = createEffect(() =>
	// 	this.actions$.pipe(
	// 		ofType(setStructuresSuccess),

	// 		concatLatestFrom(() => this.store.select(selectStructureUUID)),
	// 		map(([action, structureUUID]) => {
	// 			if (structureUUID) {
	// 				return setStructureUUID({ structureUUID });
	// 			}
	// 			return setStructureUUID({ structureUUID: action.userStructures[0].uuid });
	// 		})
	// 	)
	// );

	afterFetchUserStructures$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setStructuresSuccess),
			map(() => setCountPerStructures())
		)
	);

	fetchCount$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setCountPerStructures),
			concatLatestFrom(() => this.store.select(selectUserStructures)),
			switchMap(([, userStructures]) => {
				return this.userService.fetchCountForEachStructure(userStructures.map((s) => s.uuid)).pipe(
					map((userCountPerStructureMap) => {
						return setCountPerStructuresSuccess({ userCountPerStructureMap: userCountPerStructureMap });
					}),
					catchError((error: HttpErrorResponse) => {
						return of(setCountPerStructureError({ error }));
					})
				);
			})
		)
	);
}
