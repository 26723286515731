<addiction-search (search)="filterBy($event)"></addiction-search>

<ng-container *ngrxLet="tableRows$ as data">
  <addiction-list
    (pageChanges)="onPageChanged($event)"
    [data]="data"
    [pageSize]="pageSize"
    [itemSize]="40"
    [tableHeaderTemplate]="tableHeaderTemplate"
    [rowTemplate]="rowTemplate"
    [noItemsTemplate]="noItemsTemplate">
  </addiction-list>
</ng-container>

<ng-template #tableHeaderTemplate let-data="data">
  <div class="single-row-header">
    <div class="single-column checkbox">
      <mat-checkbox
        [checked]="gcs.selectAllChecked"
        (change)="onSelectionChange($event, 'all')"
        [indeterminate]="gcs.selectAllIndeterminate">
      </mat-checkbox>
    </div>
    <div class="single-column double first-name">
      <span class="highlight">{{ 'LIST_HEADER.NAME' | translate }}</span>
    </div>
    <div class="single-column double email">
      <span>{{ 'LIST_HEADER.EMAIL' | translate }}</span>
    </div>
  </div>
</ng-template>

<ng-template #rowTemplate let-item="item">
  <div class="single-row" *ngIf="item">
    <div class="single-column checkbox">
      <mat-checkbox
        (click)="$event.stopPropagation()"
        class="checkbox"
        [checked]="gcs.checkboxMap[item.uuid] === undefined ? gcs.selectAllChecked : gcs.checkboxMap[item.uuid]"
        (change)="onSelectionChange($event, item.uuid)">
      </mat-checkbox>
    </div>
    <div class="single-column double first-name">
      <span class="highlight">{{ item.name }}</span>
    </div>
    <div class="single-column double email">
      <span>{{ item.email }}</span>
    </div>
  </div>

  <div class="single-row-skeleton" *ngIf="!item">
    <div class="single-column checkbox">
      <span class="checkbox"></span>
    </div>
    <div class="single-column double">
      <span class="highlight"></span>
    </div>
    <div class="single-column double">
      <span></span>
    </div>
  </div>
</ng-template>

<ng-template #noItemsTemplate>
  <div class="no-results-container">
    <ng-lottie [options]="{ path: './assets/lottie/icn-dam-nocontent.json' }"></ng-lottie>
    <div class="title">
      {{ 'GRID.NO_RESULTS_TITLE' | translate }}
    </div>
    <div class="text">
      {{ 'GRID.NO_RESULTS_TEXT' | translate }}
    </div>
  </div>
</ng-template>

<div class="buttons-container">
	<button (click)="close()">
		<span>{{ 'ADD' | translate }}</span>
	</button>
</div>
