<ng-container *ngIf="header; else hTemplate">
	<ng-container *ngTemplateOutlet="header.template; context: { $implicit: header.context }"></ng-container>
</ng-container>
<ng-template #hTemplate>
	<ng-container *ngIf="headerTemplate">
		<ng-container *ngTemplateOutlet="headerTemplate"></ng-container>
	</ng-container>
</ng-template>

<table cdk-table [dataSource]="dSource" multiTemplateDataRows>
	<ng-container *ngFor="let column of columns$ | async" [cdkColumnDef]="column.columnDef">
		<th cdk-header-cell *cdkHeaderCellDef [class]="column.thClass">
			{{ column.header | translate }}
		</th>

		<td cdk-cell *cdkCellDef="let element" [class]="column.tdClass">
			<ng-container *ngIf="loading; else loaded">
				<span class="skeleton"></span>
			</ng-container>
			<ng-template #loaded>
				<ng-container *ngIf="hasTemplate(column); else base">
					<ng-container *ngTemplateOutlet="column.template; context: { $implicit: element }"></ng-container>
				</ng-container>
				<ng-template #base>
					<ng-container *ngIf="column.columnDef === 'check'; else std">
						<addiction-checkbox [value]="isRowChecked(column, element)" (valueChange)="checkRow(element, $event)"></addiction-checkbox>
					</ng-container>

					<ng-template #std>
						<ng-container *ngIf="!hasTemplate(column)">
							{{ column.cell(element) }}
						</ng-container>
					</ng-template>
				</ng-template>
			</ng-template>
		</td>
	</ng-container>

	<ng-container cdkColumnDef="expandedArea">
		<th cdk-header-cell *cdkHeaderCellDef></th>
		<td cdk-cell *cdkCellDef="let element" [attr.colspan]="columns.length" class="expanded-cell">
			<ng-container *ngIf="accordionExpandedTemplate">
				<ng-container *ngTemplateOutlet="accordionExpandedTemplate; context: { $implicit: element }"></ng-container>
			</ng-container>
		</td>
	</ng-container>

	<ng-container *ngIf="displayedColumns$ | async as displayedColumns">
		<ng-container *ngIf="accordion; else stdRow">
			<cdk-accordion [multi]="true">
				<tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
				<tr
					cdk-row
					*cdkRowDef="let row; columns: displayedColumns"
					(click)="rowClick.emit(row)"
					[ngClass]="{ 'single-row-skeleton': loading, 'expanded': accordionExpandedList[row[accordionExpandedListKey]] }"
					[class]="trClass"></tr>

				<tr
					cdk-row
					*cdkRowDef="let row; columns: ['expandedArea']"
					class="expanded-row"
					[class.expanded]="accordionExpandedList[row[accordionExpandedListKey]]"></tr>
			</cdk-accordion>
		</ng-container>
		<ng-template #stdRow>
			<tr cdk-header-row *cdkHeaderRowDef="displayedColumns"></tr>
			<tr
				cdk-row
				*cdkRowDef="let row; columns: displayedColumns"
				(click)="rowClick.emit(row)"
				[ngClass]="{ 'single-row-skeleton': loading }"
				[class]="trClass"></tr>
		</ng-template>
	</ng-container>
</table>

<ng-container *ngIf="noItemsTemplate && !rowCount">
	<ng-container *ngTemplateOutlet="noItemsTemplate"></ng-container>
</ng-container>

<ng-container>
	<datalean-paginator
		[pageSize]="pageSize"
		[currentPage]="curPage - 1"
		[totalItems]="rowCount"
		(pageChange)="onPageChange($event)"></datalean-paginator>
</ng-container>
