import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import { Asset, FeatureValue, ISortInfo, SortInfo, ThumbnailConfig } from 'addiction-components';
import { createContextAction } from 'context-store';
import { AssetSaveData, AssetUpdateBody, Conversion, STATE_STATUS } from 'src/app/shared/models';
import {
	AssetQueue,
	MediaLibErrorType,
	MediaLibraryAvailableFilters,
	MediaLibraryError,
	MediaLibraryFilters,
	MediaLibraryViewMode,
} from './media-library.state';

// API functions
export const setForceRefreshAssets = createContextAction('[Media Library] Force refresh assets');
export const changeFeatureValue = createContextAction('[Media Library] Change FeatureValue', props<{ path: string[] }>());
export const setViewModeKEY = '[Media Library] Set View Mode';
export const setViewMode = createContextAction(
	setViewModeKEY,
	props<{ filters?: MediaLibraryFilters; viewMode: MediaLibraryViewMode; mergeFilters?: boolean }>()
);
export const fetchAssets = createContextAction('[Media Library] Fetch Assets', props<{ filters?: MediaLibraryFilters; sort?: SortInfo }>());
export const assetsLoaded = createContextAction(
	'[Media Library] Assets Loaded',
	props<{ assets: Asset[][]; pages: number[]; totalAssetCount: number }>()
);
export const fetchFeatureValues = createContextAction('[Media Library] Fetch FeatureValues');
export const featureValuesLoaded = createContextAction('[Media Library] FeatureValues Loaded', props<{ featureValues: FeatureValue[] }>());

export const moveAssets = createContextAction('[Media Library] Move Assets', props<{ uuids: string[]; newParent: string | undefined }>());
//sort
export const setSort = createContextAction('[Media Library] Set Sort', props<{ sort: ISortInfo }>());

//bulkAction
export const regenerateThumbnails = createContextAction('[Media Library] Regenerate thumbnails', props<{ body: AssetUpdateBody }>());
export const thumbnailsregenerated = createContextAction('[Media Library] Regenerating thumbnails completed', props<{ uuids: string[] }>());
export const updateFeatureValueLists = createContextAction('[Media Library] update FeatureValues', props<{ body: AssetUpdateBody }>());
export const updatedFeatureValues = createContextAction('[Media Library] updating Feature Values completed', props<{ uuids: string[] }>());

// delete
export const deleteAsset = createContextAction('[Media Library] Delete Asset', props<{ uuid: string }>());
export const assetDeleted = createContextAction('[Media Library] Asset Delete Completed', props<{ uuid: string }>());
export const assetsDeleted = createContextAction('[Media Library] Assets Delete Completed', props<{ uuids: string[] }>());
export const assetDeleteError = createContextAction('[Media Library] Asset Delete Error', props<{ error: unknown }>());
export const deleteAssets = createContextAction('[Media Library] Delete Assets', props<{ body: AssetUpdateBody }>());

// save
export const createAsset = createContextAction('[Media Library] Create Asset', props<{ asset: AssetSaveData; queue?: AssetQueue[] }>());
export const assetCreated = createContextAction('[Media Library] Asset Created', props<{ asset: Asset; queue?: AssetQueue[] }>());
export const updateAsset = createContextAction('[Media Library] Update Asset', props<{ asset: AssetSaveData }>());
export const assetUpdated = createContextAction('[Media Library] Asset Updated', props<{ asset: Asset }>());
export const assetUploadProgress = createContextAction(
	'[Media Library] Asset Upload Progress Changed',
	props<{ progress: number; queue?: AssetQueue[] }>()
);
export const assetSaveError = createContextAction(
	'[Media Library] Asset Save Error',
	props<{ errorType?: MediaLibErrorType; data?: unknown; queue?: AssetQueue[] }>()
);
export const assetUpdateQueue = createContextAction('[Media Library] Asset UpdateQueue Changed', props<{ queue: AssetQueue[] }>());
export const resolveQueue = createContextAction('[Media Library] Asset Resolve Queue Changed', props<{ process: boolean; queue: AssetQueue[] }>());
export const clearQueue = createContextAction('[Media Library] Asset Clear Queue Changed', props<{ clear: boolean }>());
export const stopQueue = createContextAction('[Media Library] Asset Stop Queue Changed', props<{ stop: boolean; queue?: AssetQueue[] }>());
export const interruptUpload = createContextAction(
	'[Media Library] Asset Interrupt Uploading asset in Queue Changed',
	props<{ interruptUpload: boolean; queue?: AssetQueue[] }>()
);

// clone
export const cloneAsset = createContextAction('[Media Library] Clone Asset', props<{ uuid: string }>());
export const assetCloned = createContextAction('[Media Library] Asset Cloned', props<{ sourceUUID: string; newAsset: Asset }>());
export const cloneError = createContextAction('[Media Library] Asset Clone Error', props<{ error: unknown }>());

/* Selection */
export const setSelectedAsset = createContextAction('[Media Library] Set Selected Asset', props<{ asset: Asset }>());
export const resetSelectedAsset = createContextAction('[Media Library] Reset Selected Asset');

/** Reload both assets (images) and folders using current filters\pagination */
export const fetchAll = createContextAction('[Media Library] Fetch All', props<{ path?: string; filters?: MediaLibraryFilters | '*' }>());

// Paging
export const changePage = createContextAction('[Media Library] Change Page', props<{ page: number }>());
export const resetPage = createContextAction('[Media Library] Reset Page');

// Thumbnail configs
export const setThumbnailConfigs = createAction('[Media Library] Set Thumbnail Configs', props<{ thumbnailConfigs: Array<ThumbnailConfig> }>());
export const setConversions = createAction('[Media Library] Set Conversions', props<{ conversions: Array<Conversion> | undefined }>());

export const setPages = createContextAction('[Grid] Set pages', props<{ pages: number[] }>());

// Error
export const error = createContextAction('[Media Library] Error', props<{ error: MediaLibraryError }>());

export const setLoading = createContextAction('[Media Library] Loading');

// Scrolling
export const setTopScroll = createAction('[Media Library] Set Top Scroll', props<{ topScroll: number }>());

//FILTRI
export const setOldFilters = createContextAction('[Media Library] Set old filters', props<{ oldFilters?: MediaLibraryFilters }>()); //non poteno usare il distinctUntilChanged salvo i vecchi filtri per poterli confrontare
export const setAvailableFiltersSuccessKEY = '[Media Library] Set status ok for fetchAvailableFilters';
export const setAvailableFiltersSuccess = createContextAction(setAvailableFiltersSuccessKEY, props<MediaLibraryAvailableFilters>());
export const setAvailableFiltersStatus = createContextAction(
	'[Media Library] Set call status for availablefilter',
	props<{ status: STATE_STATUS }>()
);
export const setAvailableFiltersError = createContextAction(
	'[Media Library] Set status error for availablefilter',
	props<{ availableFiltersError: HttpErrorResponse }>()
);
export const fetchAvailableFilters = createContextAction('[Media Library] Set status loading for fetchDamFilters');
export const setStatus = createContextAction('[Media Library] Set status loading for assets', props<{ assetStatusLoading: STATE_STATUS }>());
