export enum Permission {
	/* Navigazione Principale */
	READ_CONFIGURATION = 'READ_CONFIGURATION',
	READ_PRODUCTS = 'READ_PRODUCTS',
	READ_PRODUCTS_VARIANTS = 'READ_PRODUCTS_VARIANTS',
	READ_COMMUNICATIONS = 'READ_COMMUNICATIONS',
	READ_MEDIA_LIBRARY = 'READ_MEDIA_LIBRARY',
	READ_DASHBOARD = 'READ_DASHBOARD',
	READ_CONTENTS = 'READ_CONTENTS',
	READ_CONTESTS = 'READ_CONTESTS',
	READ_INTEGRATIONS = 'READ_INTEGRATIONS',
	READ_DATALEAN_USERS = 'READ_DATALEAN_USERS',
	READ_IDM = 'READ_IDM',
	READ_APPLICATION_USERS = 'READ_APPLICATION_USERS',
	READ_APPLICATION_GROUPS = 'READ_APPLICATION_GROUPS',
	READ_SURVEY = 'READ_SURVEY',
	READ_STRUCTURE = 'READ_STRUCTURE',
	READ_STRUCTURE_VARIANTS = 'READ_STRUCTURE_VARIANTS',
	READ_FEATURES = 'READ_FEATURES',
	READ_FEATURES_VARIANTS = 'READ_FEATURES_VARIANTS',
	READ_ROLES = 'READ_ROLES',
	READ_SETTINGS = 'READ_SETTINGS',
	READ_PICTOGRAMS = 'READ_PICTOGRAMS',
	READ_WORKFLOW = 'READ_WORKFLOW',
	READ_INFORMED_CONSENS = 'READ_INFORMED_CONSENS',
	/*-----------------------*/
	/* COMMUNITIES */
	VIEW_COMMUNITIES = 'VIEW_COMMUNITIES',
	VIEW_ALL_COMMUNITIES = 'VIEW_ALL_COMMUNITIES',
	CREATE_COMMUNITIES = 'CREATE_COMMUNITIES',
	EDIT_COMMUNITIES = 'EDIT_COMMUNITIES',
	CLONE_INTO_COMMUNITY = 'CLONE_INTO_COMMUNITY',
	ASSIGN_COMMUNITY_ADMIN = 'ASSIGN_COMMUNITY_ADMIN',
	ASSOCIATE_TO_COMMUNITY = 'ASSOCIATE_TO_COMMUNITY',
	ADMINISTER_COMMUNITY = 'ADMINISTER_COMMUNITY',
	/*-----------------------*/
	/* CONFIGURATIONS */
	EDIT_CONFIGURATION_TAB = 'EDIT_CONFIGURATION_TAB',
	CREATE_CONFIGURATION = 'CREATE_CONFIGURATION',
	DELETE_CONFIGURATION = 'DELETE_CONFIGURATION',

	/* OTHERS */
	ANY = '*',
	GENERATE_QR_CODE_COLLECTION = 'GENERATE_QR_CODE_COLLECTION',
	/*-----------------------*/
	/* MEDIA_LIBRARY */
	MANAGE_PUBLIC_ASSETS = 'MANAGE_PUBLIC_ASSETS',
	MANAGE_PERMISSION = 'MANAGE_PERMISSION',
	MANAGE_OPERATION_PERMISSION = 'MANAGE_OPERATION_PERMISSION',
	/*-----------------------*/
	/* FEATURES */
	CREATE_FEATURES = 'CREATE_FEATURES',
	EDIT_FEATURES = 'EDIT_FEATURES',
	EDIT_STRUCTURES = 'EDIT_STRUCTURES',
	EDIT_ADMIN_FEATURES = 'EDIT_ADMIN_FEATURES',
	CLONE_FEATURES = 'CLONE_FEATURES',
	CREATE_FEATURE_VALUES = 'CREATE_FEATURE_VALUES',
	ADD_ICON_FEATURES = 'ADD_ICON_FEATURES',

	/* USERS */
	ASSIGN_ADMIN_ROLE = 'ASSIGN_ADMIN_ROLE',
	/*-----------------------*/
	/* STRUCTURES */
	CAN_DEFINE_STRUCTURE_FIELD_PERMISSION = 'CAN_DEFINE_STRUCTURE_FIELD_PERMISSION',
	/*-----------------------*/
	/* APP-USERS */
	IMPORT_APPLICATION_USERS = 'IMPORT_APPLICATION_USERS',
	EXPORT_APPLICATION_USERS = 'EXPORT_APPLICATION_USERS',
	ADD_APPLICATION_USERS = 'ADD_APPLICATION_USERS',
	EDIT_APPLICATION_USERS = 'EDIT_APPLICATION_USERS',
	EDIT_APPLICATION_USER_RESPONSIBLE = 'EDIT_APPLICATION_USER_RESPONSIBLE',
	/*-----------------------*/
	/* PRODUCTS */
	IMPORT_PRODUCTS = 'IMPORT_PRODUCTS',
	EXPORT_PRODUCTS = 'EXPORT_PRODUCTS',
	READ_WISHLISTS = 'READ_WISHLISTS',
	/*-----------------------*/
	/* COMMUNICATIONS */
	IMPORT_COMMUNICATIONS = 'IMPORT_COMMUNICATIONS',
	EXPORT_COMMUNICATIONS = 'EXPORT_COMMUNICATIONS',
	READ_COCKPIT_COMMUNICATIONS = 'READ_COCKPIT_COMMUNICATIONS',
	/*-----------------------*/
	/* GROUPS */
	IMPORT_APPGROUP = 'IMPORT_APPGROUP',
	CAN_VIEW_ADMIN_SMART_GROUP = 'CAN_VIEW_ADMIN_SMART_GROUP',
	/*-----------------------*/
	/* SURVEYS */
	EXPORT_SURVEY = 'EXPORT_SURVEY',
	/*-----------------------*/
	/* INTEGRATIONS */
	EDIT_INTEGRATIONS = 'EDIT_INTEGRATIONS',

	/* LMS */
	READ_LEARNING_MANAGEMENT_SYSTEM = 'READ_LEARNING_MANAGEMENT_SYSTEM',
	/*-----------------------*/

	/* RESERVATIONS */
	READ_RESERVATIONS = 'READ_RESERVATIONS',
	/*-----------------------*/

	/* MERLO EXPORT DATASHEET */
	EXPORT_DATASHEET = 'EXPORT_DATASHEET',
	/*-----------------------*/

	/* ON-BOARDING */
	ON_BOARDING = 'ON_BOARDING',
	/*-----------------------*/
}
