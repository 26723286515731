import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { DataleanPagedResult, FeatureValue, PaginationInfo, Parts, SimpleObject, SortInfo, Structure, StructureType } from 'addiction-components';
import { Observable, forkJoin } from 'rxjs';
import { tap } from 'rxjs/operators';
import { CommunicationActions, UsefulLinksActions } from 'src/app/core/state/app.actions';
import { Communication } from 'src/app/shared/models';
import { BaseListService } from 'src/app/shared/services/base-list.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class CommunicationService extends BaseListService {
	constructor() {
		super();
		this.endpoint = environment.communicationUrl;
		this.structureType = StructureType.COMMUNICATION;
	}

	override dispatchFeatures(features: FeatureValue[]): void {
		this.store.dispatch(CommunicationActions.setFeatures({ features }));
	}

	override dispatchStructures(structures: Structure[]): void {
		this.store.dispatch(CommunicationActions.setStructures({ structures }));
	}

	getCommunications(options: {
		parts?: Parts[];
		locale?: string;
		pagination?: PaginationInfo;
		sort?: SortInfo;
		filters?: {
			uuids?: string[];
			structureUUID?: string;
			query?: string;
			'structure.type'?: StructureType;
			communityUUID?: string;
			surveyUUID?: string;
		};
	}) {
		const { filters, parts, locale, pagination, sort } = options;

		const params: SimpleObject = {
			locale: locale ?? this.headerSrv.getActiveLocale(),
			active: true,
		};

		// TODO: generalizzare questa logica in una funzione reutilizzabile
		if (filters?.structureUUID) params['structureUUID'] = filters.structureUUID;
		if (filters?.['structure.type']) params['structure.type'] = filters['structure.type'];
		if (filters?.query) {
			params['q'] = filters.query;
			params['searchFields'] = ['name']; // parametrizzare i campi di ricerca??
		}
		if (filters?.communityUUID) params['communityUUID'] = filters?.communityUUID;
		if (filters?.uuids) params['uuid'] = filters.uuids.join(',');

		if (filters?.surveyUUID) params['surveyUUID'] = filters.surveyUUID;

		return this.baseApi.getManyPaged<Communication>(this.endpoint, parts ?? [Parts.ALL], {
			pagination,
			sort,
			additionalParams: params,
		});
	}

	countCommunications(filters: { structureUUID?: string; surveyUUID?: string }) {
		return this.baseApi.getOne<{ count: number }>(this.endpoint, 'count', [], {
			organizationUUID: environment.organizationUUID,
			...filters,
			active: true,
		});
	}

	deleteCommunication(uuid: string, structureType: StructureType) {
		return this.baseApi.deleteEntity(this.endpoint, { uuid }).pipe(
			tap(() => {
				if (structureType === StructureType.COMMUNICATION) this.store.dispatch(CommunicationActions.setForceRefreshCommunications());
				if (structureType === StructureType.COCKPIT) this.store.dispatch(UsefulLinksActions.setForceRefreshUsefulLinks());
			})
		);
	}

	fetchCommunications(
		pages: number[],
		sort?: Sort,
		locale?: string,
		gridSearch?: string | null,
		filters?: { featureValue?: string[]; structureUUID?: string; 'structure.type'?: StructureType; communityUUID?: string }
	): Observable<DataleanPagedResult<Communication>[]> {
		const obs: Observable<DataleanPagedResult<Communication>>[] = [];

		const params: {
			sortBy?: string;
			q?: string;
			searchFields?: string;
			locale?: string;
			active: boolean;
			structureUUID?: string;
			featureValueList?: string;
			'structure.type'?: StructureType;
			communityUUID?: string;
		} = {
			locale: locale ?? this.headerSrv.getActiveLocale(),
			active: true,
			sortBy: 'updateDate#desc',
		};

		// TODO: generalizzare questa logica in una funzione reutilizzabile
		if (filters?.structureUUID) params['structureUUID'] = filters.structureUUID;

		if (filters?.featureValue?.length) params['featureValueList'] = filters.featureValue.join(',') + '#and';

		if (filters?.['structure.type']) params['structure.type'] = filters['structure.type'];
		if (filters?.['communityUUID']) params['communityUUID'] = filters.communityUUID;
		//SORT
		if (sort) params.sortBy = `${sort.active}#${sort.direction}`;
		if (gridSearch) {
			params.q = gridSearch;
			params.searchFields = 'name,description';
		}

		for (const page of pages) {
			const pagination: PaginationInfo = new PaginationInfo(environment.pageSize, page);

			obs.push(
				this.baseApi.getManyPaged<Communication>(
					this.endpoint,
					[Parts.SUBJECT_USERS_COUNT, Parts.OPEN_COUNT, Parts.CONFIRM_COUNT, Parts.UPDATE_DATE],
					{
						pagination,
						additionalParams: params,
					}
				)
			);
		}
		return forkJoin(obs);
	}
}
