<label *ngIf="label">{{ label }} <span class="required" *ngIf="requiredIcon">*</span><span *ngIf="hint" class="tooltip" [matTooltip]="hint" [matTooltipPosition]="'right'"></span></label>
<div class="chips-container" [ngClass]="{ readonly: readonly, disabled: disabled }">
	<ng-container *ngIf="!skeletonLoaderChipsCounter else loader">
		<div class="chips-list">
      <div class="chip" *ngFor="let item of items">
        <addiction-chip [label]="item.name" (remove)="removeItem(item)" [removable]="!disabled && !readonly"></addiction-chip>
      </div>
    </div>
		<button class="add" type="button" (click)="add()" *ngIf="!disabled && !readonly"></button>
	</ng-container>

	<ng-template #loader>
    <div class="chips-list">
      <div class="chip skeleton" *dataleanRepeat="this.skeletonLoaderChipsCounter">
        <addiction-chip [removable]="false"></addiction-chip>
      </div>
    </div>
		<button class="add" type="button" [disabled]="true"></button>
	</ng-template>
</div>
