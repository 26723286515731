import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { LetDirective } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { ListComponent, PaginationInfo, SearchComponent, TableRow } from 'addiction-components';
import { LottieModule } from 'ngx-lottie';
import { environment } from 'src/environments/environment';
import { AdditionalTableField, EntityType } from '../../models';
import { EntitySelectorDialogInfinityComponent } from '../entity-selector-dialog-infinity/entity-selector-dialog-infinity.component';

@Component({
	selector: 'datalean-generic-selector-dialog',
	templateUrl: './generic-selector-dialog.component.html',
	styleUrls: ['./generic-selector-dialog.component.scss'],
	standalone: true,
	imports: [CommonModule, ListComponent, TranslateModule, SearchComponent, MatTabsModule, LetDirective, MatCheckboxModule, LottieModule],
})
export class GenericSelectorDialogComponent<T extends Record<string, unknown>> extends EntitySelectorDialogInfinityComponent<T> {
	readonly env = environment;

	@Input() override searchFields: string = 'name';
	@Input() override searchParamName: string = 'q';
	@Input() override fieldsToShow: string[] = ['name'];
	@Input() override additionalFields?: AdditionalTableField<T>[] = [];

	@Input() entityUUID: string = '';
	@Input() entityType: EntityType = EntityType.FEATURE_VALUE;
	//settare a true se serve che oltre a application group e smart group sempre presenti
	//vengano visualizzati anche gli utenti applicativi
	@Input() showUserApplication?: boolean = false;

	constructor() {
		super();
	}

	override mapData(data: T[]): TableRow[] {
		const result: TableRow[] = [];

		for (const item of data) {
			result.push({
				uuid: item['uuid'],
				name: `${item['name']}`,
				...(this.additionalFields?.length ? this.additionalFields.reduce((acc, tableField) => ({...acc, [tableField.fieldName]: item[tableField.fieldName]}), {}) : {})
			});
		}

		// console.log('result', result);

		return result;
	}

	onPageChanged(pages: number[]) {
		this.currentPage$.next(pages.map((p) => new PaginationInfo(this.pageSize, p)));
	}

	override close() {
		this.closeDialog({
			selected: Object.entries(this.gcs.checkboxMap).reduce((acc, [key, value]) => {
				if (value) {
					acc.push(key);
				}
				return acc;
			}, new Array<string>()),
		});
	}

	onSelectionChange(entityUUID?: string, checked?: boolean): void {
		if (entityUUID) {
			if (entityUUID === 'all') {
				for (const uuid of this._currentPageUUIDs) {
					this.gcs.checkboxMap[uuid] = !!checked;
				}
			} else {
				// this.gcs.reset();
				this.gcs.checkboxMap[entityUUID] = !!checked;
				if (!checked) {
					if (this.gcs.selectAllChecked) {
						for (const uuid of this._currentPageUUIDs.filter((uuid) => uuid !== entityUUID)) {
							this.gcs.checkboxMap[uuid] = true;
						}
					}
					this.gcs.selectAllChecked = false;
				}
				this.gcs.updateSelectAllStatus();
			}
		}
		// console.log('this.gcs.checkboxMap', this.gcs.checkboxMap);
		// console.log('this.selectedUUIDs', this.selectedUUIDs);
		// console.log('this.unselectedUUIDs', this.unselectedUUIDs);
		// console.log('this.gcs.selectAllChecked', this.gcs.selectAllChecked);
		this.selectionChanged(
			Object.entries(this.gcs.checkboxMap).reduce((acc, [uuid, value]) => {
				if (value) {
					acc.push({ uuid });
				}
				return acc;
			}, new Array<TableRow>())
		);
	}
}
