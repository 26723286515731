import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { EnvironmentConfiguration, Parts, StructureType, UrlBuilder, UserStructure } from '../models';
import { DataleanBaseApiService } from './datalean-base-api.service';
@Injectable()
export class RegistrationService {
	constructor(@Inject('env') private environmentSettings: EnvironmentConfiguration, private dataleanService: DataleanBaseApiService) {}

	register(userData: unknown) {
		const requestUrl = new UrlBuilder(this.environmentSettings.registrationUrl).build();
		return this.dataleanService.createEntity(requestUrl, userData, []);
	}

	getUserStructure(): Observable<UserStructure | undefined> {
		if (this.environmentSettings?.userStructureUrl)
			return this.dataleanService.getEntities<UserStructure>(
				this.environmentSettings.userStructureUrl + this.environmentSettings.userStructureUUID ?? '',
				{type: StructureType.USER},
				[Parts.STRUCTURE_FIELDS]
			);
		return of(undefined);
	}
}
