import { Injectable, OnDestroy } from '@angular/core';
import { Configuration, ConfigurationType, DataleanBaseApiService, Parts } from 'addiction-components';
import { BehaviorSubject, Observable, catchError, map, of, shareReplay } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ConfigurationService implements OnDestroy {
	private _isReady = new BehaviorSubject(false);
	configurations?: Configuration[];
	isReady = this._isReady.asObservable().pipe(shareReplay({ bufferSize: 1, refCount: true }));

	constructor(private api: DataleanBaseApiService) {}

	init() {
		this.getConfigurations();
	}

	ngOnDestroy() {
		this._isReady.complete();
	}

	fetchConfig(): Observable<Configuration[]> {
		return this.api.getManyPaged<Configuration>(environment.configurationUrl, [Parts.EMPTY]).pipe(map((apiResponse) => apiResponse.result));
	}

	getConfig(key: string): Configuration | undefined {
		if (this.configurations) {
			const config = this.configurations?.find((config) => config.key === key);
			return config;
		}
		return undefined;
	}

	getConfigValue(key: string) {
		const config = this.getConfig(key);
		if (config) {
			try {
				let returnValue = config.value;
				if (config.type === ConfigurationType.JSON && typeof returnValue === 'string') {
					returnValue = JSON.parse(returnValue);
				}
				return returnValue;
			} catch (error) {
				console.error(`Could not parse config.value for key "${key}":`, error);
			}
		}
		return null;
	}

	getConfigurations() {
		this._isReady.next(false);
		this.fetchConfig()
			.pipe(
				catchError(() => {
					this._isReady.next(false);
					return of(null);
				})
			)
			.subscribe((configurations) => {
				if (configurations) {
					this.configurations = configurations;
					this._isReady.next(true); // Notifica che il caricamento è completato
				}
			});
	}

	updateConfig(configuration: Configuration): Observable<Configuration> {
		const { uuid } = configuration;
		return this.api.updateOne<Configuration>(environment.configurationUrl, uuid, configuration, [Parts.EMPTY]);
	}

	createConfig(configuration: Configuration): Observable<Configuration> {
		return this.api.createOne<Configuration>(environment.configurationUrl, configuration, [Parts.EMPTY]);
	}

	deleteConfig(uuid: string): Observable<true> {
		return this.api.deleteOne(environment.configurationUrl, uuid).pipe(map(() => true));
	}
}
