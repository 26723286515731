import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { PaginationInfo, Parts } from 'addiction-components';
import { Observable, forkJoin } from 'rxjs';
import { EntityType } from 'src/app/shared/models';
import { PRACTICE_SECTION_STATUS, PracticeSection } from 'src/app/shared/models/informed-consent/practice-section';
import { PracticeSectionContainer } from 'src/app/shared/models/informed-consent/practice-section-container';
import { BaseListService } from 'src/app/shared/services/base-list.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class InformedConsentService extends BaseListService {
	readonly CONTAINER_ENTITY_TYPE = 'ConsensoInformatoPracticeSectionContainer' as EntityType;

	readonly containerURL = `${environment.informedConsentUrl}practice-section-container/`;

	constructor() {
		super();
	}

	fetchSections(
		pages: number[],
		sort?: Sort,
		gridSearch?: string | null,
		communityUUID?: string
	): Observable<{ result: PracticeSection[] | null; paginationInfo?: PaginationInfo }[]> {
		const obs: Observable<{ result: PracticeSection[] | null; paginationInfo?: PaginationInfo }>[] = [];

		const params: { sortBy?: string; q?: string; searchFields?: string; communityUUIDList?: string[]; latest?: boolean } = {
			latest: true, //prendo solo l'ultima versione di ogni sezione
		};

		//SORT
		if (sort) params.sortBy = `${sort.active}#${sort.direction}`;
		if (gridSearch) {
			params.q = gridSearch;
			params.searchFields = 'name';
		}
		if (communityUUID) params.communityUUIDList = [communityUUID];

		for (const page of pages) {
			const pagination = new PaginationInfo(environment.pageSize, page);

			obs.push(
				this.baseApi.getEntitiesWithPaginationData<PracticeSection>(
					`${environment.informedConsentUrl}practice-section/`,
					params,
					[],
					undefined,
					pagination,
					undefined
				)
			);
		}
		return forkJoin(obs);
	}

	fetchSectionsByUUIDs(uuids: string[]) {
		return this.baseApi.getEntities<PracticeSection[]>(
			`${environment.informedConsentUrl}practice-section/`,
			{
				latest: true,
				uuid: uuids.join(','),
			},
			[]
		);
	}

	getAllSections() {
		return this.baseApi.getEntities<PracticeSection[]>(
			`${environment.informedConsentUrl}practice-section/`,
			{
				latest: true,
				sectionStatus: PRACTICE_SECTION_STATUS.PUBLISHED,
			},
			[]
		);
	}

	fetchSection(uuid: string, version?: number) {
		const params: { version?: number; latest?: boolean } = {};
		//se passi una versione si recupera quella altrimenti si prende l'ultima disponibile
		if (version) params.version = version;
		else params.latest = true;
		return this.baseApi.getEntity<PracticeSection>(`${environment.informedConsentUrl}practice-section/`, uuid, [], params);
	}

	archiveSection(section: PracticeSection) {
		const sectionArchived = structuredClone(section);
		sectionArchived.sectionStatus = PRACTICE_SECTION_STATUS.ARCHIVED;
		return this.baseApi.updateOne(`${environment.informedConsentUrl}practice-section/`, section.uuid, sectionArchived, []);
	}

	createSection(section: PracticeSection) {
		//non creo una sezione come BOZZA
		const sectionToCreate = structuredClone(section);
		if (sectionToCreate.sectionStatus === PRACTICE_SECTION_STATUS.DRAFT) sectionToCreate.sectionStatus = PRACTICE_SECTION_STATUS.PUBLISHED;

		return this.baseApi.createEntity(`${environment.informedConsentUrl}practice-section/`, sectionToCreate, []);
	}

	updateSection(sectionUpdated: PracticeSection) {
		return this.baseApi.updateOne(`${environment.informedConsentUrl}practice-section/`, sectionUpdated.uuid, sectionUpdated, []);
	}

	fetchSectionsContainer(
		pages: number[],
		sort?: Sort,
		gridSearch?: string | null,
		communityUUID?: string
	): Observable<{ result: PracticeSectionContainer[] | null; paginationInfo?: PaginationInfo }[]> {
		const obs: Observable<{ result: PracticeSectionContainer[] | null; paginationInfo?: PaginationInfo }>[] = [];

		const params: { sortBy?: string; q?: string; searchFields?: string; communityUUIDList?: string[] } = {};

		//SORT
		if (sort) params.sortBy = `${sort.active}#${sort.direction}`;
		if (gridSearch) {
			params.q = gridSearch;
			params.searchFields = 'name';
		}
		if (communityUUID) params.communityUUIDList = [communityUUID];

		for (const page of pages) {
			const pagination = new PaginationInfo(environment.pageSize, page);

			obs.push(this.baseApi.getEntitiesWithPaginationData<PracticeSectionContainer>(this.containerURL, params, [], undefined, pagination, undefined));
		}
		return forkJoin(obs);
	}

	fetchSectionContainer(uuid: string, resolveSection: boolean = false) {
		const parts: Parts[] = [];
		if (resolveSection) parts.push('practiceSections' as Parts);
		return this.baseApi.getEntity<PracticeSectionContainer>(this.containerURL, uuid, parts, {});
	}

	createSectionContainer(container: PracticeSectionContainer) {
		return this.baseApi.createEntity(this.containerURL, container, []);
	}

	deletePracticeSectionContainer(uuid: string) {
		return this.baseApi.deleteOne(this.containerURL, uuid);
	}

	updateSectionContainer(sectionUpdated: PracticeSectionContainer) {
		return this.baseApi.updateOne(this.containerURL, sectionUpdated.uuid, sectionUpdated, []);
	}

	//COMMENTATO PER UN FUTURO IN CUI SI AGGIUSTERà LOGICA O COMPONENTI PER VIEW PERMISSION
	/**
	 * passo i permessi vecchi e quelli nuovi, in base alle modifiche faccio le create/delete dei permessi
	 * @param previousViewPermissions vecchi entityPermission
	 * @param viewPermissions nuovi entityPermission
	 * @param entityUUID entità per la quale occorre modificare i permessi
	 * @returns
	 */
	// assignPermissionViews(previousViewPermissions: EntityViewPermission[], viewPermissions: EntityViewPermission[], entityUUID: string) {
	//   console.log("previousViewPermissions", previousViewPermissions)
	//   console.log("viewPermissions", viewPermissions)
	//   console.log("entityUUID", entityUUID)

	// 	const calls = [];
	//   const selected = viewPermissions.filter((item) => item.canView);
	// 	if (previousViewPermissions.length) {
	//     const toDelete = viewPermissions.filter((item) => {
	//       if(!item.canView){
	//         //se priva era fleggato a true e ora no allora lo devo eliminare
	//         return previousViewPermissions.find(previous => previous.subjectUUID === item.subjectUUID && previous.entityUUID === entityUUID && previous.canView)
	//       }
	//       return false
	//     })
	//     if(toDelete.length)
	//       calls.push(this.baseApi.deleteMany(`${this.containerURL}${entityUUID}/permission`, {subjectUUIDList: toDelete.map((vp) => vp['subjectUUID']).join(','),}));
	//     console.log("toDelete", toDelete)
	//   }
	// 	if (selected.length){
	//     const toCreate = selected.filter(item => {
	//       const previous = previousViewPermissions.find(previous => previous.subjectUUID === item.subjectUUID && previous.entityUUID === entityUUID)
	//       //è da creare se non avevo un previous o se il previous esiste e non era visibile
	//       return !previous || previous.canView === false
	//     })
	//     //ATTENZIONE, la create non è normale, vuole tutti quelli già selezionati, infatti l'endpoint
	//     //onfronta i vecchi viewPermission con i nuovi e cancella quelli diversi (NON PUOI PASSARE SOLO QUELLI DA CREARE altrimenti
	//     //ti elimina quelli già esistenti)
	//     if (toCreate.length) calls.push(this.baseApi.createOne(`${this.containerURL}${entityUUID}/permission`, toCreate));
	//     console.log("toCreate", toCreate)
	//   }

	// 	return zip(calls)
	// }
}
