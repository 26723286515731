import { Action, createReducer, on } from '@ngrx/store';
import { RolesActions } from 'src/app/core/state/app.actions';
import { STATE_STATUS } from 'src/app/shared/models';
import { RolesState } from './roles.state';

export const TAG = 'roles';

export interface FiltersType {
	structureUUID?: string;
	featureValue?: string[];
	communityUUID?: string;
	active?: boolean;
	updateDate?: string;
	communityEntityUUID?: string;
	[key: string]: unknown;
}

const initialState: RolesState = {
	sort: undefined,
	pages: [0],
	data: { totalRolesCount: 0, roles: [] },
	features: [],
	structures: [],
	status: STATE_STATUS.UNKNOWN,
	gridSearch: null,
	deleteStatus: 'unknown',
	cachedPages: [],
	structureUUID: '',
};

export const reducers = createReducer(
	initialState,
	on(RolesActions.setRolesSuccess, (state: RolesState, { data }) => {
		return {
			...state,
			status: STATE_STATUS.READY,
			data: {
				roles: data.roles,
				totalRolesCount: data.totalRolesCount,
			},
			pages: data.pages,
		};
	}),
	on(RolesActions.setRolesError, (state: RolesState) => ({
		...state,
		status: STATE_STATUS.ERROR,
	})),
	on(RolesActions.setStatus, (state: RolesState, { status }) => {
		return {
			...state,
			status,
		};
	}),
	on(RolesActions.setGridSearch, (state: RolesState, { search }) => {
		return {
			...state,
			gridSearch: search,
		};
	}),
	on(RolesActions.setSearchInfo, (state: RolesState, { searchInfo }) => ({
		...state,
		searchInfo,
	})),
	on(RolesActions.setSort, (state: RolesState, { sort }) => ({
		...state,
		sort,
	})),
	on(RolesActions.setPages, (state: RolesState, { pages }) => ({
		...state,
		pages,
	})),
	on(RolesActions.setDeleteRoles, (state: RolesState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.LOADING,
		};
	}),
	on(RolesActions.setDeleteRolesSuccess, (state: RolesState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.READY,
		};
	}),
	on(RolesActions.setDeleteRolesError, (state: RolesState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.ERROR,
		};
	})
);

export const RolesReducer = (state: RolesState | undefined, action: Action) => {
	return reducers(state, action);
};
