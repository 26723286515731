import { CommonModule } from '@angular/common';
import { Component, Input, forwardRef, inject } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ChipComponent, FeatureValue, FeatureValueType, MessageHandler, Tag, transformTypeClassIntoInterface } from 'addiction-components';
import { Observable, Subject, filter, map, of, switchMap, take, finalize } from 'rxjs';
import { HeaderLocaleService } from 'src/app/components';
import { TagsService } from 'src/app/pages/tags/services/tags.service';
import { AssetDetail } from '../../models';
import { FeaturesService } from '../../services/features.service';
import { FeatureSelectorDialogComponent } from '../feature-selector-dialog/feature-selector-dialog.component';
import { BaseChipsSelectorComponent, ChipValue } from './base-chips-selector.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DataleanDirectiveModule } from '../../modules/directive.module';

// ℹ️ Segue la documentazione: https://addiction.atlassian.net/wiki/spaces/DOCTEC/pages/2269315073/ChipsSelector

@Component({
	selector: 'datalean-feature-chips-selector',
	templateUrl: './chips-selector.component.html',
	styleUrls: ['./chips-selector.component.scss'],
	standalone: true,
	imports: [CommonModule, ChipComponent, ReactiveFormsModule, MatTooltipModule, DataleanDirectiveModule],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => FeaturesChipsSelectorComponent) },
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: forwardRef(() => FeaturesChipsSelectorComponent),
		},
	],
})
export class FeaturesChipsSelectorComponent extends BaseChipsSelectorComponent {
	@Input() detailType?: AssetDetail;
	//valore fisso di asset
	@Input() featureValueType = FeatureValueType.ASSET;

	private messageHandler = inject(MessageHandler);
	private featureSrv = inject(FeaturesService);
	private tagService = inject(TagsService);
	private headerSrv = inject(HeaderLocaleService);
	private translateService = inject(TranslateService);

	dialogOpened$ = new Subject<void>();

	constructor() {
		super();
	}

	override writeValue(values: (ChipValue | string)[] | null): void {
		of(values)
			.pipe(
				switchMap((v) => {
					// console.log('v', v);
					if (!v || !Array.isArray(v) || !v.length) return of([]);
					if (v?.find((v) => typeof v === 'string')) {
						const uuids = v.map((item) => (typeof item === 'string' ? item : item.uuid));
						this.skeletonLoaderChipsCounter = uuids.length;

						return this.featureSrv
							.getFeaturesByUUID(uuids, this.translateService.currentLang)
							.pipe(map((features) => features.map((feature) => this.mapToChipValue(feature))));
					}

					return of(v);
				}),
				take(1),
				finalize(() => this.skeletonLoaderChipsCounter = 0)
			)
			.subscribe({
				next: (result) => (this.items = result as ChipValue[]),
			});
	}

	openDialog(currentValues: ChipValue[]): Observable<ChipValue[] | undefined> {
		let obs$: Observable<Array<FeatureValue> | Array<Tag>>;

		if (this.detailType) {
			let title = 'PRODUCTS.FEATURE_VALUES';
			if (this.detailType === AssetDetail.CATEGORY) {
				obs$ = this.featureSrv.getFeatures(
					[this.featureValueType],
					this.headerSrv.getActiveLocale() || (this.translateService.currentLang ?? this.translateService.defaultLang)
				);
			} else {
				obs$ = this.tagService.getTags();
				title = 'PRODUCTS.TAGS';
			}
			return obs$.pipe(
				switchMap((result) => {
					return this.messageHandler
						.openDialog(
							FeatureSelectorDialogComponent<transformTypeClassIntoInterface<FeatureValue>>,
							{ title },
							{
								detailType: this.detailType,
								selectedUUIDs: currentValues?.map(({ uuid }) => uuid),
								list: result as transformTypeClassIntoInterface<FeatureValue>[],
							}
						)
						.result$.pipe(
							filter((result) => result?.reason === 'COMPLETE'),
							map((result) => result.data),
							filter((data): data is { selected: Array<transformTypeClassIntoInterface<FeatureValue>> } => !!data),
							map((data) =>
								data.selected.map((fv) => ({
									uuid: fv.uuid,
									name: this.detailType === AssetDetail.CATEGORY ? fv.label ?? fv.name : fv.name,
								}))
							)
						);
				})
			);
		}
		return of(undefined);
	}

	private mapToChipValue(entity: FeatureValue): ChipValue {
		return {
			uuid: `${entity['uuid']}`,
			name: `${entity['label']}`,
		};
	}
}
