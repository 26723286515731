import { createFeatureSelector, createSelector } from '@ngrx/store';
import { WorkFlowReducers } from '../../../core/state/app.reducers';

const selectEWorkFlowState = createFeatureSelector<WorkFlowReducers.WorkFlowState>(WorkFlowReducers.TAG);

export const selectWorkFlowConfigurations = createSelector(selectEWorkFlowState, (state) => state?.workFlowConfig);
export const selectWorkFlowInstance = createSelector(selectEWorkFlowState, (state) => state?.workFlowInstance);
export const selectWorkFlowsFilters = createSelector(selectEWorkFlowState, (state) => state?.filters);
export const selectWorkFlowsStructures = createSelector(selectEWorkFlowState, (state) => state?.structures);

export const selectData = createSelector(selectEWorkFlowState, (state) => state.data);
export const selectPages = createSelector(selectEWorkFlowState, (state) => state.pages);
export const selectPagedData = createSelector(selectData, selectPages, (data, pages) => ({ ...data, pages }));
export const selectStatus = createSelector(selectEWorkFlowState, (state) => state.status);
export const selectSort = createSelector(selectEWorkFlowState, (state) => state.sort);
export const selectGridSearch = createSelector(selectEWorkFlowState, (state) => state.gridSearch);
export const selectCachedPages = createSelector(selectEWorkFlowState, (state) => state.cachedPages);
export const selectWorkFlowType = createSelector(selectEWorkFlowState, (state) => state.workFlowType);
