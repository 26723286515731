import { Action, createReducer, on } from '@ngrx/store';
import * as SectionActions from './practice-section.actions';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';
import { Sort } from '@angular/material/sort';
import { PracticeSection } from 'src/app/shared/models/informed-consent/practice-section';

export const SECTIONS_TAG = 'practiceSections';

export interface PracticeSectionState {
	gridSearch: string | null;
	sort?: Sort;
	data: { totalSectionsCount: number; sections: PracticeSection[][] };
	pages: number[];
	status: STATE_STATUS;
	deleteStatus: STATE_STATUS;
	cachedPages: number[];
}

const initialState: PracticeSectionState = {
	status: STATE_STATUS.UNKNOWN,
	deleteStatus: STATE_STATUS.UNKNOWN,
	gridSearch: null,
	pages: [],
	data: { totalSectionsCount: 0, sections: [] },
	cachedPages: [],
};

export const reducers = createReducer(
	initialState,
	on(SectionActions.setSort, (state: PracticeSectionState, { sort }) => ({
		...state,
		sort,
	})),
	on(SectionActions.setPages, (state: PracticeSectionState, { pages }) => ({
		...state,
		pages,
	})),
	on(SectionActions.setSectionsSuccess, (state: PracticeSectionState, { data }) => {
		return {
			...state,
			status: STATE_STATUS.READY,
			data: {
				sections: data.sections,
				totalSectionsCount: data.totalSectionsCount,
			},
			pages: [...state.cachedPages, ...data.pages],
		};
	}),
	on(SectionActions.setSectionsError, (state: PracticeSectionState) => ({
		...state,
		status: STATE_STATUS.ERROR,
	})),
	on(SectionActions.setStatus, (state: PracticeSectionState, { status }) => {
		return {
			...state,
			status,
		};
	}),
	on(SectionActions.setGridSearch, (state: PracticeSectionState, { search }) => {
		return {
			...state,
			gridSearch: search,
      cachedPages: [],
      data: { totalSectionsCount: 0, sections: [] },
		};
	}),
	on(SectionActions.setDeletePracticeSection, (state: PracticeSectionState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.LOADING,
		};
	}),
	on(SectionActions.setDeletePracticeSectionSuccess, (state: PracticeSectionState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.READY,
		};
	}),
	on(SectionActions.setDeletePracticeSectionError, (state: PracticeSectionState) => {
		return {
			...state,
			deleteStatus: STATE_STATUS.ERROR,
		};
	}),
  on(SectionActions.setCachedPages, (state: PracticeSectionState, { pages }) => ({
		...state,
		cachedPages: [...new Set([...state.cachedPages, ...pages])],
	})),
	on(SectionActions.resetCache, (state: PracticeSectionState) => ({
		...state,
		cachedPages: [],
    status: STATE_STATUS.READY,
		pages: [0],
		data: { totalSectionsCount: 0, sections: [] },
	}))
);

export const PracticeSectionsReducer = (state: PracticeSectionState | undefined = initialState, action: Action) => {
	return reducers(state, action);
};
