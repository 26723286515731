import { Component, Input, inject } from '@angular/core';
import {
	BaseModalContent,
	CheckboxMultipleService,
	DataleanBaseApiService,
	Page,
	PaginationInfo,
	Parts,
	SearchInfo,
	SimpleObject,
	SortInfo,
	SortObject,
	TableConfiguration,
	TableRow,
} from 'addiction-components';
import { BehaviorSubject, combineLatest, distinctUntilChanged, forkJoin, map, shareReplay, switchMap, tap } from 'rxjs';
import { isPage } from '../../helpers';
import { AdditionalField } from '../../models';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'datalean-entity-selector-dialog-infinity',
	templateUrl: './entity-selector-dialog-infinity.component.html',
	styleUrls: ['./entity-selector-dialog-infinity.component.scss'],
})
export class EntitySelectorDialogInfinityComponent<
	T = Record<string, unknown>,
	K extends Record<string, unknown> = { selected: Array<string> }
> extends BaseModalContent<K> {
	@Input() selectedUUIDs: string[] | undefined = undefined;
	@Input() indeterminateUUIDs: string[] | undefined = undefined;
	@Input() unselectedUUIDs: string[] | undefined = undefined;
	/**
	 * Attributo usato per usare la modale in logica contraria all'ordinario, per cui, se non ci sono valori preselezionati negativeReflection è true
	 * sono tutti selezionati.
	 */
	@Input() negativeReflection: boolean = false;
	@Input() endpoint: string = '';
	@Input() additionalParams: SimpleObject = {};
	@Input() searchFields: string = '';
	@Input() searchParamName: string = '';
	@Input() fieldsToShow: string[] = [];
	@Input() filters: SimpleObject = {};
	@Input() additionalFields?: AdditionalField<T>[] = [];

	dataleanService = inject(DataleanBaseApiService);
	gcs = inject(CheckboxMultipleService);

	pageSize: number = environment.pageSize;
	loading: boolean = false;
	_currentPageUUIDs: string[] = [];
	_currentData: Array<TableRow>[] = [];
	// FILTERS
	currentPage$ = new BehaviorSubject<PaginationInfo[]>([new PaginationInfo(this.pageSize, 0)]);
	search$ = new BehaviorSubject<string | undefined>(undefined);
	sort$ = new BehaviorSubject<SortInfo | undefined>(undefined);

	fetchParams$ = combineLatest([this.currentPage$, this.search$, this.sort$]).pipe(
		distinctUntilChanged((a, b) => {
			const isPageSame =
				a[0].length === b[0].length &&
				a[0].every((p, i) => p.numberOfPage === b[0][i].numberOfPage && p.numberOfElementsOnPage === b[0][i].numberOfElementsOnPage);
			const isSearchSame = a[1] === b[1];
			const isSortSame = a[2]?.sortBy === b[2]?.sortBy || a[2]?.sortDirection === b[2]?.sortDirection;

			// console.log('isPageSame', isPageSame);
			// console.log('isSearchSame', isSearchSame);
			// console.log('isSortSame', isSortSame);
			return isPageSame && isSearchSame && isSortSame;
		})
	);

	pageSettings$ = new BehaviorSubject<Page>(new Page(5, undefined, undefined, 0));
	switchTab$ = new BehaviorSubject(undefined);

	tableRows$ = combineLatest([this.switchTab$, this.fetchParams$]).pipe(
		map(([, fetchParams]) => fetchParams),
		tap(() => (this.loading = true)),
		// recupera i dati dal server
		switchMap(([paging, search, sort]) =>
			forkJoin(
				paging.map((p) =>
					this.dataleanService.getManyPaged<T>(this.endpoint, [Parts.EMPTY], {
						pagination: p,
						sort,
						search: search ? new SearchInfo(this.searchFields, search, undefined, this.searchParamName) : undefined,
						additionalParams: { ...this.additionalParams, ...this.filters },
					})
				)
			)
		),
		// notifica cambiamento pagina alla tabella
		map((data) => {
			// this.changePage(data.paginationInfo);
			const parsedData = data.map((d) => ({ result: this.mapData(d.result), paginationInfo: d.paginationInfo }));
			const startingResult: { pages: number[]; items: { uuid: string; name: string; email?: string }[][]; totalItems: number } = {
				pages: [],
				items: [],
				totalItems: 0,
			};
			const result = [...parsedData].reduce((acc, item) => {
				const paginationInfo = item.paginationInfo
					? new Page(item.paginationInfo.pageSize, item.paginationInfo.totalElements, 0, item.paginationInfo.pageNumber)
					: undefined;
				if (paginationInfo) {
					acc.pages.push(paginationInfo.pageNumber);
					acc.totalItems = paginationInfo.totalElements ?? 0;
					if (item.result.length) {
						acc.items[paginationInfo.pageNumber] = item.result.map((d) => ({
							...d,
							uuid: d['uuid'] as string,
							name: d['name'] as string,
							email: d['email'] as string,
						}));
					}
				}
				return acc;
			}, startingResult);
			this.gcs.totalItems = result.totalItems;
			const flattedItems = structuredClone(result.items).flat();
			// console.log('this.gcs.checkboxMap', this.gcs.checkboxMap);
			// console.log('parsedData', Object.keys(this.gcs.checkboxMap).filter((v) => parsedData.some((p) => p['uuid'] === v)).length);
			if (Object.keys(this.gcs.checkboxMap).filter((v) => flattedItems.some((p) => p['uuid'] === v)).length) {
				this.selectedUUIDs = Object.entries(this.gcs.checkboxMap)
					.filter(([uuid, value]) => flattedItems.some((v) => v['uuid'] === uuid) && value)
					.map(([uuid]) => uuid);
				this.unselectedUUIDs = Object.entries(this.gcs.checkboxMap)
					.filter(([uuid, value]) => flattedItems.some((v) => v['uuid'] === uuid) && !value)
					.map(([uuid]) => uuid);
			}
			// console.log('this.selectedUUIDs', this.selectedUUIDs);
			// console.log('this.unselectedUUIDs', this.unselectedUUIDs);

			if (!this.selectedUUIDs?.length && this.unselectedUUIDs?.length && this.negativeReflection) {
				this.selectedUUIDs = flattedItems
					.filter((data) => !this.unselectedUUIDs?.includes(data['uuid'] as string))
					.map((data) => data['uuid'] as string);
			}

			this.gcs.currentPageItems = [...flattedItems.map((d) => d['uuid'] as string)];
			for (const data of flattedItems as []) {
				this.gcs.checkboxMapWithValue[data['uuid']] = data['name'];
			}

			if (this.gcs.totalItems) {
				this.gcs.selectAllChecked = false;
				this.gcs.updateSelectAllStatus();
			}
			return result;
		}),
		tap(({ items }) => {
			this.loading = false;
			this._currentData = items;
			// console.log('items', this.selectedUUIDs, this.unselectedUUIDs);
			this._currentPageUUIDs = items.flatMap((r) => r.flatMap((row) => row.uuid));
			if (!this.selectedUUIDs?.length && this.negativeReflection) this.selectedUUIDs = this._currentPageUUIDs;
			if (this.unselectedUUIDs?.length && this.selectedUUIDs?.length) {
				this.selectedUUIDs = this.selectedUUIDs.filter((uid) => !this.unselectedUUIDs?.includes(uid));
			}

			if (this.selectedUUIDs) {
				// console.log('this.selectedUUIDs', this.selectedUUIDs);
				for (const uuid of this.selectedUUIDs) {
					this.gcs.checkboxMap[uuid] = true;
				}
			}
		}),
		shareReplay({ refCount: true, bufferSize: 1 })
	);

	selectedRows$ = this.tableRows$.pipe(map(() => this._currentPageUUIDs.filter((row) => this.selectedUUIDs?.includes(row))));

	baseTableConfig: TableConfiguration = {
		columns: [
			{
				name: 'name',
			},
		],
		key: 'uuid',
		filters: true,
		filtersConfig: [],
		displayCheck: () => true,
		selectable: true,
	};

	constructor() {
		super();
		this.gcs.reset();
	}

	changePage(pagInfo: PaginationInfo | Page) {
		// console.log('changePage', pagInfo, isPage(pagInfo));
		if (isPage(pagInfo)) pagInfo = new PaginationInfo(pagInfo.pageSize, pagInfo.pageNumber, pagInfo.totalElements);

		const totalPages =
			pagInfo.totalNumberOfElements && pagInfo.numberOfElementsOnPage ? Math.ceil(pagInfo.totalNumberOfElements / pagInfo.numberOfElementsOnPage) : 0;
		this.pageSettings$.next(new Page(pagInfo.numberOfElementsOnPage, pagInfo.totalNumberOfElements, totalPages, pagInfo.numberOfPage));
	}

	mapData(data: T[]): TableRow[] {
		return data.map((dataObj) => ({
			uuid: dataObj['uuid' as keyof T] as string,
			name: this.fieldsToShow.reduce((prevValue, curValue) => prevValue + dataObj[curValue as keyof T] + ' ', ''),
			...(this.additionalFields?.length ? this.additionalFields.reduce((acc, tableField) => ({...acc, [tableField.fieldName]: dataObj[tableField.fieldName]}), {}) : {})
		}));
	}

	selectionChanged(selectedRows: TableRow[]) {
		const pageSelection = selectedRows.map((row) => row['uuid'] as string);
		// console.log('pageSelection', pageSelection);
		const otherPageSel = this.selectedUUIDs?.filter((uuid) => !this._currentPageUUIDs.includes(uuid));
		this.selectedUUIDs = otherPageSel ? [...otherPageSel, ...pageSelection] : pageSelection;

		const pageUnSelection =
			selectedRows && selectedRows.length
				? this._currentPageUUIDs.filter((uuid) => !selectedRows.map((row) => row['uuid'] as string).includes(uuid))
				: this._currentPageUUIDs;
		this.unselectedUUIDs = (this.unselectedUUIDs ? [...new Set([...this.unselectedUUIDs, ...pageUnSelection])] : pageUnSelection).filter(
			(uuid) => !!uuid && !this.selectedUUIDs?.includes(uuid)
		);
		// console.log('this.selectedUUIDs', this.selectedUUIDs);
		// console.log('this.unselectedUUIDs', this.unselectedUUIDs);
	}

	// pageChanged(pageInfo: PageChangeEvent) {
	// 	if (pageInfo.count != this.pageSettings$.value.pageSize && pageInfo.offset != this.pageSettings$.value.pageNumber) {
	// 		this.currentPage$.next([new PaginationInfo(pageInfo.pageSize, pageInfo.offset, pageInfo.count)]);
	// 	}
	// }

	sort(sortInfo: SortObject) {
		this.sort$.next(new SortInfo(sortInfo.prop, sortInfo.dir));
	}

	close() {
		const selectedItems = this.selectedUUIDs ?? [];
		this.closeDialog({ selected: selectedItems ?? [] } as unknown as K);
	}

	filterBy(name: string | null) {
		this.search$.next(name ?? undefined);
	}
}
