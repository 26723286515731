import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { EMPTY, catchError, combineLatestWith, debounce, distinctUntilChanged, filter, first, map, of, switchMap, tap } from 'rxjs';
import { PracticeSectionsContainerSelector } from 'src/app/core/state/app.selectors';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';
import { InformedConsentService } from '../../informed-consent.service';
import { PracticeSectionsContainerActions } from 'src/app/core/state/app.actions';

@Injectable()
export class PracticeSectionContainerEffects {
	private actions$ = inject(Actions);
	private store = inject(Store);
	private informedConsentService = inject(InformedConsentService);

	constructor() {}

	fetchContainers$ = createEffect(() =>
		this.actions$.pipe(
			//quando selezioni una categoria o fai una ricerca, viene triggherato il DamFilter, quindi ci registriamo ad
			//un suo eventuale successo per richiamare gli assets
			ofType(PracticeSectionsContainerActions.setPages, PracticeSectionsContainerActions.setForceRefreshPracticeSectionsContainer),
			concatLatestFrom(() => [
				this.store.select(PracticeSectionsContainerSelector.selectPages),
				this.store.select(PracticeSectionsContainerSelector.selectSort),
				this.store.select(PracticeSectionsContainerSelector.selectGridSearch),
				this.store.select(PracticeSectionsContainerSelector.selectCachedPages),
			]),
			debounce(() => {
				return this.store.select(PracticeSectionsContainerSelector.selectStatus).pipe(
					filter((stat) => {
						return !stat || stat !== 'loading';
					})
				)
      }),
			//non voglio caricare se non ho le pagine pronte
			filter(([, pages]) => {
        return !!pages?.length
      }),
			tap(() => {
				this.store.dispatch(PracticeSectionsContainerActions.setStatus({ status: STATE_STATUS.LOADING }));
			}),
			switchMap(([, pages, sort, gridSearch, cachedPages]) => {
				const actualPages = pages.filter((p) => !cachedPages.includes(p));
				if (!actualPages.length) return EMPTY;

				return this.informedConsentService.fetchSectionsContainer(actualPages, sort, gridSearch).pipe(
					combineLatestWith(this.store.select(PracticeSectionsContainerSelector.selectPagedData)),
					first(),
					map(([data, startingResult]) => {
						startingResult.pages = cachedPages;
						const result = data.reduce((acc, item) => {
							if (item.paginationInfo) {
								acc.pages.push(item.paginationInfo.numberOfPage);
								acc.totalSectionsCount = item.paginationInfo.totalNumberOfElements;
								if (item.result) {
									acc.sectionsContainer[item.paginationInfo.numberOfPage] = item.result;
								}
							}
							return acc;
						}, structuredClone(startingResult));

						return PracticeSectionsContainerActions.setSectionsContainerSuccess({ data: result });
					}),
					catchError((error: HttpErrorResponse) => {
						return of(PracticeSectionsContainerActions.setSectionsContainerError({ error }));
					})
				);
			})
		)
	);

	resetCache$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PracticeSectionsContainerActions.setGridSearch, PracticeSectionsContainerActions.setSort),
			concatLatestFrom(() => [
				this.store.select(PracticeSectionsContainerSelector.selectPages),
				this.store.select(PracticeSectionsContainerSelector.selectSort),
				this.store.select(PracticeSectionsContainerSelector.selectGridSearch),
				this.store.select(PracticeSectionsContainerSelector.selectCachedPages),
			]),
			//non voglio caricare se i dati delle azioni sono gli stessi
			distinctUntilChanged(
				([, pagesPrev, sortPrev, gridSearchPrev,,], [, pagesCurr, sortCurr, gridSearchCurr, cachedPages,]) => {
					return (
						pagesPrev.length === pagesCurr.length &&
						pagesPrev.every((f) => pagesCurr.includes(f)) &&
						sortCurr?.active === sortPrev?.active &&
						sortCurr?.direction === sortPrev?.direction &&
						gridSearchCurr === gridSearchPrev &&
						pagesPrev.filter((f) => !cachedPages.includes(f)).length === 0
					);
				}
			),
			map(() => PracticeSectionsContainerActions.resetCache())
		)
	);

	deletePracticeSection$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PracticeSectionsContainerActions.setDeletePracticeSectionContainer),
			switchMap(({ sectionContainer }) => {
				return this.informedConsentService.deletePracticeSectionContainer(sectionContainer.uuid).pipe(
					map(() => {
						return PracticeSectionsContainerActions.setDeletePracticeSectionContainerSuccess();
					}),
					catchError((error: HttpErrorResponse) => {
						return of(PracticeSectionsContainerActions.setSectionsContainerError({ error }));
					})
				);
			})
		)
	);

	deletePracticeSections$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PracticeSectionsContainerActions.setDeletePracticeSectionContainerSuccess, PracticeSectionsContainerActions.setDeletePracticeSectionContainerError),
			map(() => PracticeSectionsContainerActions.resetCache())
		)
	);

	practiceSectionSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PracticeSectionsContainerActions.setSectionsContainerSuccess),
			map(({ data: { pages } }) => PracticeSectionsContainerActions.setCachedPages({ pages }))
		)
	);

	forceRefreshTable$ = createEffect(() =>
		this.actions$.pipe(
			ofType(PracticeSectionsContainerActions.resetCache),
			map(() => PracticeSectionsContainerActions.setForceRefreshPracticeSectionsContainer())
		)
	);
}
