import { CommonModule } from '@angular/common';
import { Component, Input, forwardRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { Store } from '@ngrx/store';
import { CheckboxMultipleService, ChipComponent, CloseReason, DataleanBaseApiService, ModalService, Parts } from 'addiction-components';
import { Observable, Subject, filter, finalize, map, of, switchMap, take } from 'rxjs';
import { CommunitySelectSelectors } from 'src/app/core/state/app.selectors';
import { ChipSelectorInput } from '../../models/chip-selector-input.interface';
import { GenericSelectorDialogComponent } from '../generic-selector-dialog/generic-selector-dialog.component';
import { BaseChipsSelectorComponent, ChipValue } from './base-chips-selector.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { DataleanDirectiveModule } from '../../modules/directive.module';

// ℹ️ Segue la documentazione: https://addiction.atlassian.net/wiki/spaces/DOCTEC/pages/2269315073/ChipsSelector

@Component({
	selector: 'datalean-generic-chip-selector',
	templateUrl: './chips-selector.component.html',
	styleUrls: ['./chips-selector.component.scss'],
	standalone: true,
	imports: [CommonModule, ChipComponent, ReactiveFormsModule, MatTooltipModule, DataleanDirectiveModule],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, multi: true, useExisting: forwardRef(() => GenericChipSelectorComponent) },
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: forwardRef(() => GenericChipSelectorComponent),
		},
	],
})
export class GenericChipSelectorComponent extends BaseChipsSelectorComponent {
	dialogOpened$ = new Subject<void>();
	gcs = inject(CheckboxMultipleService);
	lastCommunitySelectedUUID: string | undefined;
	dataleanService = inject(DataleanBaseApiService);
	@Input({ required: true }) inputData!: ChipSelectorInput;

	constructor(private modalSrv: ModalService, private store: Store) {
		super();
		this.store
			.select(CommunitySelectSelectors.selectLastCommunitySelected)
			.pipe(takeUntilDestroyed())
			.subscribe((communitySelected) => (this.lastCommunitySelectedUUID = communitySelected));
	}

	override writeValue(val: (ChipValue | string)[] | null): void {
		of(val)
			.pipe(
				switchMap((v) => {
					// console.log('v', v);
					if (!v || !Array.isArray(v) || !v.length) return of();
					const uuids = v.map((item) => (typeof item === 'string' ? item : item.uuid));
					// console.log('uuids', uuids);
					if (this.inputData?.entityType) {
						const params: { uuid: string[]; communityUUID?: string } = {
							uuid: uuids,
						};
						if (this.lastCommunitySelectedUUID && this.lastCommunitySelectedUUID !== 'all') {
							params['communityUUID'] = this.lastCommunitySelectedUUID;
						}
						this.skeletonLoaderChipsCounter = params.uuid.length;
						return this.dataleanService.getManyPaged<Record<string, unknown>>(this.inputData?.endpoint, [Parts.EMPTY], {
							additionalParams: params,
						});
					}
					return of();
				}),
				map((result) => result.result.map((item) => this.mapToChipValue(item))),
				take(1),
				finalize(() => this.skeletonLoaderChipsCounter = 0)
			)
			.subscribe((result) => {
				this.items = result;
			});
	}

	override openDialog(currentValues: ChipValue[] = []): Observable<{ selected: ChipValue[]; replace?: boolean | undefined }> {
		const selected = (currentValues || []).map(({ uuid }) => uuid);

		return this.modalSrv
			.openDialog(
				GenericSelectorDialogComponent,
				{ title: this.inputData?.modalTitle },
				{
					negativeReflection: false,
					endpoint: this.inputData?.endpoint,
					selectedUUIDs: selected,
					additionalParams: this.inputData?.additionalParams ?? {},
				}
			)
			.result$.pipe(
				filter((result): result is { reason: CloseReason; data: { selected: string[] } } => result?.reason === 'COMPLETE' && !!result.data),
				switchMap(({ data }) => {
					if (!data?.selected?.length) return of();
					// console.log('data', data);
					if (this.inputData?.entityType) {
						const params: { uuid: string[]; communityUUID?: string } = {
							uuid: data?.selected,
						};
						if (this.lastCommunitySelectedUUID && this.lastCommunitySelectedUUID !== 'all') {
							params['communityUUID'] = this.lastCommunitySelectedUUID;
						}
						return this.dataleanService.getManyPaged<Record<string, unknown>>(this.inputData?.endpoint, [Parts.EMPTY], {
							additionalParams: params,
						});
					}
					return of();
				}),
        map((result) => ({ selected: result.result.map((item) => this.mapToChipValue(item)), replace: true }))
			);
	}

	private mapToChipValue(entity: Record<string, unknown>): ChipValue {
		return {
			uuid: `${entity['uuid']}`,
			name: `${entity['name']}`,
		};
	}
}
