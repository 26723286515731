import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, catchError, filter, of, switchMap } from 'rxjs';
import { combineLatestWith, debounce, distinctUntilChanged, first, map, tap } from 'rxjs/operators';
import { CommunitySelectActions, IntegrationActions } from 'src/app/core/state/app.actions';
import { STATE_STATUS } from 'src/app/shared/models';
import { IntegrationService } from '../services/integration.service';
import { setIntegrationsSuccess } from './integration.actions';
import {
	selectCachedPages,
	selectGridSearch,
	selectPagedData,
	selectPages,
	selectSort,
	selectStatus,
} from './integration.selectors';

@Injectable()
export class IntegrationEffect {
	private store = inject(Store);
	private actions$ = inject(Actions);
	private integrationService = inject(IntegrationService);
	private translateService = inject(TranslateService);

	fetchIntegrations$ = createEffect(() =>
		this.actions$.pipe(
			ofType(IntegrationActions.setPages, IntegrationActions.setForceRefreshIntegrations),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectCachedPages),
			]),
			debounce(() => this.store.select(selectStatus).pipe(filter((stat) => !stat || stat !== 'loading'))),
			//non voglio caricare se non ho le pagine pronte
			filter(([, pages]) => !!pages?.length),
			tap(() => {
				this.store.dispatch(IntegrationActions.setStatus({ status: STATE_STATUS.LOADING }));
			}),
			switchMap(([, pages, sort, gridSearch, cachedPages]) => {
				const actualPages = pages.filter((p) => !cachedPages.includes(p));
				if (!actualPages.length) return EMPTY;
				return this.integrationService
					.fetchIntegrations(
						actualPages,
						sort,
						this.translateService.currentLang,
						gridSearch,
					)
					.pipe(
						combineLatestWith(this.store.select(selectPagedData)),
						first(),
						map(([data, startingResult]) => {
							// console.log(startingResult);
							startingResult.pages = cachedPages;
							const result = data.reduce((acc, item) => {
								if (item.paginationInfo) {
									acc.pages.push(item.paginationInfo.pageNumber);
									acc.totalIntegrationsCount = item.paginationInfo.totalElements ?? 0;
									if (item.result) {
										acc.integrations[item.paginationInfo.pageNumber] = item.result;
									}
								}
								return acc;
							}, structuredClone(startingResult));

							// console.log(result);

							return IntegrationActions.setIntegrationsSuccess({ data: result });
						}),
						catchError((error: HttpErrorResponse) => {
							console.error(error);
							return of(IntegrationActions.setIntegrationsError({ error }));
						})
					);
			})
		)
	);

	resetCache$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				IntegrationActions.setGridSearch,
				IntegrationActions.setSort,
				CommunitySelectActions.setLastCommunitySelected
			),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(selectCachedPages),
			]),
			// //non voglio caricare se i dati delle azioni sono gli stessi
			distinctUntilChanged(
				(
					[, pagesPrev, sortPrev, gridSearchPrev],
					[, pagesCurr, sortCurr, gridSearchCurr, cachedPages]
				) => {
					// console.log(action);
					return (
						pagesPrev.length === pagesCurr.length &&
						pagesPrev.every((f) => pagesCurr.includes(f)) &&
						sortCurr?.active === sortPrev?.active &&
						sortCurr?.direction === sortPrev?.direction &&
						gridSearchCurr === gridSearchPrev &&
						pagesPrev.filter((f) => !cachedPages.includes(f)).length === 0
					);
				}
			),
			map(() => IntegrationActions.resetCache())
		)
	);

	deleteIntegration$ = createEffect(() =>
		this.actions$.pipe(
			ofType(IntegrationActions.deleteIntegration),
			// invoke API
			switchMap(({ uuid }) => {
				return this.integrationService
					.deleteIntegration(uuid)
					.pipe(
						map(() => {
							return IntegrationActions.setDeleteIntegrationsSuccess();
						}),
						catchError((error: HttpErrorResponse) => of(IntegrationActions.error({ error: { errorType: 'generic_http', data: error } })))
					);
			})
		)
	);


	integrationSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setIntegrationsSuccess),
			map(({ data: { pages } }) => IntegrationActions.setCachedPages({ pages }))
		)
	);

	deleteAndConsolidateIntegration$ = createEffect(() =>
		this.actions$.pipe(
			ofType(
				IntegrationActions.setDeleteIntegrationsSuccess,
				IntegrationActions.setDeleteIntegrationsError,
				IntegrationActions.setConsolidationSuccess
			),
			map(() => IntegrationActions.resetCache())
		)
	);

	forceRefreshTable$ = createEffect(() =>
		this.actions$.pipe(
			ofType(IntegrationActions.resetCache),
			map(() => IntegrationActions.setForceRefreshIntegrations())
		)
	);

	consolidateIntegration$ = createEffect(() =>
		this.actions$.pipe(
			ofType(IntegrationActions.setConsolidationDate),
			// invoke API
			switchMap(({ uuid }) => {
				return this.integrationService
					.consolidateCatalean(uuid)
					.pipe(
						map(() => {
							return IntegrationActions.setConsolidationSuccess();
						}),
						catchError((error: HttpErrorResponse) => of(IntegrationActions.error({ error: { errorType: 'generic_http', data: error } })))
					);
			})
		)
	);

	constructor() {}
}
