import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { DataleanPagedResult, FeatureValue, PaginationInfo, Parts, SimpleObject, SortInfo, Structure, StructureType } from 'addiction-components';
import { Observable, forkJoin } from 'rxjs';
import { ContentsActions } from 'src/app/core/state/app.actions';
import { Content } from 'src/app/shared/models';
import { BaseListService } from 'src/app/shared/services/base-list.service';
import { environment } from 'src/environments/environment';

@Injectable({ providedIn: 'root' })
export class ContentService extends BaseListService {
	constructor() {
		super();
		this.endpoint = environment.contentUrl;
		this.structureType = StructureType.CONTENT;
	}

	override dispatchFeatures(features: FeatureValue[]): void {
		this.store.dispatch(ContentsActions.setFeatures({ features }));
	}

	override dispatchStructures(structures: Structure[]): void {
		this.store.dispatch(ContentsActions.setStructures({ structures }));
	}

	getContents(options: {
		parts?: Parts[];
		locale?: string;
		pagination?: PaginationInfo;
		sort?: SortInfo;
		filters?: { uuids?: string[]; structureUUID?: string; query?: string; communityUUID?: string };
	}) {
		const { filters, parts, locale, pagination, sort } = options;

		const params: SimpleObject = {
			locale: locale ?? this.headerSrv.getActiveLocale(),
			active: true,
		};

		// TODO: generalizzare questa logica in una funzione reutilizzabile
		if (filters?.structureUUID) params['structureUUID'] = filters.structureUUID;
		if (filters?.query) {
			params['q'] = filters.query;
			params['searchFields'] = ['name']; // parametrizzare i campi di ricerca??
		}
		if (filters?.communityUUID) params['communityUUID'] = filters.communityUUID;

		if (filters?.uuids) params['uuid'] = filters.uuids.join(',');

		return this.baseApi.getManyPaged<Content>(environment.contentUrl, parts ?? [Parts.ALL], {
			pagination,
			sort,
			additionalParams: params,
		});
	}

	countContents(filters: { structureUUID?: string }) {
		return this.baseApi.getOne<{ count: number }>(environment.contentUrl, 'count', [], {
			organizationUUID: environment.organizationUUID,
			...filters,
			active: true,
		});
	}

	fetchContents(
		pages: number[],
		sort?: Sort,
		locale?: string,
		gridSearch?: string | null,
		filters?: { featureValue?: string[]; structureUUID?: string; communityUUID?: string }
	): Observable<DataleanPagedResult<Content>[]> {
		const obs: Observable<DataleanPagedResult<Content>>[] = [];

		const params: {
			sortBy?: string;
			q?: string;
			searchFields?: string;
			locale?: string;
			active: boolean;
			structureUUID?: string;
			featureValueList?: string;
			communityUUID?: string;
		} = {
			locale: locale ?? this.headerSrv.getActiveLocale(),
			active: true,
		};

		// TODO: generalizzare questa logica in una funzione reutilizzabile
		if (filters?.structureUUID) params['structureUUID'] = filters.structureUUID;
		if (filters?.communityUUID) params.communityUUID = filters.communityUUID;
		if (filters?.featureValue?.length) params['featureValueList'] = filters.featureValue.join(',') + '#and';

		//SORT
		if (sort) params.sortBy = `${sort.active}#${sort.direction}`;
		if (gridSearch) {
			params.q = gridSearch;
			params.searchFields = 'name,description';
		}

		for (const page of pages) {
			const pagination: PaginationInfo = new PaginationInfo(environment.pageSize, page);

			obs.push(
				this.baseApi.getManyPaged<Content>(environment.contentUrl, [Parts.FEATURE_VALUE_LIST, Parts.UPDATE_DATE], {
					pagination,
					additionalParams: params,
				})
			);
		}
		return forkJoin(obs);
	}
}
