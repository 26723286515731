<div class="container" *ngIf="formGroup">
	<form class="form-group-container" [formGroup]="formGroup">
		<ng-container *ngFor="let field of structureFields">
			<ng-container *ngIf="getFormControl(field)">
				<!-- non-repeatable-container -->
				<ng-container *ngIf="!field.repeatable">
					<ng-container [ngSwitch]="field.type">
						<div class="field-container" *ngSwitchCase="types.TEXT" [ngClass]="getClass(field.name)">
							<addiction-input
								[readonly]="readonly"
								[label]="field.label | toLocalizedValuePipe"
								[formControl]="getFormControl(field)"></addiction-input>
						</div>

						<div class="field-container" *ngSwitchCase="types.URLTITLE" [ngClass]="getClass(field.name)">
							<addiction-input
								[readonly]="readonly"
								[label]="field.label | toLocalizedValuePipe"
								type="urlTitle"
								[formControl]="getFormControl(field)"></addiction-input>
						</div>

						<div class="field-container" *ngSwitchCase="types.NUMBER" [ngClass]="getClass(field.name)">
							<addiction-input
								[readonly]="readonly"
								type="number"
								[label]="field.label | toLocalizedValuePipe"
								[formControlName]="field.name"></addiction-input>
						</div>

						<div class="field-container" *ngSwitchCase="types.TEXTAREA" [ngClass]="getClass(field.name)">
							<addiction-textarea
								[readonly]="readonly"
								placeholder=""
								[label]="field.label | toLocalizedValuePipe"
								[formControl]="getFormControl(field)"></addiction-textarea>
						</div>

						<!-- <div class="field-container" *ngSwitchCase="types.RELATED_ENTITIES" [ngClass]="getClass(field.name)">
              <datalean-products-chips-selector [formControl]="getFormControl(field)" [label]="field.label | toLocalizedValuePipe">
              </datalean-products-chips-selector>
            </div> -->

						<!-- <div class="field-container" *ngSwitchCase="types.FEATURE_VALUES_CONTAINER" [ngClass]="getClass(field.name)">
							<datalean-feature-chips-selector
								[formControl]="getFormControl(field)"
								[label]="field.label | toLocalizedValuePipe"
								[detailType]="getExtra(field, 'detailType')"
								[featureValueType]="getExtra(field, 'featureValueType') ?? FeatureValueType.ASSET"
								[emit]="getExtra(field, 'emit') ?? 'uuid'">
							</datalean-feature-chips-selector>
						</div> -->

						<div class="field-container" *ngSwitchCase="types.HTML" [ngClass]="getClass(field.name)">
							<label>
								{{ field.label | toLocalizedValuePipe }}
								<span *ngIf="field.isRequired">*</span>
							</label>
              <!-- TODO METTI A POSTO COLLEGANDO IL DATO GIUSTO -->
							<addiction-html-editor [readonly]="readonly" [formControl]="getFormControl(field)"></addiction-html-editor>
						</div>

						<div class="field-container" *ngSwitchCase="types.BOOLEAN" [ngClass]="getClass(field.name)">
							<addiction-checkbox
								class="column"
								*ngSwitchCase="types.BOOLEAN"
								[label]="field.label | toLocalizedValuePipe"
								[formControl]="getFormControl(field)"></addiction-checkbox>
						</div>

						<div class="field-container" *ngSwitchCase="types.PRIVACY" [ngClass]="getClass(field.name)">
							<addiction-checkbox-privacy
								class="column"
								[privacyConfig]="field.parsedExtra | as"
								[formControl]="getFormControl(field)"></addiction-checkbox-privacy>
						</div>

						<div class="field-container" *ngSwitchCase="types.TOGGLE" [ngClass]="getClass(field.name)">
							<mat-slide-toggle [formControl]="getFormControl(field)">
								{{ field.label | toLocalizedValuePipe }}
							</mat-slide-toggle>
						</div>

						<div class="field-container" *ngSwitchCase="types.PHONE_3" [ngClass]="getClass(field.name)">
							<addiction-phone
								[readonly]="readonly"
								[label]="field.label | toLocalizedValuePipe"
								[formControl]="getFormControl(field)"></addiction-phone>
						</div>

						<div class="field-container" *ngSwitchCase="types.SELECT" [ngClass]="getClass(field.name)">
							<label>
								{{ field.label | toLocalizedValuePipe }}
								<span *ngIf="field.isRequired">*</span>
							</label>
							<addiction-select
								[readonly]="readonly"
								class="column"
								*ngSwitchCase="types.SELECT"
								[options]="field.parsedExtra | as"
								[formControl]="getFormControl(field)"></addiction-select>
						</div>

						<div class="field-container" *ngSwitchCase="types.RADIO" [ngClass]="getClass(field.name)">
							<addiction-radio
								[readonly]="readonly"
								class="column"
								*ngSwitchCase="types.RADIO"
								[options]="field.parsedExtra | as"
								[label]="field.label | toLocalizedValuePipe"
								[formControl]="getFormControl(field)"></addiction-radio>
						</div>

						<!-- <div class="field-container" *ngSwitchCase="types.FILE_3" [ngClass]="getClass(field.name)">
							<datalean-structure-file-selector
								mode="complete"
								[readonly]="readonly"
								[label]="field.label | toLocalizedValuePipe"
								[inputTypeRequired]="field.inputTypeRequired"
								[formatRequired]="field.formatRequired"
								[formControl]="getFormControl(field)"></datalean-structure-file-selector>
						</div> -->

						<!-- <div class="field-container" *ngSwitchCase="types.LESSON_ATTACHMENT" [ngClass]="getClass(field.name)">
							<ng-container *ngIf="field.parentValue !== 'quiz'">
								<datalean-structure-file-selector
									mode="complete"
									[readonly]="readonly"
									[inputTypeRequired]="field.inputTypeRequired"
									[formatRequired]="field.formatRequired"
									[formControl]="getFormControl(field)"></datalean-structure-file-selector>
							</ng-container>
							<ng-container *ngIf="field.parentValue === 'quiz'">
								<label>Quiz</label>
								<datalean-quiz-chips-selector
									[formControl]="getFormControl(field)"
									[label]="field.label | toLocalizedValuePipe"></datalean-quiz-chips-selector>
							</ng-container>
						</div> -->

						<!-- <div class="field-container" *ngSwitchCase="types.ATTACHMENT_3" [ngClass]="getClass(field.name)">
							<datalean-structure-file-selector
								mode="compact"
								[readonly]="readonly"
								[label]="field.label | toLocalizedValuePipe"
								[inputTypeRequired]="field.inputTypeRequired"
								[formatRequired]="field.formatRequired"
								[formControl]="getFormControl(field)"></datalean-structure-file-selector>
						</div> -->

						<div class="field-container" *ngSwitchCase="types.EMAIL_3" [ngClass]="getClass(field.name)">
							<addiction-email
								[readonly]="readonly"
								[label]="field.label | toLocalizedValuePipe"
								[formControl]="getFormControl(field)"></addiction-email>
						</div>

						<!-- <ng-container *ngSwitchCase="types.SINGLE_RELATED_ENTITY">
							<div class="field-container ratio-100" [ngClass]="getClass(field.name)">
								<addiction-input [readonly]="readonly" [label]="'name' | toLocalizedValuePipe" type="text" formControlName="name"></addiction-input>
							</div>
							<div class="field-container ratio-100" [ngClass]="getClass(field.name)">
								<mat-slide-toggle [ariaReadOnly]="readonly" formControlName="active">
									{{ 'ACTIVE' | translate }}
								</mat-slide-toggle>
							</div>
							<div class="field-container ratio-100" [ngClass]="getClass(field.name)">
								<datalean-lesson-chips-selector
									formControlName="linkedLesson"
									[readonly]="readonly"
									[limit]="1"
									[label]="field.label | toLocalizedValuePipe"></datalean-lesson-chips-selector>
							</div>

							<div class="field-container ratio-100" [ngClass]="getClass(field.name)">
								<datalean-lesson-chips-selector
									formControlName="blockedBy"
									[readonly]="readonly"
									[label]="'blocked' | toLocalizedValuePipe"></datalean-lesson-chips-selector>
							</div>
						</ng-container> -->

						<div class="field-container" *ngSwitchCase="types.DATE" [ngClass]="getClass(field.name)">
							<label>
								{{ field.label | toLocalizedValuePipe }}
								<span *ngIf="field.isRequired">*</span>
							</label>
							<addiction-date-picker [readonly]="readonly" [formControl]="getFormControl(field)" [showTime]="false"></addiction-date-picker>
						</div>

						<div class="field-container" *ngSwitchCase="types.CONTAINER" [ngClass]="getClass(field.name)">
							<div class="simple-container-container" *ngIf="field.fields && field.fields.length">
								<div class="container-title">
									{{ field.label | toLocalizedValuePipe }}
									<span *ngIf="field.isRequired">*</span>
								</div>

								<ng-container [formGroup]="getFormGroup(field)">
									<addiction-container
										[readonly]="readonly"
										[structureFields]="field.fields"
										[depth]="depth + 1"
										[dataset]="getSubDataset(field.name)">
									</addiction-container>
								</ng-container>
							</div>
						</div>
					</ng-container>
				</ng-container>

				<div
					class="repeatable-container"
					*ngIf="field.repeatable"
					[formArrayName]="field.name"
					cdkDropList
					[cdkDropListData]="getFormArray(field)"
					(cdkDropListDropped)="drop($event)">
					<label>
						{{ field.label | toLocalizedValuePipe }}
						<span *ngIf="field.isRequired">*</span>
					</label>

					<!-- TODO - Mettere classe ".closed" su .single-element quando clicco su drag-container -->
					<div
						class="single-element"
						*ngFor="let control of getFormArray(field)?.controls; let i = index"
						cdkDrag
						cdkDragPreviewContainer="parent"
						cdkDragBoundary=".repeatable-container"
						[cdkDragData]="control">
						<div class="drag-placeholder" *cdkDragPlaceholder></div>

						<div class="drag-container" cdkDragHandle>
							<span class="number">{{ i + 1 }}</span>
						</div>

						<ng-container [ngSwitch]="field.type">
							<!-- <div class="repeatable-fields-container" *ngSwitchCase="types.FILE_3" [ngClass]="getClass(field.name)">
								<datalean-structure-file-selector
									mode="complete"
									[readonly]="readonly"
									[inputTypeRequired]="field.inputTypeRequired"
									[formatRequired]="field.formatRequired"
									[formControlName]="i"
									[label]="field.label | toLocalizedValuePipe"></datalean-structure-file-selector>
							</div> -->

							<!-- <div class="repeatable-fields-container" *ngSwitchCase="types.ATTACHMENT_3" [ngClass]="getClass(field.name)">
								<datalean-structure-file-selector
									mode="compact"
									[readonly]="readonly"
									[inputTypeRequired]="field.inputTypeRequired"
									[formatRequired]="field.formatRequired"
									[formControlName]="i"
									[label]="field.label | toLocalizedValuePipe"></datalean-structure-file-selector>
							</div> -->

							<div class="repeatable-fields-container" *ngSwitchCase="types.CONTAINER" [ngClass]="getClass(field.name)">
								<addiction-container
									[formControlName]="i"
									[readonly]="readonly"
									[structureFields]="field.fields ?? []"
									[depth]="depth + 1"
									[dataset]="getSubDataset(field.name, i)">
								</addiction-container>
							</div>
						</ng-container>
						<div class="buttons-container">
							<!-- <button class="clone"></button>-->
							<button class="delete" type="button" *ngIf="field.repeatable && !readonly" (click)="removeChild(field, i)"></button>
						</div>
					</div>

					<button class="add-element" type="button" *ngIf="field.repeatable && !readonly" (click)="addChild(field)">
						<span>{{ 'ADD_ELEMENT' | translate }}</span>
					</button>
				</div>
			</ng-container>
		</ng-container>
	</form>
</div>
