import { CdkVirtualScrollViewport, ScrollingModule } from '@angular/cdk/scrolling';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';

@Component({
	selector: 'addiction-list',
	standalone: true,
	imports: [CommonModule, ScrollingModule, CdkTableModule],
	templateUrl: './list.component.html',
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ListComponent<T> implements OnChanges {
	_itemsRows: T[] = [];
	isItemsRowsEmpty: boolean = false;

	@Input() itemSize: number = 50;
	@Input() headerTemplate?: TemplateRef<unknown>;
	@Input() tableHeaderTemplate?: TemplateRef<unknown>;
	@Input() rowTemplate?: TemplateRef<unknown>;
	@Input() noItemsTemplate?: TemplateRef<unknown>;
	@Input() loadingTemplate?: TemplateRef<unknown>;
	@Input() isLoading?: boolean;
	@Input({ required: true }) pageSize: number = 20;
	@Input({ required: true }) data?: { items: T[][]; pages?: number[]; totalItems: number };
	@Input() draggable = false;
	@Output() rowClick = new EventEmitter<T>();
	@Output() pageChanges = new EventEmitter<number[]>();
	@Output() move = new EventEmitter<{ prevPosition: number; currentPosition: number }>();

	_availablePages: number[] = [];
	@ViewChild(CdkVirtualScrollViewport) scrollViewPort?: CdkVirtualScrollViewport;

	constructor() {}
	ngOnChanges(changes: SimpleChanges): void {
		if (changes['data'] && changes['data'].currentValue) {
			this.data = changes['data'].currentValue;
			if (!this.data) return;
			this._availablePages = this.data.pages
				? [...new Set(this._availablePages.concat(this.data.pages))]
				: [...(this.data.items?.length ? this.data.items.map((_, index) => index) : [])];
			let arrayLength = this.data.totalItems;
			if (arrayLength === 0) {
				this.isItemsRowsEmpty = true;
				this._itemsRows = [];
			} else if (arrayLength === undefined && this.data.items.length) {
				arrayLength = this.data.items.reduce((acc, item) => {
					acc += item.length;
					return acc;
				}, 0);
			} else {
				this.isItemsRowsEmpty = false;
			}

			const tmpArray = Array.from<T>({ length: arrayLength });
			if (tmpArray.length > 0) {
				for (const page of this._availablePages) {
					const dataPage = this.data.items[page];
					if (dataPage) {
						tmpArray.splice(page * this.pageSize, this.data.items[page].length, ...this.data.items[page]);
					}
				}
			}
			this._itemsRows = tmpArray.length > 0 ? [...tmpArray] : [];
		}
	}

	loadPaginatedData(pages: number[]) {
		this.pageChanges.emit([...new Set(this._availablePages.concat(pages))]);
	}

	onScroll() {
		let end = this.scrollViewPort?.getRenderedRange().end;
		if (end) {
			end++;
		}
		const scrollRange = this.scrollViewPort?.getRenderedRange();
		if (scrollRange) {
			let pagesToLoad = this._range(this._getPageForIndex(scrollRange?.start), this._getPageForIndex(scrollRange?.end));
			if (pagesToLoad.length === 0) {
				pagesToLoad = [0];
			}
			if (pagesToLoad.every((val) => this._availablePages.includes(val))) {
				this._availablePages = [...pagesToLoad];
			}
			if (!this._arraysEqual(pagesToLoad, this._availablePages) || this._availablePages.length === 0) this.loadPaginatedData(pagesToLoad);
		}
	}

	private _getPageForIndex = (index: number) => Math.floor(index / this.pageSize);
	private _range = (start: number, stop: number) => Array.from({ length: stop - start + 1 }, (_, i) => start + i);
	private _arraysEqual(a: unknown[], b: unknown[]) {
		if (a === b) return true;
		if (a == null || b == null) return false;
		if (a.length !== b.length) return false;

		for (let i = 0; i < a.length; ++i) {
			if (a[i] !== b[i]) return false;
		}
		return true;
	}
}
