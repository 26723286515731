import { DataleanEntity, ObjectValuesType } from 'addiction-components';

export const PRACTICE_SECTION_STATUS = {
	PUBLISHED: 'PUBLISHED',
	DRAFT: 'DRAFT',
	ARCHIVED: 'ARCHIVED',
} as const;

export type PracticeSectionStatus = ObjectValuesType<typeof PRACTICE_SECTION_STATUS>;

export interface PracticeSection extends DataleanEntity {
	name: string;
	value: string;
	version: number;
	sectionStatus: PracticeSectionStatus;
}
