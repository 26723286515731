<ng-container *ngIf="headerTemplate" [ngTemplateOutlet]="headerTemplate"></ng-container>
<div class="grid-viewport" (scroll)="onGridScroll($event)" #gridViewport>
  <div class="grid-row" *ngFor="let group of _itemsRows; let i = index; trackBy: trackBy">
    <div *ngFor="let item of group; let j = index" class="grid-card" (click)="cardClick.emit(item)" [ngStyle]="{ maxWidth: 100 / _columns + '%' }">
      <ng-container *ngIf="cardTemplate" [ngTemplateOutlet]="cardTemplate" [ngTemplateOutletContext]="{item, index: (i)*_columns+j+1}">
      </ng-container>
    </div>
  </div>
  <div class="buttons-container">
    <button class="more" type="button" *ngIf="!allLoaded" (click)="loadNextPage()">
      <span>{{ 'LOAD_MORE' | translate }}</span>
    </button>
  </div>
</div>
<ng-container *ngIf="noItemsTemplate && !_itemsRows.length" [ngTemplateOutlet]="noItemsTemplate"></ng-container>
