import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { EMPTY, catchError, combineLatestWith, debounce, distinctUntilChanged, filter, first, map, of, switchMap, tap } from 'rxjs';
import { STATE_STATUS } from 'src/app/shared/models';
import { CommunitySelectActions, QuizActions } from '../../../../core/state/app.actions';
import { CommunitySelectSelectors } from '../../../../core/state/app.selectors';
import { QuizService } from '../services/quiz.service';
import { setDeleteQuiz, setDeleteQuizSuccess, setForceRefreshQuiz, setPages, setQuizError, setQuizSuccess, setStatus } from './quiz.actions';
import { selectCachedPages, selectGridSearch, selectPagedData, selectPages, selectSort, selectStatus } from './quiz.selectors';

@Injectable()
export class QuizEffects {
	private actions$ = inject(Actions);
	private store = inject(Store);
	private quizService = inject(QuizService);
	private translateService = inject(TranslateService);

	constructor() {}

	fetchQuiz$ = createEffect(() =>
		this.actions$.pipe(
			//quando selezioni una categoria o fai una ricerca, viene triggherato il DamFilter, quindi ci registriamo ad
			//un suo eventuale successo per richiamare gli assets
			ofType(setPages, setForceRefreshQuiz),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(CommunitySelectSelectors.selectLastCommunitySelectedForApiRest),
				this.store.select(selectCachedPages),
			]),
			debounce(() => this.store.select(selectStatus).pipe(filter((stat) => !stat || stat !== 'loading'))),
			//non voglio caricare se non ho le pagine pronte
			filter(([, pages]) => !!pages?.length),
			tap(() => this.store.dispatch(setStatus({ status: STATE_STATUS.LOADING }))),
			switchMap(([, pages, sort, gridSearch, communityUUID, cachedPages]) => {
				const actualPages = pages.filter((p) => !cachedPages.includes(p));
				if (!actualPages.length) return EMPTY;
				return this.quizService
					.fetchQuiz(actualPages, sort, this.translateService.currentLang ?? this.translateService.defaultLang, gridSearch, communityUUID)
					.pipe(
						combineLatestWith(this.store.select(selectPagedData)),
						first(),
						map(([data, startingResult]) => {
							startingResult.pages = cachedPages;
							const result = data.reduce((acc, item) => {
								if (item.paginationInfo) {
									acc.pages.push(item.paginationInfo.pageNumber);
									acc.totalQuizCount = item.paginationInfo.totalElements ?? 0;
									if (item.result) {
										acc.quiz[item.paginationInfo.pageNumber] = item.result.map((quiz) => ({
											...quiz,
										}));
									}
								}
								return acc;
							}, structuredClone(startingResult));

							return setQuizSuccess({ data: result });
						}),
						catchError((error: HttpErrorResponse) => {
							console.error(error);
							return of(setQuizError({ error }));
						})
					);
			})
		)
	);

	resetCache$ = createEffect(() =>
		this.actions$.pipe(
			ofType(QuizActions.setGridSearch, QuizActions.setSort, CommunitySelectActions.setLastCommunitySelected),
			concatLatestFrom(() => [
				this.store.select(selectPages),
				this.store.select(selectSort),
				this.store.select(selectGridSearch),
				this.store.select(CommunitySelectSelectors.selectLastCommunitySelectedForApiRest),
				this.store.select(selectCachedPages),
			]),
			//non voglio caricare se i dati delle azioni sono gli stessi
			distinctUntilChanged(
				([, pagesPrev, sortPrev, gridSearchPrev, communityUUIDPrev], [, pagesCurr, sortCurr, gridSearchCurr, communityUUIDCurr, cachedPages]) => {
					return (
						pagesPrev.length === pagesCurr.length &&
						pagesPrev.every((f) => pagesCurr.includes(f)) &&
						sortCurr?.active === sortPrev?.active &&
						sortCurr?.direction === sortPrev?.direction &&
						communityUUIDPrev === communityUUIDCurr &&
						gridSearchCurr === gridSearchPrev &&
						pagesPrev.filter((f) => !cachedPages.includes(f)).length === 0
					);
				}
			),
			map(() => QuizActions.resetCache())
		)
	);

	deleteQuiz$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setDeleteQuiz),
			switchMap(({ quiz }) => {
				return this.quizService.deleteQuiz(quiz).pipe(
					map(() => {
						return setDeleteQuizSuccess();
					}),
					catchError((error: HttpErrorResponse) => {
						return of(setQuizError({ error }));
					})
				);
			})
		)
	);

	quizSuccess$ = createEffect(() =>
		this.actions$.pipe(
			ofType(setQuizSuccess),
			map(({ data: { pages } }) => QuizActions.setCachedPages({ pages }))
		)
	);

	afterDeleteQuiz$ = createEffect(() =>
		this.actions$.pipe(
			ofType(QuizActions.setDeleteQuizError, QuizActions.setDeleteQuizSuccess),
			map(() => QuizActions.resetCache())
		)
	);

	forceRefreshTable$ = createEffect(() =>
		this.actions$.pipe(
			ofType(QuizActions.resetCache),
			map(() => QuizActions.setForceRefreshQuiz())
		)
	);
}
