<ng-container>
	<ng-container *ngIf="headerTemplate" [ngTemplateOutlet]="headerTemplate"></ng-container>
	<ng-container
		*ngIf="tableHeaderTemplate && !isItemsRowsEmpty"
		[ngTemplateOutlet]="tableHeaderTemplate"
		[ngTemplateOutletContext]="{ data: _itemsRows }"></ng-container>
	<cdk-virtual-scroll-viewport
		class="list-viewport"
		[appendOnly]="false"
		[itemSize]="itemSize"
		[ngClass]="{
			'with-header': headerTemplate,
			'with-header-template': tableHeaderTemplate && !isItemsRowsEmpty
		}"
		(scrolledIndexChange)="onScroll()">
		<div class="list-row" *cdkVirtualFor="let item of _itemsRows" (click)="rowClick.emit(item)">
			<ng-container *ngIf="rowTemplate" [ngTemplateOutlet]="rowTemplate" [ngTemplateOutletContext]="{item}"></ng-container>
		</div>
	</cdk-virtual-scroll-viewport>
	<ng-container *ngIf="noItemsTemplate && isItemsRowsEmpty" [ngTemplateOutlet]="noItemsTemplate"> </ng-container>
</ng-container>
