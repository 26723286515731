import { SafeHtml } from "@angular/platform-browser";


// Interfaccia utilizzata all'interno della classe EntitySelectorDialogInfinityComponent
// Serve per specificare i campi aggiuntivi da aggiungere ad ogni elemento della lista di item
export interface AdditionalField<T> {
	fieldName: keyof T;
}


// Interfaccia utilizzata all'interno della classe GenericSelectorDialogComponent
// Serve per specificare i campi aggiuntivi da aggiungere ad ogni elemento della lista di item e come renderizzarli nella lista
export interface AdditionalTableField<T> extends AdditionalField<T> {
	columnHeader: string;
	cellTemplate?: (item: T) => SafeHtml;
	cellClasses?: string[];
	tableHeaderClasses?: string[];
}