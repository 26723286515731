<addiction-search (search)="filterBy($event)"></addiction-search>

<addiction-list
	(pageChanges)="onPageChanged($event)"
	[data]="(tableRows$ | async) ?? { pages: [], items: [], totalItems: loading ? env.pageSize : 0 }"
	[pageSize]="pageSize"
	[itemSize]="50"
	[tableHeaderTemplate]="tableHeaderTemplate"
	[rowTemplate]="rowTemplate"
	[noItemsTemplate]="noItemsTemplate"></addiction-list>

<ng-template #tableHeaderTemplate>
	<div class="single-row-header" matSort>
		<div class="single-column checkbox">
			<span class="checkbox"></span>
			<mat-checkbox [checked]="gcs.selectAllChecked" (change)="onSelectionChange($event, 'all')" [indeterminate]="gcs.selectAllIndeterminate">
			</mat-checkbox>
		</div>
		<div class="single-column double first-name" mat-sort-header="name">
			<span>{{ 'LIST_HEADER.NAME' | translate }}</span>
		</div>

		<ng-container *ngIf="additionalFields?.length">
			<ng-container *ngFor="let additionalTableField of additionalFields">
				<div
					[class.single-column]="!additionalTableField.tableHeaderClasses?.length"
					[ngClass]="additionalTableField.tableHeaderClasses?.length ? additionalTableField.tableHeaderClasses : ''">
					<span>{{ additionalTableField.columnHeader | translate }}</span>
				</div>
			</ng-container>
		</ng-container>

		<div class="single-column options">
			<span class="options"></span>
		</div>
	</div>
</ng-template>

<ng-template #rowTemplate let-item="item">
	<div class="single-row-skeleton" *ngIf="!item">
		<div class="single-column checkbox">
			<span class="checkbox"></span>
		</div>
		<div class="single-column single">
			<span></span>
		</div>

		<ng-container *ngIf="additionalFields?.length">
			<ng-container *ngFor="let field of additionalFields">
				<div [ngClass]="field.cellClasses?.length ? field.cellClasses : ''" class="single-column"><span></span></div>
			</ng-container>
		</ng-container>
	</div>

	<div class="single-row" *ngIf="item" matSort>
		<div class="single-column checkbox">
			<span class="checkbox"></span>
			<mat-checkbox
				(click)="$event.stopPropagation()"
				class="checkbox"
				[checked]="gcs.checkboxMap[item.uuid] === undefined ? gcs.selectAllChecked : gcs.checkboxMap[item.uuid]"
				(change)="onSelectionChange($event, item.uuid)">
			</mat-checkbox>
		</div>
		<div class="single-column double first-name">
			<span class="highlight">{{ item.name }}</span>
		</div>

		<ng-container *ngIf="additionalFields?.length">
			<ng-container *ngFor="let additionalTableField of additionalFields">
				<div
					[class.single-column]="!additionalTableField.cellClasses"
					[ngClass]="additionalTableField.cellClasses?.length ? additionalTableField.cellClasses : ''">
					<ng-container *ngIf="additionalTableField.cellTemplate; else defaultTemplate">
						<div [innerHTML]="additionalTableField.cellTemplate(item)"></div>
					</ng-container>

					<ng-template #defaultTemplate>
						<span>{{ item[additionalTableField.fieldName] }}</span>
					</ng-template>
				</div>
			</ng-container>
		</ng-container>

		<div class="single-column options"></div>
	</div>
</ng-template>

<ng-template #noItemsTemplate>
	<div class="no-results-container">
		<ng-lottie [options]="{ path: './assets/lottie/icn-dam-nocontent.json' }"></ng-lottie>
		<div class="title">
			{{ 'GRID.NO_RESULTS_TITLE' | translate }}
		</div>
		<div class="text">
			{{ 'GRID.NO_RESULTS_TEXT' | translate }}
		</div>
	</div>
</ng-template>

<div class="buttons-container">
	<button class="confirm" (click)="close()">
		<span>{{ 'CONFIRM' | translate }}</span>
	</button>
</div>
