import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { concatLatestFrom } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Asset, ISortInfo, deepEqual } from 'addiction-components';
import { ContextStore, createContextEffect } from 'context-store';
import { catchError, concatMap, debounceTime, distinctUntilChanged, filter, first, map, of, switchMap, takeUntil, tap } from 'rxjs';
import { MediaLibraryActions } from 'src/app/core/state/app.actions';
import { CommunitySelectSelectors, MediaLibrarySelectors } from 'src/app/core/state/app.selectors';
import { DataleanHTTPError, IDataleanErrorManager, STATE_STATUS } from 'src/app/shared/models';
import { MediaLibraryService } from '../services/media-library.service';
import { setAvailableFiltersError, setAvailableFiltersStatus, setAvailableFiltersSuccess } from './media-library.actions';
import {
	selectAssets,
	selectCurrentFeatureValuePath,
	selectFilters,
	selectOldData,
	selectOldFilters,
	selectPagination,
	selectSorting,
	selectStatus,
} from './media-library.selectors';
import { AssetQueue, MediaLibErrorType, MediaLibraryFilters, QueueStatus } from './media-library.state';

@Injectable()
export class MediaLibraryEffects {
	// // LOAD ASSETS REQUEST or REFRESH when something changes
	loadAssets$ = createContextEffect(
		[
			MediaLibraryActions.changeFeatureValue,
			MediaLibraryActions.fetchAssets,
			MediaLibraryActions.fetchAll,
			MediaLibraryActions.assetCreated,
			MediaLibraryActions.assetDeleted,
			MediaLibraryActions.assetsDeleted,
			MediaLibraryActions.assetUpdated,
			MediaLibraryActions.assetCloned,
			MediaLibraryActions.setSort,
			MediaLibraryActions.setPages,
			MediaLibraryActions.clearQueue,
			MediaLibraryActions.setForceRefreshAssets,
		],
		($) =>
			$.pipe(
				concatLatestFrom(() => [
					this.storeCtx.select(selectStatus),
					this.storeCtx.select(selectPagination),
					this.storeCtx.select(selectSorting),
					this.storeCtx.select(selectFilters),
					this.storeCtx.select(selectOldData),
					this.storeCtx.select(selectOldFilters),
					this.storeCtx.select(selectCurrentFeatureValuePath),
					this.storeCtx.select(selectAssets),
					this.store.select(CommunitySelectSelectors.selectLastCommunitySelectedForApiRest),
				]),
				debounceTime(100),
				filter(([, stat, pages]) => {
					return !!pages.length && (!stat || stat !== STATE_STATUS.LOADING);
				}),
				map(
					([_, , ...rest]) =>
						[_, ...rest] as [
							Action,
							number[],
							ISortInfo | undefined,
							MediaLibraryFilters | undefined,
							(
								| {
										totalAssetCount: number;
										assets: Asset[][];
								  }
								| undefined
							),
							MediaLibraryFilters | undefined,
							string[],
							{ totalAssetCount: number; assets: Asset[][] } | undefined,
							string
						]
				),
				distinctUntilChanged(
					(
						[, pagesPrev, sortPrev, mediaLibraryFilterPrev, oldDataPrev, oldFilterPrev, fetchParamsPrev, , communityUUIDPrev],
						[action, pagesCurr, sortCurr, mediaLibraryFilterCurr, oldDataCurr, oldFilterCurr, fetchParamsCurr, , communityUUIDCurr]
					) => {
						return (
							action.type !== '[Media Library] Force refresh assets' &&
								pagesPrev.length === pagesCurr.length &&
								pagesPrev.every((f) => pagesCurr.includes(f)) &&
								oldFilterPrev === oldFilterCurr &&
								sortPrev === sortCurr &&
								communityUUIDCurr,
							communityUUIDPrev,
							deepEqual(mediaLibraryFilterPrev, mediaLibraryFilterCurr) &&
								deepEqual(oldDataPrev, oldDataCurr) &&
								deepEqual(mediaLibraryFilterCurr, oldDataCurr) &&
								deepEqual(fetchParamsPrev, fetchParamsCurr)
						);
					}
				),
				switchMap(([action, pages, sort, mediaLibraryFilter, , , currentPath, assets, communityUUIDCurr]) => {
					this.storeCtx.dispatch(MediaLibraryActions.setStatus({ assetStatusLoading: STATE_STATUS.LOADING }));

					//se sto forzando il refresh allora prendo tutte le pages
					const pagesToFetch = pages.filter((page) => !assets?.assets[page]?.length || action.type === '[Media Library] Force refresh assets');

					if (!pagesToFetch.length) {
						return of(MediaLibraryActions.assetsLoaded({ pages, assets: assets?.assets ?? [], totalAssetCount: assets?.totalAssetCount ?? 0 })).pipe(
							filter(() => true)
						);
					}

					return this.mediaLibSrv.loadAssets(currentPath, mediaLibraryFilter, pagesToFetch, sort, undefined, communityUUIDCurr).pipe(
						map((data) => {
							const startingResult: { pages: number[]; assets: Asset[][]; totalAssetCount: number } = {
								pages: data?.length ? [...new Set([...pages, ...pagesToFetch])] : [],
								assets: [],
								totalAssetCount: 0,
							};
							const result = data.reduce((acc, item) => {
								if (item.paginationInfo) {
									acc.totalAssetCount = item.paginationInfo.totalElements;
									if (item.result) {
										acc.assets[item.paginationInfo.pageNumber] = item.result;
									}
								}
								return acc;
							}, startingResult);
							const filteredAssets = result.assets.filter((a) => a);

							if (communityUUIDCurr) {
								return MediaLibraryActions.assetsLoaded({
									...result,
									assets: filteredAssets,
								});
							}

							const currentAssets = communityUUIDCurr ? assets?.assets ?? [] : [];
							const allAssets = [...currentAssets, ...filteredAssets].filter((a) => a);

							return MediaLibraryActions.assetsLoaded({
								...result,
								assets: allAssets,
							});
						}),
						catchError((error: unknown) => {
							return of(
								MediaLibraryActions.error({
									error: { errorType: MediaLibErrorType.GENERIC_HTTP, data: error },
								})
							);
						})
					);
				})
			)
	);

	loadFeatureValues$ = createContextEffect([MediaLibraryActions.fetchFeatureValues, MediaLibraryActions.fetchAll], ($) =>
		$.pipe(
			// fetch API
			switchMap(({ context }) =>
				this.mediaLibSrv.loadFeatureValues(true, this.translateService.currentLang ?? this.translateService.defaultLang).pipe(
					map(
						({ result }) => MediaLibraryActions.featureValuesLoaded({ featureValues: result, context }),
						// error handling
						catchError((error: unknown) =>
							of(MediaLibraryActions.error({ error: { errorType: MediaLibErrorType.GENERIC_HTTP, data: error }, context }))
						)
					)
				)
			)
		)
	);

	changeFilters$ = createContextEffect([MediaLibraryActions.setViewMode], (actions$) =>
		actions$.pipe(
			//se gli oggetti sono uguali non vado avanti
			concatLatestFrom(() => [this.storeCtx.select(MediaLibrarySelectors.selectOldFilters)]),
			// filter(([action, oldFilters]) => {
			// 	return !deepEqual(action.filters, oldFilters);
			// }),
			switchMap(([{ filters, mergeFilters }, _oldFilters]) => {
				const filterObject = mergeFilters ? { ..._oldFilters, ...filters } : filters;
				this.storeCtx.dispatch(MediaLibraryActions.setOldFilters({ oldFilters: filterObject }));
				return of(MediaLibraryActions.fetchAssets({ filters: filterObject }));
			})
		)
	);

	deleteAssets$ = createContextEffect([MediaLibraryActions.deleteAssets], ($) =>
		$.pipe(
			// invoke API
			switchMap(({ body }) => this.mediaLibSrv.multipleUpdateV2(body).pipe(map(() => body.entityUUIDs ?? []))),
			map((uuids) => MediaLibraryActions.assetsDeleted({ uuids })),
			// error handling
			catchError((error: unknown) => of(MediaLibraryActions.error({ error: { errorType: MediaLibErrorType.GENERIC_HTTP, data: error } })))
		)
	);

	updateFeatureValueLists$ = createContextEffect([MediaLibraryActions.updateFeatureValueLists], ($) =>
		$.pipe(
			// invoke API
			switchMap(({ body }) => this.mediaLibSrv.multipleUpdateV2(body).pipe(map(() => body.entityUUIDs ?? []))),
			map((uuids) => MediaLibraryActions.updatedFeatureValues({ uuids })),
			// error handling
			catchError((error: unknown) => of(MediaLibraryActions.error({ error: { errorType: MediaLibErrorType.GENERIC_HTTP, data: error } })))
		)
	);

	regenerateThumbnails$ = createContextEffect([MediaLibraryActions.regenerateThumbnails], ($) =>
		$.pipe(
			// invoke API
			switchMap(({ body }) => this.mediaLibSrv.multipleUpdateV2(body).pipe(map(() => body.entityUUIDs ?? []))),
			map((uuids) => MediaLibraryActions.thumbnailsregenerated({ uuids })),
			// error handling
			catchError((error: unknown) => of(MediaLibraryActions.error({ error: { errorType: MediaLibErrorType.GENERIC_HTTP, data: error } })))
		)
	);
	// MOVE ASSETS
	moveAssets$ = createContextEffect([MediaLibraryActions.moveAssets], ($) =>
		$.pipe(
			// invoke API
			switchMap(({ uuids, newParent, context }) =>
				this.mediaLibSrv.moveItems(uuids, newParent).pipe(
					// must re-fetch to repaginate
					map(() => MediaLibraryActions.fetchAssets({ context })),
					// error handling
					catchError((error: unknown) =>
						of(MediaLibraryActions.error({ error: { errorType: MediaLibErrorType.GENERIC_HTTP, data: error }, context }))
					)
				)
			)
		)
	);

	// DELETE SINGLE ASSET
	deleteAsset$ = createContextEffect([MediaLibraryActions.deleteAsset], ($) =>
		$.pipe(
			concatMap(({ uuid, context }) =>
				this.mediaLibSrv.deleteAsset(uuid).pipe(
					map(() => MediaLibraryActions.assetDeleted({ uuid, context })),
					catchError((error: unknown) => of(MediaLibraryActions.assetDeleteError({ error, context })))
				)
			)
		)
	);

	// CREATE A NEW ASSET
	createAsset$ = createContextEffect([MediaLibraryActions.createAsset], ($) =>
		$.pipe(
			concatMap(({ asset, queue }) =>
				this.mediaLibSrv.createAsset(asset).pipe(
					switchMap((upload) =>
						this.storeCtx.select(MediaLibrarySelectors.getQueue).pipe(
							first(),
							map((list) => ({ asset, queue: list, upload }))
						)
					),
					map(({ asset, queue, upload }) => {
						queue = queue?.map((q) => {
							if (q.assetInfo.name === asset.name) {
								if (upload.status === 'uploading') {
									return { ...q, progress: upload.progress, status: QueueStatus.UPLOADING };
								}
								if (upload.status === 'complete') {
									return { ...q, status: QueueStatus.FINISHED };
								}
							}
							return q;
						});
						return upload.status === 'complete'
							? MediaLibraryActions.assetCreated({ asset: upload.result, queue })
							: MediaLibraryActions.assetUploadProgress({ progress: upload.progress, queue });
					}),
					catchError((error: DataleanHTTPError) => {
						queue = queue?.map((q) => {
							if (asset.name === q.assetInfo.name) {
								return { ...q, status: QueueStatus.ERROR };
							}
							return q;
						});
						//questa gestione dell'errore è per evitare di mandare un errorType che scatena l'apertura della modale con il messaggio in caso di
						//drang and drop
						const actionObj: { errorType?: MediaLibErrorType; data?: unknown; queue?: AssetQueue[] } = {};
						if (queue?.some((q) => q.status === QueueStatus.ERROR)) {
							actionObj['queue'] = queue;
						}
						if (!queue || !queue?.length)
							actionObj['errorType'] = error.hasError('MEDIALIBRARY.NAME_TAKEN') ? 'name_taken' : MediaLibErrorType.GENERIC_HTTP;
						return of(MediaLibraryActions.assetSaveError(actionObj));
					}),
					takeUntil(
						this.storeCtx
							.select(MediaLibrarySelectors.interruptUpload)
							.pipe(
								filter(
									({ interruptUpload, asset: interruptedAsset }) => interruptUpload === true && interruptedAsset.some((ia) => ia.name === asset.name)
								)
							)
					)
				)
			)
		)
	);

	// UPDATE Queue ASSETS
	updateQueueAsset$ = createContextEffect([MediaLibraryActions.resolveQueue], ($) =>
		$.pipe(
			switchMap((action) => {
				if (action.process) {
					const elementToProcess = [...action.queue].filter((q) => q.status === QueueStatus.WAITING)[0];
					if (elementToProcess) {
						// console.log(elementToProcess);
						return of(MediaLibraryActions.createAsset({ asset: elementToProcess.assetInfo, queue: action.queue }));
					}
				}
				return of();
			})
		)
	);

	// SELECT ASSET NEWLY CREATED
	assetCreated$ = createContextEffect([MediaLibraryActions.assetCreated], ($) =>
		$.pipe(map(({ asset, context }) => MediaLibraryActions.setSelectedAsset({ asset, context })))
	);

	// UPDATE AN ASSET
	updateAsset$ = createContextEffect([MediaLibraryActions.updateAsset], ($) =>
		$.pipe(
			concatMap(({ asset, context }) =>
				this.mediaLibSrv.updateAsset(asset).pipe(
					map((upload) =>
						upload.status == 'complete'
							? MediaLibraryActions.assetUpdated({ asset: upload.result, context })
							: MediaLibraryActions.assetUploadProgress({ progress: upload.progress, context })
					),
					catchError((error: DataleanHTTPError) =>
						of(
							MediaLibraryActions.assetSaveError({
								errorType: MediaLibErrorType.UPDATE_ASSET,
								data: (error.source as { error: IDataleanErrorManager }).error,
							})
						)
					)
				)
			)
		)
	);

	// CLONE AN ASSET
	cloneAsset$ = createContextEffect([MediaLibraryActions.cloneAsset], ($) =>
		$.pipe(
			concatMap(({ uuid, context }) =>
				this.mediaLibSrv.cloneAsset(uuid).pipe(
					map((asset) => MediaLibraryActions.assetCloned({ sourceUUID: uuid, newAsset: asset, context })),
					catchError((error: unknown) => of(MediaLibraryActions.cloneError({ error, context })))
				)
			)
		)
	);

	//GESTIONE FILTRI
	fetchAvailableFilters$ = createContextEffect(
		[
			MediaLibraryActions.changeFeatureValue,
			MediaLibraryActions.setViewMode,
			MediaLibraryActions.fetchAvailableFilters,
			MediaLibraryActions.fetchAll,
		],
		(actions$) =>
			actions$.pipe(
				concatLatestFrom(() => [
					this.storeCtx.select(MediaLibrarySelectors.selectFilters),
					this.storeCtx.select(MediaLibrarySelectors.selectCurrentFeatureValuePath).pipe(
						map((path) => {
							return [...path].pop();
						})
					), //nell'ultimo elemento c'è la categoria corrente
					this.storeCtx.select(MediaLibrarySelectors.selectAvailableFilterStatus),
				]),
				filter(([, , , availableFilterStatus]) => availableFilterStatus !== STATE_STATUS.LOADING),
				tap(() => this.storeCtx.dispatch(setAvailableFiltersStatus({ status: STATE_STATUS.LOADING }))),
				switchMap(([, filters, featureUUID]) =>
					this.mediaLibSrv.fetchAvailableFilters(featureUUID, filters).pipe(
						map((availableFilter) => setAvailableFiltersSuccess(availableFilter)),
						catchError((availableFiltersError: HttpErrorResponse) => of(setAvailableFiltersError({ availableFiltersError })))
					)
				)
			)
	);

	forceRefresh$ = createContextEffect([MediaLibraryActions.updatedFeatureValues], ($) =>
		$.pipe(
			// invoke API
			map(() => MediaLibraryActions.setForceRefreshAssets({}))
		)
	);

	constructor(
		private storeCtx: ContextStore,
		private store: Store,
		private mediaLibSrv: MediaLibraryService,
		private translateService: TranslateService
	) {}
}
