import { CdkDrag, DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit, forwardRef } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
	AbstractControl,
	ControlValueAccessor,
	FormControl,
	NG_VALIDATORS,
	NG_VALUE_ACCESSOR,
	ReactiveFormsModule,
	UntypedFormArray,
	UntypedFormControl,
	UntypedFormGroup,
	ValidationErrors,
	Validator,
	Validators,
} from '@angular/forms';
import { MatMenuModule } from '@angular/material/menu';
import { LetDirective } from '@ngrx/component';
import { TranslateModule } from '@ngx-translate/core';
import { StructureField, ToLocalizedValuePipe } from 'addiction-components';
import { BehaviorSubject, map } from 'rxjs';
import { getPercentageFromPixel, safeCoordinateRangePercentage } from '../../helpers/pixel-utils';
import { LocalizableField, UnknownObject } from '../../models';
import { ContainerComponent } from '../container/container.component';
import { HotspotComponent } from '../hotspot/hotspot.component';
import { StructureFileSelectorComponent } from '../structure-file-selector/structure-file-selector.component';

@Component({
	selector: 'datalean-hotspot-image-container',
	templateUrl: './hotspot-image-container.component.html',
	styleUrls: ['./hotspot-image-container.component.scss'],
	standalone: true,
	imports: [
		CommonModule,
		CdkDrag,
		MatMenuModule,
		TranslateModule,
		DragDropModule,
		LetDirective,
		HotspotComponent,
		ReactiveFormsModule,
		forwardRef(() => ContainerComponent),
		forwardRef(() => StructureFileSelectorComponent),
		ToLocalizedValuePipe,
	],
	providers: [
		{ provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => HotspotImageContainerComponent), multi: true },
		{
			provide: NG_VALIDATORS,
			multi: true,
			useExisting: forwardRef(() => HotspotImageContainerComponent),
		},
		ToLocalizedValuePipe,
	],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HotspotImageContainerComponent<T extends Record<string, unknown> = Record<string, unknown>>
	implements OnInit, ControlValueAccessor, Validator
{
	static readonly hotspotPositionY = 'hotspotPositionY';
	static readonly hotspotPositionX = 'hotspotPositionX';
	@Input() field: StructureField | undefined;
	@Input() requiredIcon?: boolean = false;
	dataset?: T; //serve per impostare i valori di default (o riempiere il form con i vecchi valori)

	// private group = inject(ControlContainer, { skipSelf: true });
	formGroup = new UntypedFormGroup({
		image: new UntypedFormControl(null),
	});

	get hotspotPositionY() {
		return HotspotImageContainerComponent.hotspotPositionY;
	}
	get hotspotPositionX() {
		return HotspotImageContainerComponent.hotspotPositionX;
	}

	//variabili per gestione hotspot
	hotspotFields = new BehaviorSubject<StructureField[]>([]); //usato per problemi di changedetection
	dataContainerName = new BehaviorSubject<string>(''); //nome del contenitore dei dati per hotspot

	dataContainerValue$ = this.dataContainerName.pipe(
		map((name) => {
			return this.formGroup.controls?.[name]?.value ?? [];
		})
	);

	constructor() {
		this.formGroup.valueChanges.pipe(takeUntilDestroyed()).subscribe((formValue: Record<string, unknown>) => {
			const keys = Object.keys(formValue);
			const keysFiltered = keys.filter((el) => el !== 'image');
			if (keysFiltered.length) {
				this.dataContainerName.next(keysFiltered[0]);
			}
		});
	}

	ngOnInit() {
		if (this.field) {
			this.hotspotFields.next(this.getStructureFieldsFromHotspot(this.field));
		}
	}

	public onTouched: () => void = () => {};

	onValidatorChange = () => {};

	registerOnValidatorChange(fn: () => void): void {
		this.onValidatorChange = fn;
	}

	validate(c: AbstractControl): ValidationErrors | null {
		if (!this.requiredIcon && c.hasValidator(Validators.required)) {
			this.requiredIcon = true;
		}
		if (this.requiredIcon === true) this.formGroup.controls['image'].addValidators(Validators.required);

		if (this.formGroup.invalid) return { message: 'campo non valido' };
		return null;
	}

	registerOnTouched(fn: () => void): void {
		this.onTouched = fn;
	}

	writeValue(obj: T): void {
		this.dataset = obj; // imposto valori che sono già presenti nel form
		this.formGroup.patchValue(obj);
	}

	registerOnChange(fn: (val: unknown | undefined) => void): void {
		this.formGroup.valueChanges.subscribe(fn);
	}

	createHotspot(event: MouseEvent, field: StructureField): void {
		const rect = (event.target as HTMLImageElement).getBoundingClientRect();
		const xInPixel = event.clientX - rect.left;
		const yInPixel = event.clientY - rect.top;
		const x = safeCoordinateRangePercentage(getPercentageFromPixel(xInPixel, rect.width)).toFixed(1);
		const y = safeCoordinateRangePercentage(getPercentageFromPixel(yInPixel, rect.height)).toFixed(1);

		//aggiorno form aggiungendo un elemento
		//Qua so che la struttura ha un solo campo (che è un container)
		const hotFields = this.hotspotFields.value;
		if (hotFields.length > 0) {
			const containerData = hotFields[0];
			const newHotspotObject: UnknownObject = {};
			for (const dataField of Object.values(containerData.fields ?? [])) {
				if (dataField.name === HotspotImageContainerComponent.hotspotPositionX) newHotspotObject[dataField.name] = x;
				else if (dataField.name === HotspotImageContainerComponent.hotspotPositionY) newHotspotObject[dataField.name] = y;
				else {
					newHotspotObject[dataField.name] = undefined;
				}
			}

			//cosa molto simile alla addChild (ma diversa)
			const control = this.formGroup.controls[this.dataContainerName.getValue()] as UntypedFormArray;

			let newFormControl = new FormControl();
			if (field.fields?.some((sf) => sf.name === 'templateId')) {
				newFormControl = new FormControl({ templateId: `container-${control.controls.length}` });
			}
			control?.push(newFormControl);
			this.formGroup.updateValueAndValidity();
			//fine addChild

			//ora aggiorno nel form è stato aggiunto un oggetto con i valori nulli
			//prendo l'ultimo aggiunto e ci metto i valori appena calcolati
			const arrayHotspot = control?.value ?? [];
			if (arrayHotspot?.length) {
				arrayHotspot[arrayHotspot.length - 1] = newHotspotObject;
			} else console.log('qualcosa non va');

			control?.patchValue(arrayHotspot);
		}
	}

	deleteHotspot(index: number) {
		const hotspotControl = this.getHotspotDataController();
		hotspotControl?.removeAt(index);
	}

	getStructureFieldsFromHotspot(field: StructureField) {
		const newFields = structuredClone(field.fields ?? []).map((el) => {
			const field = structuredClone(el);
			if (!field.fields) field.fields = [];

			//aggiungo i campi che non sono mappati nella struttura
			field.fields.push({
				name: HotspotImageContainerComponent.hotspotPositionX,
				isRequired: false,
				type: 'number',
				uuid: HotspotImageContainerComponent.hotspotPositionX,
				showCollapsed: false,
				label: new LocalizableField([
					{ language: 'it-IT', value: 'Posizione x (%)' },
					{ language: 'en-US', value: 'Position x (%)' },
				]),
				desktopRatio: 50,
				predefinedValue: '0',
			});
			field.fields.push({
				name: HotspotImageContainerComponent.hotspotPositionY,
				isRequired: false,
				type: 'number',
				uuid: HotspotImageContainerComponent.hotspotPositionY,
				showCollapsed: false,
				label: new LocalizableField([
					{ language: 'it-IT', value: 'Posizione y (%)' },
					{ language: 'en-US', value: 'Position y (%)' },
				]),
				desktopRatio: 50,
				predefinedValue: '0',
			});
			return field;
		});

		return newFields;
	}

	movedHotspot(index: number, percenageCoord: { x: number; y: number }) {
		//aggiorno form
		const hotspotControl = this.getHotspotDataController();
		if (hotspotControl) {
			const control = hotspotControl.controls[index];
			const oldValue = control.value ?? {};
			//gestisco solo le percentuali (quindi per alcune operazioni devo fare delle conversioni)
			oldValue[HotspotImageContainerComponent.hotspotPositionX] = percenageCoord.x.toFixed(1);
			oldValue[HotspotImageContainerComponent.hotspotPositionY] = percenageCoord.y.toFixed(1);
			control.patchValue(oldValue);
		}
	}

	getHotspotDataController() {
		const hotspotControl = this.formGroup.controls[this.dataContainerName.getValue()] as UntypedFormArray;
		return hotspotControl;
	}

	trackHotspot(index: number, item: Record<string, number>): boolean;
	trackHotspot() {
		// return item?.[HotspotImageContainerComponent.hotspotPositionX] + item?.[HotspotImageContainerComponent.hotspotPositionY];
		return true;
	}
}
