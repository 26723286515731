import { CdkAccordionModule } from '@angular/cdk/accordion';
import { CommonModule } from '@angular/common';
import { Component, inject, ViewChild } from '@angular/core';
import { MatAccordion, MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { Store, StoreModule } from '@ngrx/store';
import { distinctUntilChanged, filter, switchMap } from 'rxjs';
import { NavLink } from '../../../shared/models';
import { SharedModule } from '../../../shared/shared.module';
import { NavigationSelector, UserSelector } from '../../state/app.selectors';

@Component({
	selector: 'datalean-main-navbar',
	templateUrl: './main-navbar.component.html',
	styleUrls: ['./main-navbar.component.scss'],
	standalone: true,
	imports: [CommonModule, StoreModule, CdkAccordionModule, MatExpansionModule, SharedModule, RouterModule],
})
export class MainNavbarComponent {
	@ViewChild(MatAccordion) accordion!: MatAccordion;

	private store = inject(Store);

	links$ = this.store.select(UserSelector.selectUserData).pipe(
		filter((data) => !!data),
		switchMap((data) =>
			this.store.select(NavigationSelector.selectNavLinks).pipe(
				distinctUntilChanged((prev, curr) => {
					const areArraysEqual =
						prev.length === curr.length &&
						prev.every((link, index) => link.label === curr[index].label && link.children?.length === curr[index].children?.length);
					return areArraysEqual && !!data;
				})
			)
		)
	);
	activePath$ = this.store.select(NavigationSelector.selectActivePath);

	public checkIfNavLinkSelected(navLink: NavLink, activePath: string | undefined): boolean {
		if (!activePath) return false;

		if (!navLink.children) {
			return navLink.path == activePath;
		}

		return !!navLink.children.find((childLink) => childLink.path == activePath);
	}
}
