import { DestroyRef, Injectable, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import {
	DataleanBaseApiService,
	DataleanSelectOption,
	FeatureValue,
	FeatureValueType,
	MessageHandler,
	Parts,
	Structure,
	StructureType,
} from 'addiction-components';
import { map } from 'rxjs/operators';
import { HeaderLocaleService } from 'src/app/components';
import { CommunitySelectSelectors } from 'src/app/core/state/app.selectors';
import { ImportExportDialogComponent } from 'src/app/shared/components/import-export-dialog/import-export-dialog.component';
import { environment } from 'src/environments/environment';

@Injectable()
export class BaseListService {
	protected store = inject(Store);
	protected translateService = inject(TranslateService);
	protected headerSrv = inject(HeaderLocaleService);
	protected baseApi = inject(DataleanBaseApiService);
	protected translate = inject(TranslateService);
	protected destroyRef = inject(DestroyRef);
	protected messageHandler = inject(MessageHandler);

	protected endpoint = '';
	protected structureType: StructureType = StructureType.PRODUCT;

	protected lastCommunitySelected$ = this.store
		.select(CommunitySelectSelectors.selectLastCommunitySelected)
		.pipe(takeUntilDestroyed(this.destroyRef));

	constructor() {}

	init(structureType?: StructureType): void {
		if (structureType) {
			this.structureType = structureType;
		}
		this.lastCommunitySelected$.pipe(takeUntilDestroyed(this.destroyRef)).subscribe((communityUUID) => {
			this.getStructureAndFeatures(communityUUID);
		});
	}

	getStructureAndFeatures(communityUUID?: string): void;
	getStructureAndFeatures() {
		const structureAdditionalParams: {
			type?: StructureType;
		} = { type: this.structureType };

		const featureAdditionalParams: {
			featureValueCommunityUUID?: string;
			type?: FeatureValueType;
			locale?: string;
			parentUUID: string;
		} = { type: this.structureType, locale: this.translateService.currentLang ?? this.translateService.defaultLang, parentUUID: '' };

		//TODO rimttere quando è possibile prendere le categorie in modo decente filtrate per community
		// if (communityUUID !== undefined) {
		// 	featureAdditionalParams.featureValueCommunityUUID = communityUUID;
		// } else if (this.lastCommunitySelected !== undefined) {
		// 	featureAdditionalParams.featureValueCommunityUUID = this.lastCommunitySelected;
		// }

		this.baseApi
			.getMany<Structure>(environment.structureUrl, [Parts.STRUCTURE_FIELDS], { additionalParams: structureAdditionalParams })
			.subscribe((structures) => {
				this.dispatchStructures(structures);
			});
		this.baseApi
			.getMany<FeatureValue>(environment.featureValueUrl, [Parts.FEATURE_VALUES], {
				additionalParams: featureAdditionalParams,
			})
			.subscribe((features) => {
				this.dispatchFeatures(features);
			});
	}

	dispatchStructures(structures: Structure[]): void;
	dispatchStructures() {}

	dispatchFeatures(features: FeatureValue[]): void;
	dispatchFeatures() {}

	getStructures(parts: Parts[]) {
		if (!parts) parts = [Parts.EMPTY];

		return this.baseApi.getManyPaged<Structure>(environment.structureUrl, parts);
	}

	count(filters: { structureUUID?: string; communityUUID?: string; q?: string; searchFields?: string }) {
		return this.baseApi.getOne<{ count: number }>(this.endpoint, 'count', [], {
			organizationUUID: environment.organizationUUID,
			...filters,
		});
	}

	multipleCount(filters: { structuresUUID?: string[]; structureUUID?: string; communityUUID?: string; q?: string; searchFields?: string }) {
		filters.structureUUID = filters.structuresUUID?.join(',');
		delete filters.structuresUUID;

		if (!filters.structureUUID) {
			delete filters.structureUUID;
		}

		return this.baseApi.getOne<Array<{ count: number; uuid: string }>>(this.endpoint, 'count_structures', [], {
			organizationUUID: environment.organizationUUID,
			...filters,
		});
	}

	public multipleUpdate(bulkPayload: { uuidList: string[]; delete?: boolean; active?: boolean }) {
		return this.baseApi.updateBulk(this.endpoint + 'multipleUpdate', bulkPayload).pipe(map(() => true));
	}

	openImportExportModal(structures: DataleanSelectOption<string>[] | null | undefined) {
		const $openModalRes = this.messageHandler.openDialog(
			ImportExportDialogComponent,
			{ title: this.translate.instant('MODALS.IMPORT_EXPORT.MODAL_TITLE') },
			{
				showExport: true,
				showImport: true,
				endpointImport: this.endpoint + 'import/',
				endpointExport: this.endpoint + 'export/',
				structures: structures,
				negativeReflection: true,
			}
		);
		return $openModalRes.result$;
	}
}
