import { HttpErrorResponse } from '@angular/common/http';
import { Sort } from '@angular/material/sort';
import { createAction, props } from '@ngrx/store';
import { PracticeSection } from 'src/app/shared/models/informed-consent/practice-section';
import { STATE_STATUS } from 'src/app/shared/models/state-status.enum';

export const setForceRefreshPracticeSections = createAction('[Practice-section] Set Force Refresh');
export const setSectionsSuccess = createAction(
	'[Practice-section] Set Practice sections Success',
	props<{ data: { totalSectionsCount: number; pages: number[]; sections: PracticeSection[][] } }>()
);
export const setSectionsError = createAction('[Practice-section] Set section Error', props<{ error: HttpErrorResponse }>());
export const setDeletePracticeSection = createAction('[Practice-section] Set Delete section', props<{ section: PracticeSection }>());
export const setDeletePracticeSectionSuccess = createAction('[Practice-section] Set Delete sections Success');
export const setDeletePracticeSectionError = createAction('[Practice-section] Set Delete sections Error', props<{ error: HttpErrorResponse }>());
export const setStatus = createAction('[Practice-section] Set status', props<{ status: STATE_STATUS }>());
export const setPages = createAction('[Practice-section] Set pages', props<{ pages: number[] }>());
export const setCachedPages = createAction('[Practice-section] Set cached pages', props<{ pages: number[] }>());
export const resetCache = createAction('[Practice-section] reset cache');
export const setSort = createAction('[Practice-section] Set sort', props<{ sort: Sort }>());
export const setGridSearch = createAction('[Practice-section] Set search', props<{ search: string | null }>());
